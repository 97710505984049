<template>
  <div class="mx-auto w-100 position-absolute" style="z-index: 90; top:0; left:0; height:100vh;">
    <div style="opacity: 0.4; height: 100vh; top: 0; left: 0;" class="bg-secondary w-100" @click="exitModal">
    </div>
    <div class="bg-white position-absolute border-dark w-50 mx-auto rounded" style="top:20%; left:0; right:0">
      <div class="card-header mb-3 p-0">
        <div class="text-right card-header p-2">
          <button class="btn btn-danger " @click="exitModal">
            <i class="fas fa-times"></i>
          </button>
        </div>
        <div class="w-100 text-center p-3" v-if="url">
          <img :src="url.url" alt="" style='width: 100%; left:0; right:0;' class="mx-auto">
        </div>
        <div class="w-100 text-center p-3 font-weight-bold" v-else>
          <span>
            Geef een reden voor het afkeuren van de afbeeldingen
          </span>
          <textarea class="form-control mt-3" cols="30" rows="10" v-model="reason"></textarea><br />
          <button class="btn btn-danger btn-block" @click="setReason" v-show="reason">
            Afkeuren
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {

  props:[
    'url',
  ],

  data() {
    return {
      reason: '',
    }
  },

  methods: {
    exitModal (event) {
      this.$emit('clicked', 'closeModal')
    },
    
    setReason (event) {
      this.$emit('clicked', this.reason)
    },
  }
}
</script>