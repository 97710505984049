<template>
<!-- TODO: object maken van images en die opvullen -->
  <div>
    <div class="row">
      <div class="col-md-4 mb-3">
        <h2 class="w-100 d-inline d-md-block">Status:</h2>
        <span class="badge bg-warning" v-if="accepted == null">Wachtend op goedkeuring</span>
        <span class="badge bg-success text-white" v-else-if="accepted == true">Goedgekeurd</span>
        <span class="badge bg-danger text-white" v-if="accepted == false">Afgewezen</span>
      </div>
      <div class="col-md-1">
      </div>
      <div class="col-md-3 d-md-block d-none">
        <h1>Info</h1>
      </div>
      <div class="col-md-4 w-full">
        <a class="btn btn-primary float-right ml-1" v-if="!imported" :href="image_form_url">Bekijken in Formulier</a>
        <a class="btn btn-secondary ml-2 float-right" :href="back_button_url">Terug</a>
      </div>
    </div>
    <div class="row ">
      <div class="col-md-4 d-md-block d-none">
        <div class="border " style="height: 15px;">
          <div class="border h-100" :class="accepted == false ? 'bg-danger' : 'bg-success'" style="transition-duration: 0.2s; transition-timing-function: ease-in-out;" :style="imported ? accepted != null ? 'width:100%' : 'width: 75%' : 'width:47%'">
          </div>
        </div>
        <div class="row">
          <div class="col-3">
            <div class="border position-relative d-none d-lg-block float-right" style="height:15px; width: 1px;">
              <div class="position-absolute text-center" style="top:10px; left: -33px;">
                Bestanden uploaden
              </div>
            </div>
          </div>
          <div class="col-3">
            <div class="border position-relative d-none d-lg-block float-right" style="height:15px; width: 1px;" :class="!imported ? 'd-block font-weight-bold ' : 'd-none d-lg-block' ">
              <div class="position-absolute text-center" style="top:10px; left: -33px;">
                Formulier geimporteerd
              </div>
            </div>
          </div>
          <div class="col-3 ">
            <div class="border position-relative float-right" style="height:15px; width: 1px;" :class="imported && accepted == null ? 'd-block font-weight-bold ' : 'd-none d-lg-block' ">
              <div class="position-absolute text-center" style="top:10px; left: -33px;">
                Afbeeldingen controleren
              </div>
            </div>
          </div>
          <div class="col-3">
            <div class="border position-relative d-none d-lg-block float-right" style="height:15px; width: 1px;" :class=" accepted == true || accepted == false ? 'd-block font-weight-bold ' : 'd-none d-lg-block'" >
              <div class="position-absolute text-center" style="top:10px; left: -20px;">
                Afgerond!
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-1">
      </div>
      <div class="col-md-7">
        <p>
          In dit scherm zie je welke afbeeldingen je hebt geprobeerd te uploaden en hoe ver de status is van het uploadproces. De afbeeldingen zullen moeten worden goedgekeurd voordat ze geupload worden naar ons systeem. Dit wordt gedaan door een van de medewerkers van DagjeWeg.Tickets. U krijgt een mail zodra de aanvraag goedgekeurd of afgekeurd is.
        </p>
      </div>
    </div>
    <transition>
      <imageimportmodal v-if="modal" :url="modalImage" @clicked="closeModal"/>
    </transition>
    <div class="row row-centered mt-4">
      <div v-for="(image, image_index) in images" :key="image_index" class="col-xl-2 col-md-3 col-6">
        <div class="card mb-4">
          <div class="card-header p-2">
            <div class="text-center">
              <img @click="openModal(image)" :src="image.url" alt="" style='width: 100%; cursor: zoom-in;'>
              <h4>{{image.type == "logo" ? "Logo" : "Afbeelding"}}</h4>
            </div>
            <div class="clear"></div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="reason" class="mb-3">
      <b>
        Uw formulier is afgekeurd met de volgende reden:
      </b> <br />
      {{reason}}
    </div>
    <div>
      <span v-if="accepted == null && admin_rights && imported">
        <a @click="sendMail(true)" class="btn btn-success mb-3 text-white">Goedkeuren</a>
        <a @click="denyForm" class="btn btn-danger mb-3 text-white" >Afkeuren</a>
      </span>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import ImageImportModal from './ImageImportModal.vue';

export default {
  components: {
    ImageImportModal
  },
  
  props: [
    'merchant_id',
    'images',
    'user_id',
    'admin_rights',
    'form_key',
    'form_id',
    'back_button_url',
    'image_form_url',
    'imported',
    'is_completed',
    'is_denied'
  ],

  data() {
    return {
      modal: false,
      accepted: null,
      ModalImage: '',
      reason: '',
    }
  },

  created() {
    if(this.is_denied){
      this.accepted = false 
    } else if(this.is_completed) {
      this.accepted = this.is_completed
    }
  },
  methods: {
    denyForm(){
      this.modal = true
      this.accepted = false
    },

    closeModal(value){
      if (value == 'closeModal') {
        this.modalImage = null
        this.modal = false
      }else{
        this.modal = false
        this.sendMail(false)
      }
    },

    openModal(image){
      this.modalImage = image
      this.modal = true
    },

    sendMail(accepted) {
      this.accepted = accepted
      axios.post('/merchants/' + this.merchant_id + '/image_imports/' + this.form_id + '/send_mail',
        {
          accepted: accepted,
          key: this.form_key,
          id: this.form_id,
          reason: this.reason
        }
      )
    },
  }
}
</script>
<style scoped>
  .v-enter-active,
  .v-leave-active {
    transition: opacity 0.2s ease;
  }

  .v-enter,
  .v-leave-to {
    opacity: 0;
  }

</style>