<template>
  <div>
    <div class="bg-white rounded shadow-sm p-3">
      <div class="row">
        <div class="col">
          <img src="/onlineticketswinkel.png" width="150px;" class="mx-auto d-block">
        </div>
      </div>

      <fieldset class="form-group">
        <legend class="pt-0">Bedrijfsgegevens</legend>
        <p>Voor we aan het werk kunnen met uw reserveringsformulier, hebben we voor onze administratie nog een aantal bedrijfsgegevens nodig. Deze informatie gebruiken we onder andere om een account aan te maken waarmee u kunt kunt inloggen in de backoffice van OnlineTicketsWinkel en om facturen te kunnen versturen.</p>

        <div class="form-group">
          <label for="name">Winkelnaam</label>
          <input v-model="result.name" type="string" class="form-control" id="name">
          <small class="form-text text-muted">Vul de naam uw winkel in.</small>
        </div>

        <div class="form-group">
          <div class="row">
            <div class="col-8">
              <div class="custom-file">
                <input @change="onFileChange" type="file" class="custom-file-input" aria-describedby="logoHelp" id="logo">
                <label class="custom-file-label" for="logo">Kies een logo</label>
                <small id="logoHelp" class="form-text text-muted">Upload het logo van de winkel. Dit logo tonen we op de reserveringspagina en in e-mails. (bestandsformaat: .jpg of .png) </small>
              </div>
            </div>
            <div class="col-4">
              <img :src="result.logo" width="100%" />
            </div>
          </div>  
        </div>  

        <div class="form-group">
          <label for="access_email">E-mail voor inloggen</label>
          <input v-model="result.access_email" type="email" class="form-control" id="access_email" aria-describedby="access_emailHelp">
          <small id="access_emailHelp" class="form-text text-muted">Vul het e-maildres in waarmee u wilt inloggen in de backoffice van OnlineTicketsWinkel.</small>
        </div>

        <div class="form-group">
          <label for="administrative_email">E-mail-adres voor administratie</label>
          <input v-model="result.administrative_email" type="email" class="form-control" id="administrative_email" aria-describedby="emailHelp">
          <small id="emailHelp" class="form-text text-muted">Vul het e-maildres in waarop u de facturen wilt ontvangen.</small>
        </div>

        <div class="form-group">
          <label for="phone">Telefoonnummer</label>
          <input v-model="result.phone" type="email" class="form-control" id="phone">
          <small class="form-text text-muted">Vul het telefoonnummer in waarop we u kunnen bereiken met eventuele vragen.</small>
        </div>

        <div class="form-group">
          <label for="vat_number">BTW-nummer (niet verplicht)</label>
          <input v-model="result.vat_number" type="string" class="form-control" id="vat_number">
          <small class="form-text text-muted">Vul het BTW-nummer in dat we kunnen vermelden op de facturen.</small>
        </div>

      </fieldset>

      <fieldset class="form-group">
        <legend class="pt-0">Algemene info</legend>
        <p>Om het reserveringsformulier specifiek voor uw winkel te kunnen inrichten, hebben we wat aanvullende informatie nodig over de momenten waarop u klanten uw wilt/kunt ontvangen.</p>

        <div class="form-group">
          <h5>Dagen geopend</h5>
          <small>Selecteer hieronder de openingsdagen van uw winkel.</small>
          <div class="form-check">
            <input class="form-check-input" type="checkbox" value="" id="date1" v-model="date1">
            <label class="form-check-label" for="date1">
              Maandag
            </label>
          </div>
          <div class="form-check">
            <input class="form-check-input" type="checkbox" value="2" id="date2" v-model="date2">
            <label class="form-check-label" for="date2">
              Dinsdag
            </label>
          </div>
          <div class="form-check">
            <input class="form-check-input" type="checkbox" value="3" id="date3" v-model="date3">
            <label class="form-check-label" for="date3">
              Woensdag
            </label>
          </div>
          <div class="form-check">
            <input class="form-check-input" type="checkbox" value="4" id="date4" v-model="date4">
            <label class="form-check-label" for="date4">
              Donderdag
            </label>
          </div>
          <div class="form-check">
            <input class="form-check-input" type="checkbox" value="5" id="date5" v-model="date5">
            <label class="form-check-label" for="date5">
              Vrijdag
            </label>
          </div>
          <div class="form-check">
            <input class="form-check-input" type="checkbox" value="6" id="date6" v-model="date6">
            <label class="form-check-label" for="date6">
              Zaterdag
            </label>
          </div>
          <div class="form-check">
            <input class="form-check-input" type="checkbox" value="7" id="date7" v-model="date7">
            <label class="form-check-label" for="date7">
              Zondag
            </label>
          </div>
        </div>

        <div class="form-group">
          <label for="open_from">Openingstijd</label>
          <input v-model="result.open_from" type="time" class="form-control" id="open_from" aria-describedby="openHelp" placeholder="9:00">
          <small id="openHelp" class="form-text text-muted">Vul hier het starttijdstip van het eerste tijdblok in.</small>
        </div>
        <div class="form-group">
          <label for="open_until">Sluitingstijd</label>
          <input v-model="result.open_until" type="time" class="form-control" id="open_until" aria-describedby="closeHelp" placeholder="17:00">
          <small id="closeHelp" class="form-text text-muted">Vul het tijdstip in waarop uw winkel sluit.</small>
        </div>

        <legend class="pt-0">Afhaal info</legend>
        <div class="form-group">
          <label for="formControlRange">Duur tijdblok</label>
          <small class="form-text text-muted">Geef aan hoe lang klanten bij een reservering de tijd hebben om hun bestelling op te halen.</small>
          <input v-model="result.duration" min="5" step="5" max="60" type="range" class="form-control-range" id="formControlRange">
          <p class="small text-center">{{result.duration}} minuten</p>
        </div>
        <div class="form-group">
          <label for="max_capacity">Aantal reserveringen per tijdsblok</label>
          <small class="form-text text-muted">Geef aan hoeveel klanten u per tijdsblok kunt/wilt ontvangen in de winkel.</small>
          <input v-model="result.max_capacity" min="1" step="1" max="100" type="range" class="form-control-range" id="max_capacity">
          <p class="small text-center">{{result.max_capacity}} reserveringen</p>
        </div>

        <legend class="pt-0">Winkelbezoek info</legend>
        <div class="form-group">
          <label for="formControlRange">Duur tijdblok</label>
          <small class="form-text text-muted">Geef aan hoe lang klanten mogen winkelen.</small>
          <input v-model="result.shop_duration" min="5" step="5" max="60" type="range" class="form-control-range" id="formControlRange">
          <p class="small text-center">{{result.shop_duration}} minuten</p>
        </div>
        <div class="form-group">
          <label for="max_capacity">Aantal reserveringen per tijdsblok</label>
          <small class="form-text text-muted">Geef aan hoeveel klanten u per tijdsblok kunt/wilt laten winkelen.</small>
          <input v-model="result.shop_max_capacity" min="1" step="1" max="50" type="range" class="form-control-range" id="max_capacity">
          <p class="small text-center">{{result.shop_max_capacity}} reserveringen</p>
        </div>
      </fieldset>
      <p>Na bevestiging via de button 'Zet mijn winkel klaar', gaan we direct voor u aan de slag met het klaarzetten van uw reserveringsformulier. U gaat dan akkoord met de <a href="https://www.onlineticketswinkel.nl" target="_blank">bijkomende kosten</a>. U ontvangt vervolgens een e-mail waarmee u kunt inloggen in de backoffice van OnlineTicketsWinkel. </p>
      <div class="alert alert-danger mt-3" role="alert" v-bind:class="{ 'd-none': !isError }">
        Nog niet alles is ingevuld. Vul alles in en druk dan opnieuw op verzenden.
      </div>
      <button class="btn btn-primary btn-lg" :disabled="isExporting" @click="getResult()">Zet mijn winkel klaar</button>
      <small v-if="isExporting">Even geduld a.u.b. we maken nu uw winkel aan.</small>
    </div>
    <div class="modal fade" id="myModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Voltooid!</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <p>We hebben jouw winkel succesvol aangemaakt. Je hebt een e-mail ontvangen op: {{this.result.access_email}}, hiermee kan je inloggen in onze backoffice.</p>
            <p>Je mag deze pagina nu afsluiten.</p>
          </div>
          <div class="modal-footer">
            <a href="https://www.onlineticketswinkel.nl" class="btn btn-secondary">Terug</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
export default {
  name: 'RetailForm',
  data () {
    return {
      isExporting: false,
      date1: false,
      date2: false,
      date3: false,
      date4: false,
      date5: false,
      date6: false,
      date7: false,
      error: false,
      result: {
        name: null,
        administrative_email: null,
        vat_number: "",
        open_days: [],
        open_from: null,
        open_until: null,
        duration: 15,
        max_capacity: 10,
        access_email: null,
        logo: null,
        shop_duration: 10,
        shop_max_capacity: 2
      }
    }
  },
  computed: {
    isError(){
      return this.error
    }
  },
  methods: {
    showModal() {
      $('#myModal').modal('show')
    },
    onFileChange(e) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length)
        return;
      this.createImage(files[0]);
    },
    createImage(file) {
      var image = new Image();
      var reader = new FileReader();
      var vm = this;

      reader.onload = (e) => {
        vm.result.logo = e.target.result;
      };
      reader.readAsDataURL(file);
    },
    addOpenDays() {
      this.result.open_days = []
      if (this.date1) this.result.open_days.push(1);
      if (this.date2) this.result.open_days.push(2);
      if (this.date3) this.result.open_days.push(3);
      if (this.date4) this.result.open_days.push(4);
      if (this.date5) this.result.open_days.push(5);
      if (this.date6) this.result.open_days.push(6);
      if (this.date7) this.result.open_days.push(7);
    },
    sendToBackoffice() {
      axios.post('/retail/merchant.json', {
        result: this.result,
      }).then((response) => {
        if(response.data.merchant) {
          this.showModal()
        }
      }).catch((error) => {
        console.error(error);
      }).finally(() => {
        // TODO
      });
    },
    getResult() {
      this.isExporting = true
      this.addOpenDays()
      if(this.result.open_days.length > 1 && this.result.name && this.result.administrative_email && this.result.open_from && this.result.open_until && this.result.duration && this.result.shop_duration && this.result.shop_max_capacity && this.result.access_email && this.result.max_capacity) {
        console.log("Alles is ingevuld")
        this.sendToBackoffice()
      } else {
        console.log("Nog niet alles is ingevuld.")
        this.error = true
        this.isExporting = false
      }
    }
  }
};
</script>

<style lang="css" scoped>
</style>