import Vue from 'vue/dist/vue.esm'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    stats: [],
    compare_stats: [],
    period: null,
    heatmap: [],
    merchants: [],
    references: [],
    overview: [],
    loaded: false,
    name: "",
    forms: [],
    periods: [],
    shops: [],
    forms_total_tickets: null,
    forms_total_amount: null,
    shops_total_tickets: null,
    shops_total_amount: null
  },

  getters: {
    stats(state) {
      return state.stats
    },
    compare_stats(state) {
      return state.compare_stats
    },
    name(state) {
      return state.name
    },
    loaded(state) {
      return state.loaded
    },
    period(state) {
      return state.period
    },
    heatmap(state) {
      return state.heatmap
    },
    merchants(state) {
      return state.merchants
    },
    references(state) {
      return state.references
    },
    overview(state) {
      return state.overview
    },
    carts(state) {
      return state.carts
    },
    periods(state) {
      return state.periods
    },
    forms(state) {
      return state.forms
    },
    shops(state) {
      return state.shops
    },
    forms_total_tickets(state){
      return state.forms_total_tickets
    },
    forms_total_amount(state){
      return state.forms_total_amount
    },
    shops_total_tickets(state){
      return state.shops_total_tickets
    },
    shops_total_amount(state){
      return state.shops_total_amount
    }
  },

  actions: {
    setStats(context, data){
      console.log("Settings stats from data:")
      console.log(data)
      context.commit("setStats", data)
    },
    setPeriods(context, data) {
      context.commit("setPeriods", data)
    },
    resetStats(context) {
      context.commit("resetStats")
    },
    setPeriod(context, data) {
      context.commit("setPeriod", data)
    }
  }, 

  mutations: {
    setStats(state, data) {
      state.stats = data.stats;
      state.compare_stats = data.compare_stats;
      state.loaded = data.info;
      state.references = data.references;
      state.merchants = data.merchants;
      state.heatmap = data.heatmap;
      state.overview = data.overview
      state.name = data.name
      state.forms = data.forms
      state.shops = data.shops
      state.forms_total_tickets = data.forms_total_tickets
      state.forms_total_amount = data.forms_total_amount
      state.shops_total_tickets = data.shops_total_tickets
      state.shops_total_amount = data.shops_total_amount
    },
    setPeriods(state, data) {
      state.periods = data
    },
    resetStats(state) {
      state.loaded = false;
      state.stats = [];
      state.references = [];
      state.merchants = [];
      state.heatmap = [];
      state.overview = [];
    },
    setPeriod(state, data) {
      state.period = data;
    }
  }
  

})
