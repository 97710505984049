<template>
	<div class="container-fluid">
		<div class="row">
		<div class="col-12 input-group">
	      <input @keyup.enter="newScan" v-model="code" type="text" name="code" id="code" placeholder="code" class="form-control" autofocus="autofocus">
	      <div class="input-group-append">
	        <input @click="newScan" type="submit" name="commit" value="Controleer code" class="btn btn-primary" data-disable-with="Controleer code">
	      </div>
	    </div>
	    </div>
	    <div class="row mt-4">
	    	<div class="col-12">
	    <div class="list-group-flush" style="display: flex; flex-direction: column-reverse;">
        <ScanResult v-for="(result, index) in scannedCodes" :merchantKey="$attrs.merchantkey" :key="`result-${index}`" :result="result" />
      </div>
      </div>
      </div>
	</div>
</template>

<script>
import axios from 'axios'
import ScanResult  from './ScanResult.vue'
export default {
  name: 'Scanner',
  components: { ScanResult },
  props: ["merchantKey"],
  data () {
    return {
      scannedCodes: [],
      code: ''
    }
  },
  methods: {
  	newScan() {
      if(this.code != '') {
    	  if (this.scannedCodes.includes(this.code) && this.scannedCodes[(this.scannedCodes.length - 1)] === this.code) {
          console.log('ignoring code...')
        } else {
          this.scannedCodes.push(this.code)
          this.code = ''
        }
      }
    }
  }
}
</script>

<style scoped>
	
</style>