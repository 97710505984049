<template>
  <div>
    <div class="row">
      <Transition>
        <div style="position:fixed; z-index:9999; top: 100px; right: 40px;" class="text-center alert alert-success" v-if="message">
          {{message}}
        </div>
        <div style="position:fixed; z-index:9999; top: 100px; right: 40px;" class="text-center alert alert-danger" v-else-if="error">
          {{error}}
        </div>
      </Transition>
      <div class="col-md-9 col-12 inline-block border-right">
        <div class="row">
          <div class="form-group col-md-5 col-12 d-inline-block">
            <div v-if="!article.id && !init">
              <label class="form-label" for="title"> Afbeelding </label>
              <p>Zodra je het artikel aanmaakt komt de optie om een adbeelding toe te voegen hier te staan.</p>
            </div>
          </div>
          <div class="col-md-7 col-12">
            <div class="form-group">
              <label class="form-label" for="title"> Titel </label>
              <input class="form-control" type="text" name="title" v-model="article.title" >
            </div>
            <div class="form-group">
              <label class="form-label" for="name"> Administratieve naam </label>
              <input class="form-control" type="text" name="name" v-model="article.name" >
              <small class="text-secondary">Samenvattende naam van artikel voor het zoeken bijv. { soort artikel }-{ jaartal }</small>
            </div>
            <div class="form-group">
              <label class="form-label" for="short_description"> Korte omschrijving </label>
              <textarea class="form-control" maxlength="200" name="short_description" id="short_description" v-model="article.short_description" rows="5"></textarea>
              <small>Nog {{200 - (article.short_description ? article.short_description.length : 0)}} karakters over.</small>
            </div>        
          </div>
        </div>
        <div class="form-group">
          <label class="form-label" for="body"> Artikel tekst </label>
          <VueEditor :editorToolbar="customToolbar" v-model="article.body" />
        </div>
        <div class="form-group">
          <label class="form-label" for="seo_title"> SEO Titel </label>
          <input class="form-control" type="text" name="seo_title" v-model="article.seo_title" >
        </div>
        <div class="form-group">
          <label class="form-label" for="seo_description"> SEO Omschrijving </label>
          <input class="form-control" type="text" name="seo_description" v-model="article.seo_description" >
        </div>
      </div>
    
      <div class="col-md-3 col-12 border-left">
        <div class="form-actions mb-3">
          <button @click="saveArticle" :disabled="saving" class="btn btn-success mb-1">
            Opslaan
          </button>
          <a :href="article.url" target="_blank" class="btn btn-primary mb-1" v-if="article.id">
            Artikel bekijken
          </a>
          <button class="btn btn-warning mb-1" v-if="article.id" @click="checkArticle">
            Markeer als gechecked
          </button>
          <a :href="`/shops/${shop_id}/articles`" class="btn btn-secondary mb-1">
            Terug
          </a>
          <br/><small v-if="article.last_checked_at">Laatst gechecked: {{article.last_checked_at}}</small>
          <small v-else>Nog niet gechecked</small>
        </div>
        <div class="form-group">
          <div class="card" style="padding: 1rem 0;">
            <div class="row no-gutters d-table">
              <div class="col-md-4 text-center d-table-cell align-middle">
                <input type="checkbox" v-model="article.is_active" style="width: 50px; height: 50px;" >
              </div>
              <div class="col-md-8 d-table-cell align-middle">
                <div class="card-body">
                  <h5 class="card-title" v-if="!article.is_active">Publiceren</h5>
                  <h5 class="card-title" v-else>Gepubliceerd</h5>
                  <small>{{article.views}}x bekeken</small>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="form-group">
          <div class="card">
            <div class="row no-gutters d-table">
              <div class="col d-table-cell align-middle">
                <div class="card-body">
                  <div>
                    <label>Verloopdatum artikel</label>
                    <input type="date" v-model="article.expires_at" class="form-control mb-3">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="form-group">
          <div class="card" style=" padding: 1rem 0;">
            <div class="row no-gutters">
              <div class="col">
                <div class="form-group px-4">
                  <h5>Categorie</h5>
                  <multiselect v-model="article.tag" :options="tags" @input="getMerchants(); getTagIcon(); clearMerchants();" placeholder="Kies een categorie" label="name" track-by="id"></multiselect>
                </div>
              </div>
            </div>
            <div class="row no-gutters d-table ml-1">
              <div class="col-md-4 text-center d-table-cell align-middle">
                <img style="border-radius: 5px;" :style="chosen_tag.color ? 'background-color :' + chosen_tag.color : 'background-color:magenta' " :src="chosen_tag.icon" alt="">
              </div>
              <div class="col-md-8 d-table-cell align-middle">
                <div class="card-body">
                  <h5>{{chosen_tag.name}}</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>           
            <div class="p-absolute bg-white" style="width: 95%; height:250px;" v-if="loading">
              <div class="semipolar-spinner mx-auto" :style="'margin-top:115px;'" >
                <div class="ring"></div>
              </div>
            </div>
            <multiselect v-model="article.merchants" v-if="!loading" :options="merchants" :multiple="true" :close-on-select="false" :taggable="false" :clear-on-select="true" :preserve-search="true" placeholder="Kies de aanbieders" label="merchant_name" track-by="merchant_id" class="p-relative mt-2">
              <template slot="selection" >
                <span class="multiselect__single">{{ merchants.length }} gevonden aanbieders </span>
              </template>
            </multiselect>
          </div>
        </div>
      </div>
    <hr />

    <div class="col-md-12">
      <draggable :list="article.merchants" @start="drag=true" @end="drag=false">
        <div v-for="(selected, selected_index) in article.merchants" :key="selected_index" class="d-block">
          <div class="d-flex justify-content-between merchant border-top border-bottom" style="padding: 12px 6px;"  @click="openAccordion(selected_index)">
            <label class="form-label" for="tag_id">
              <i class="fas fa-bars" style="align-self:center"></i>
            <img v-if="merchants && selected &&selected.photo_id" :src="picture_url(selected)" alt="" width="50px" height="50px">    
              {{selected.merchant_id}} - <b>{{selected.merchant_name}}</b>
            </label>
            <span class="form-label" style="align-self:center" >
              <i class="fas fa-chevron-down form-label" style="transition-duration: .2s" :style="open == selected_index ? 'transform:rotateX(180deg)' : 'transform:rotateX(0deg)'" ></i>
            </span>
          </div>
          <div v-show="open == selected_index  && !loading" style="height: auto; margin: 5px 20px" >
            <label>Uitgelichte afbeelding van aanbieder</label>
            <div style="flex-wrap: wrap; max-height:300px; overflow-y:scroll;" class="d-flex justify-content-start mb-2 ml-3">
              <span v-for="(image, image_index) in selected.merchant_images" :key="image_index" @click="selected.photo_id = image.id">
                <img :src="image.url" alt="" width="150px" height="150px" :class="selected.photo_id == image.id ? 'border border-bold border-primary' : 'border border-white border-bold'">
              </span>
            </div>
            <small v-if="!selected.photo_id">Selecteer een foto om te gebruiken als thumbnail</small>
            <textarea class="form-control mt-2" v-model="selected.textarea" rows="6"></textarea>
          </div>
          <div v-if="open == selected_index && !selected.merchant_images && !loading">
            Er zijn geen afbeeldingen gevonden voor deze aanbieder.
          </div>
          <div v-else-if="open == selected_index && loading">
            <div style="width: 95%; height:250px;" class="bg-white position-relative" v-if="loading">
              <div class="semipolar-spinner mx-auto" :style="'margin-top:115px;'" >
                <div class="ring"></div>
              </div>
            </div>
          </div>
        </div>
      </draggable>
    </div>
  </div>
</div>

</template>
<script>
  import axios from 'axios';
  import Multiselect from 'vue-multiselect';
  import draggable from 'vuedraggable'

  export default {
    components: {
      Multiselect,
      draggable,
    },
    props: [
      'shop_id',
      'id',
      'tags',
    ],
    data() {
      return {
        article: {
          id: null,
          shop_id: null,
          title: null,
          name: null,
          chosen_image: null,
          short_description: null,
          body: null,
          tag: {
            id: null,
            name: null,
          },
          seo_title: null,
          seo_description: null,
          is_active: false,
          views: 0,
          expires_at: null,
          last_checked_at: null,
          merchants: [],
        },
        open: null,
        merchants: [],
        loading: false,
        saving: false,
        init: true,
        message: "",
        error: "",
        preview_image: "",
        chosen_tag: {},
        customToolbar: [
          ["bold", "italic", "underline", "strike"],
          [
            { align: "" },
            { align: "center" },
            { align: "right" },
            { align: "justify" }
          ],
          ["blockquote"],
          [{ list: "ordered" }, { list: "bullet" }],
          [{ color: [] }, { background: [] }],
          ["link"],
          ["clean"]
        ]
      }
    },
    watch: {
      message: function(val) {
        if(val != "") {
          setTimeout(() => {
            this.message = "";
          }, 3000);
        }
      },
      error: function(val) {
        if(val != "") {
          setTimeout(() => {
          this.error = "";
          }, 3000);
        }
      },
      'article.merchants': function(val) {
        this.loadImagesForMerchant(val)
      }
    },
    created() {
      this.setArticle();
    },

    methods: {
      openAccordion(index) {
        if(this.open != index){
          this.open = index
        }else{
          this.open = null
        }
      },
      
      clearMerchants() {
        this.article.merchants = []
      },

      handleFileUpload(event){
        this.chosen_image = this.$refs.image.files[0];
        this.preview_image = URL.createObjectURL(this.chosen_image);
      },

      picture_url(merchant) {
        return merchant.merchant_images.find(x => x.id == merchant.photo_id) ? merchant.merchant_images.find(x => x.id == merchant.photo_id).url : null;
      },

      loadImagesForMerchant(merchants) {
        if(this.article.merchants.length > 0) {
          var new_merchant = merchants.find(x => x.merchant_images == null)
          if(new_merchant) {
            axios.get(`/articles/get_merchant_images.json`, {params: {merchant_id: new_merchant.merchant_id}})
            .then(response => {
              new_merchant.merchant_images = response.data.images
            });
          }
        }
      },

      setArticle() {
        if(this.id) {
          axios.get(`/shops/${this.shop_id}/articles/${this.id}.json`).then(response => {
            this.article = response.data
                        this.article.id = this.idthis.article.shop_id = this.shop_id

            this.getMerchants()
            this.getTagIcon();
            this.init = false
          })
        } else {
          this.init = false;
        }
      },

      getTagIcon(){
        if (this.article.tag && this.article.tag.id != 0) {
          axios.get(`/articles/get_tag_icon.json`, {params: {tag_id: this.article.tag.id}})
          .then(response => {
            this.chosen_tag = response.data
          })
        } else {
          this.chosen_tag = {}
        }
      },

      getMerchants(){
        if(this.article.tag && this.article.tag.id != 0) {
          this.loading = true
          axios.get(`/articles/get_merchant_by_tag_id.json`, {params: {tag_id: this.article.tag.id}})
          .then(response => {
            this.merchants = response.data;
            this.loading = false;
          })
        } else {
          this.merchants = []
        }
      },
        
      checkArticle(){
        const params = {
          id: this.article.id,
          last_checked_at: new Date(),
        }
      
        axios.put('/shops/' + this.shop_id + '/articles/' + this.article.id + "/check.json", params).then(response => {
          this.message = "Het artikel is gecheckt";
          this.article.last_checked_at = response.data;
        }).catch(e => {
          this.error = "Het artikel kon niet geüpdate worden.";
        });
        
      },

      saveArticle() {
        this.saving = true 
        if(this.article.tag.id && this.article.title && this.article.body && this.article.name && this.article.seo_description && this.article.seo_title && this.article.short_description) {
          if(this.article.id) {
            var url = '/shops/' + this.shop_id + '/articles/' + this.article.id + '.json'
            var method = "put"
          } else {
            var url = '/shops/' + this.shop_id + '/articles' + '.json'
            var method = "post"
          }

          var bodyFormData = new FormData();
          for (const [key, value] of Object.entries(this.article)) {
            if(key == "merchants") {
              var index = 0
              for (const merchant of value) {
                for (const [key_2, value_2] of Object.entries(merchant)) {
                  bodyFormData.append(`article[merchants][${merchant.merchant_id}][${key_2}]`, value_2);
                }
                bodyFormData.append(`article[merchants][${merchant.merchant_id}][sort]`, index);
                index = index + 1
              }
            } else {
              bodyFormData.append(`article[${key}]`, value);
            }
          }
          if(this.chosen_image) {
            bodyFormData.append('image', this.chosen_image)
          }

          bodyFormData.append('article[tag_id]', this.article.tag.id)

          axios.request({url: url, method: method, data: bodyFormData, headers: { "Content-Type": "multipart/form-data" }}).then(response => {
            this.message = "Het artikel is succesvol opgeslagen";
            this.saving = false
            if(!this.article.id) {
              window.location.href = `/shops/${this.shop_id}/articles/${response.data}/edit`;
            }
          }).catch(e => {
            this.error = "Het artikel kon niet worden opgeslagen.";
            this.saving = false
          });
        } else {
          this.error = "Niet alle velden zijn ingevuld.";
          this.saving = false
        }
      },
    }
  }
</script>
<style scoped>
  .multiselect__content-wrapper {
    position: relative;
  }

  .merchant{
    transition-duration: 300ms;
  }

  .merchant:hover{
    background-color: #f0f0f0;
  }
  
  .v-enter-active,
  .v-leave-active {
    transition: opacity 0.5s ease;
  }

  .border-bold {
    border-width: 4px!important;
  }

  .v-enter,
  .v-leave-to {
    opacity: 0;
  }

  .semipolar-spinner, .semipolar-spinner * {
    box-sizing: border-box;
  }

  .semipolar-spinner {
    height: 65px;
    width: 65px;
    position: relative;
  }

  .semipolar-spinner .ring {
    border-radius: 50%;
    position: absolute;
    border: calc(65px * 0.05) solid transparent;
    border-top-color: gray;
    border-left-color: gray;
    animation: semipolar-spinner-animation 2s infinite;
    height: 50px;
    width: 50px;
    z-index: 5;
  }

  @keyframes semipolar-spinner-animation {
    50% {
      transform: rotate(360deg);
    }
  }
</style>