<template>
  <div ref="container">
    
    <div class="container">
      <div class="row">
        <div class="col col-lg-6 mx-auto">
          <div class="text-center" v-if="!sending">
            <div class="btn-group mb-4" role="group">
              <button @click="selectStep('products')" type="button" class="btn btn-sm btn-outline-primary" :class="{active: currentStep == 'products'}">1. Producten</button>
              <button @click="selectStep('details')" type="button" class="btn btn-sm btn-outline-primary" :class="{active: currentStep == 'details'}">2. Adres</button>
              <button @click="selectStep('confirm')" type="button" class="btn btn-sm btn-outline-primary" :class="{active: currentStep == 'confirm'}" :disabled="confirmDisabled">3. Bevestigen</button>
            </div>
          </div>

          <div v-if="currentStep == 'products'">
            <GiftcardProduct @delete="deleteCard" class="mb-4" :packaging="packaging" :index="index" :key="index" :product="product" v-for="(product, index) in products" />
            <div class="text-center">
              <button @click="addNewProduct" class="btn btn-outline-success mx-auto mb-4"><i class="fa fa-plus"></i></button>
            </div>

            <GiftcardCart :products="products" :packaging="packaging" />

            <div class="text-center">
              <button @click="selectStep('details')" class="btn btn-success mx-auto mt-3">volgende stap: adresgegevens <i class="fa fa-chevron-right"></i></button>
            </div>
          </div>


          <div v-if="currentStep == 'details'">
            <form>
              <h3 class="offset-sm-3">Bedrijfsgegevens</h3>
              <div class="form-group row">
                <label for="company_name" class="col-sm-3 col-form-label">Bedrijfsnaam</label>
                <div class="col-sm-9">
                  <input v-model="companyName" type="text" class="form-control" id="company_name">
                </div>
              </div>
                          

              <div class="form-group row">
                <label for="vat_number" class="col-sm-3 col-form-label">BTW ID</label>
                <div class="col-sm-9">
                  <input v-model="vatNumber" type="text" class="form-control" id="vat_number">
                </div>
              </div>

              <div class="form-group row">
                <label for="po_number" class="col-sm-3 col-form-label">PO Nummer</label>
                <div class="col-sm-9">
                  <input v-model="poNumber" type="text" class="form-control" id="po_number">
                  <small class="form-text text-muted">Niet verplicht. Gebruik dit veld om een opdrachtnummer of eigen kenmerk op te geven. Deze verschijnt op de factuur.</small>
                </div>
              </div>

              
              <h3 class="offset-sm-3">Factuurgegevens</h3>

              <div class="form-group row">
                <label for="contact_person" class="col-sm-3 col-form-label">Contactpersoon</label>
                <div class="col-sm-9">
                  <div class="row">
                    <div class="col">
                      <input v-model="contactFirstName" type="text" class="form-control" id="contact_person_first" placeholder="Voornaam">
                    </div>
                    <div class="col">
                      <input v-model="contactLastName" type="text" class="form-control" id="contact_person_last" placeholder="Achternaam">
                    </div>
                  </div>                
                </div>
              </div>
              
              <div class="form-group row">
                <label for="emailField" class="col-sm-3 col-form-label">E-mail</label>
                <div class="col-sm-9">
                  <input v-model="contactEmail" type="email" class="form-control" id="emailField" required>
                  <small class="form-text text-muted">Naar dit adres wordt de factuur gestuurd.</small>
                </div>
              </div>

              <div class="form-group row">
                <label for="comment" class="col-sm-3 col-form-label">Opmerking</label>
                <div class="col-sm-9">
                  <textarea v-model="comment" rows="3" placeholder="Niet verplicht. Schrijf hier eventueel een opmerking of wens met betrekking tot de afhandeling van deze bestelling..." type="text" class="form-control" id="comment" required></textarea>
                </div>
              </div>


              <div class="form-group row">
                <label for="address" class="col-sm-3 col-form-label">Adres</label>
                <div class="col-sm-9">
                  <input @keyup="syncAddresInput" v-model="invoiceAddress" type="text" class="form-control" id="address" placeholder="Straatnaam 123">
                </div>
              </div>

              <div class="form-group row">
                <label for="zip" class="col-sm-3 col-form-label">Postcode</label>
                <div class="col-sm-4">
                  <input @keyup="syncAddresInput" v-model="invoiceZip" type="text" class="form-control" id="zip" placeholder="2244 AB">
                </div>
              </div>

              <div class="form-group row">
                <label for="city" class="col-sm-3 col-form-label">Plaatsnaam</label>
                <div class="col-sm-9">
                  <input @keyup="syncAddresInput" v-model="invoiceCity" type="text" class="form-control" id="city" placeholder="Plaatsnaam">
                </div>
              </div>


              <h3 class="offset-sm-3">Verzendgegevens</h3>

              <div class="form-group offset-sm-3">
                <div class="form-check">
                  <input class="form-check-input" type="checkbox" id="sync" v-model="syncAddress">
                  <label class="form-check-label" for="sync" >
                    Gebruik het factuuradres ook als verzendadres
                  </label>
                </div>
              </div>

              <div class="form-group row">
                <label for="send_address" class="col-sm-3 col-form-label">Adres</label>
                <div class="col-sm-9">
                  <input v-model="sendAddress" :readonly="syncAddress" type="text" class="form-control" id="send_address" placeholder="Straatnaam 123">
                </div>
              </div>

              <div class="form-group row">
                <label for="send_zip" class="col-sm-3 col-form-label">Postcode</label>
                <div class="col-sm-4">
                  <input v-model="sendZip" :readonly="syncAddress" type="text" class="form-control" id="send_zip" placeholder="2244 AB">
                </div>
              </div>

              <div class="form-group row">
                <label for="send_city" class="col-sm-3 col-form-label">Plaatsnaam</label>
                <div class="col-sm-9">
                  <input v-model="sendCity" :readonly="syncAddress" type="text" class="form-control" id="send_city" placeholder="Plaatsnaam">
                </div>
              </div>

              
            </form>

            <div class="text-center">
              <button :disabled="confirmDisabled" @click="selectStep('confirm')" class="btn btn-success mx-auto mt-3">volgende stap: overzicht en bevestigen <i class="fa fa-chevron-right"></i></button>
            </div>

          </div>

          <div v-if="currentStep == 'confirm'">
            <div class="row mb-5">
              <div class="col">
                <h5>Factuurgegevens</h5>
                <strong>{{ companyName }}</strong><br>
                BTW: {{ vatNumber }}<br>
                <div v-if="poNumber.length">PO: {{ poNumber }}</div>
                Contactpersoon: {{ contactFirstName }} {{ contactLastName }}<br>
              </div>
            </div>
            <div class="row mb-5">
              <div class="col">
                <h5>Factuuradres</h5>
                {{ invoiceAddress }}<br>
                {{ invoiceZip }} {{ invoiceCity }}
              </div>
              <div class="col">
                <h5>Verzendadres</h5>
                {{ sendAddress }}<br>
                {{ sendZip }} {{ sendCity }}
              </div>
            </div>

            <h5>Bestelling</h5>
            <GiftcardCart :products="products" :packaging="packaging" />

            <div class="alert alert-info">
              <h5>Wat gebeurt er hierna?</h5>
              <p>De cadeaukaarten worden zo snel mogelijk (doorgaans op dezelfde dag) verzonden naar {{ sendCity }}. De factuur wordt gestuurd naar {{ contactEmail }}. De cadeaukaarten worden actief zodra de factuur betaald is.</p>
            </div>
            <div class="text-center" v-if="!success">
              <button @click="makeOrder" class="btn btn-success btn-lg" :disabled="sending">Bestelling plaatsen</button>
            </div>
            <div v-if="success">
              <p class="lead text-center"><span style="font-size: 60px;">🎉</span><br>{{ message }}</p>
            </div>
          </div>

        </div>
      </div>
    </div>

  </div>
</template>

<script>
import axios from 'axios'
import GiftcardProduct from './GiftcardProduct'
import GiftcardCart from './GiftcardCart'

export default {

  name: 'GiftcardOrderForm',
  components: {GiftcardProduct, GiftcardCart},
  data () {
    return {
      packaging: {
        dwGiftbox: {
          name: 'DagjeWeg.Tickets geschenkverpakking',
          price: 0.50,
          formattedPrice: '€ 0,50'
        },
        none: {
          name: 'Geen verpakking',
          price: 0
        }
      },
      products: [],
      currentStep: 'products',
      companyName: '',
      vatNumber: '',
      poNumber: '',
      contactFirstName: '',
      contactLastName: '',
      contactEmail: '',
      invoiceAddress: '',
      comment: '',
      invoiceCity: '',
      invoiceZip: '',
      sendCity: '',
      sendZip: '',
      sendAddress: '',
      syncAddress: true,
      sending: false,
      success: false,
      message: ''
    } 
  },
  computed: {
    confirmDisabled() {
      return !this.companyName.length || !this.contactFirstName.length || !this.contactLastName.length || !this.invoiceAddress.length || !this.invoiceCity.length || !this.invoiceZip.length || !this.sendCity.length || !this.sendZip.length || !this.sendAddress.length || !this.contactEmail.length
    }
  },
  methods: {
    makeOrder(){
      // :company_name, :vat_number, :po_number, :invoice_city, :invoice_address, :invoice_zip, :shipping_address, :shipping_city, :shipping_zip, :contact_first_name, :contact_last_name, :contact_email, :comment
      this.sending = true
      this.sendHeight()
      axios.post('/giftcard_company_orders', {
        company_name: this.companyName,
        vat_number: this.vatNumber,
        po_number: this.poNumber,
        contact_first_name: this.contactFirstName,
        contact_last_name: this.contactLastName,
        contact_email: this.contactEmail,
        invoice_address: this.invoiceAddress,
        invoice_zip: this.invoiceZip,
        invoice_city: this.invoiceCity,
        shipping_address: this.sendAddress,
        shipping_zip: this.sendZip,
        shipping_city: this.sendCity,
        comment: this.comment,
        order_details: this.products

      }).then((response) => {
        console.log(response.data)
        this.success = response.data.success
        this.message = response.data.message
        this.sendHeight()
        // TODO
      }).catch((error) => {
        console.error(error);
      }).finally(() => {
        // TODO
      });
    },
    selectStep(step) {
      this.currentStep = step
      this.sendHeight()
    },
    sendHeight() {
      setTimeout(function () { 
        if (this.$refs.container) {
          const height = this.$refs.container.offsetHeight
          console.log(`height: ${height}`)

          window.parent.postMessage({
            'height': height + 30,
            'messageId': this.messageId
          }, '*');  
        }
      }.bind(this), 20)

    },
    deleteCard(index) {
      // let products = this.products
      this.products.splice(index, 1)
      this.sendHeight()
      // this.products = products
      // console.log(index)
    },
    syncAddresInput() {
      if (this.syncAddress) {
        this.sendCity = this.invoiceCity
        this.sendAddress = this.invoiceAddress
        this.sendZip = this.invoiceZip
      }
    },
    addNewProduct() {
      const newProduct = {
        value: 25.00,
        quantity: 1,
        giftBox: 'dwGiftbox'
      }
      this.products.push(newProduct)
      this.sendHeight()
    }
  },
  created(){
    this.addNewProduct()
  }
}
</script>

<style lang="css" scoped>
</style>