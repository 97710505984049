<template>
  <div>
    <div class="row">
      <div class="col-3">
        <div class="row">
          <div class="col bg-light rounded p-3">

          <div class="form-group">
            <select v-model="dateType" class="custom-select">
              <option value="buy">Aankoopdatum</option>
              <option value="visit">Bezoekdatum</option>
            </select>

          </div>

          <div class="form-group">
            <label for="fromdate">Vanaf datum</label>
            <input v-model="fromDate" type="date" class="form-control" id="fromdate">
          </div>
          <div class="form-group">
            <label for="fromdate">Tot datum</label>
            <input v-model="untilDate" type="date" class="form-control" id="fromdate">
          </div>

          <hr>

          <div class="form-check">
            <input v-model="scanned" class="form-check-input" type="radio" name="scanRadios" id="scanRadios1" value="both" checked>
            <label class="form-check-label" for="scanRadios1">
              Gescand én ongescand
            </label>
          </div>
          <div class="form-check">
            <input v-model="scanned" class="form-check-input" type="radio" name="scanRadios" id="scanRadios2" value="scanned">
            <label class="form-check-label" for="scanRadios2">
              Alleen gescand
            </label>
          </div>
          <div class="form-check">
            <input v-model="scanned" class="form-check-input" type="radio" name="scanRadios" id="scanRadios3" value="unscanned">
            <label class="form-check-label" for="scanRadios3">
              Alleen ongescand
            </label>
          </div>

          <hr>

          <div class="btn-group w-100 my-2">
            <button @click="chooseTicketKinds" :class="{'active': !allTicketKinds}" class="btn btn-sm btn-outline-primary">Kies ticketsoorten</button>
            <button @click="selectAllTicketKinds" :class="{'active': allTicketKinds}" class="btn btn-sm btn-outline-primary">Alle ticketsoorten</button>
          </div>

          <div v-if="!allTicketKinds">
            <div class="form-check" v-for="(ticketKind, key) in ticketKinds" :key="key">
              <input v-model="selectedTicketKindIds" class="form-check-input" type="checkbox" :value="ticketKind.id" :id="`tk-${ticketKind.id}`">
              <label class="form-check-label" :for="`tk-${ticketKind.id}`">
                {{ ticketKind.name }}
              </label>
            </div>
          </div>

          <hr>


          <div class="btn-group w-100 my-2">
            <button @click="chooseForms" :class="{'active': !allForms}" class="btn btn-sm btn-outline-primary">Kies formulieren</button>
            <button @click="selectAllForms" :class="{'active': allForms}" class="btn btn-sm btn-outline-primary">Alle formulieren</button>
          </div>

          <div v-if="!allForms">
            <div class="form-check" v-for="(form, key) in forms" :key="key">
              <input v-model="selectedFormIds" class="form-check-input" type="checkbox" :value="form.id" :id="`form-${form.id}`">
              <label class="form-check-label" :for="`form-${form.id}`">
                {{ form.name }}
              </label>
            </div>
          </div>


          <button @click="search" :disabled="loading" class="btn btn-block btn-primary mt-3">Filteren</button>
        </div>
        </div>
        <div class="row mt-4">
          <div class="col bg-light rounded p-3">
            <span>Mail Quota</span>
            <div class="progress">
              <div class="progress-bar bg-info progress-bar-striped" role="progressbar" v-bind:style="{ width: progress + '%' }" :aria-valuenow="merchant.remaining_quota" aria-valuemin="0" :aria-valuemax="merchant.monthly_quota">
              </div>
            </div>
            <small>{{merchant.remaining_quota}} / {{merchant.monthly_quota}}</small>
          </div>
        </div>
      </div>

      <div class="col" v-if="tickets.length || loading">
        <center>
          <h2>Aantal resultaten: {{ tickets.length }}</h2>
        </center>
        <div class="text-center w-100">
          <div class="btn-group mx-auto my-3">
            <button @click="exportCSV" :disabled="loading" class="btn btn-outline-primary btn-sm">CSV exporteren</button>
            <button @click="exportMail" :disabled="loading" class="btn btn-outline-primary btn-sm">Mail versturen</button>
          </div>
        </div>

        <div v-if="loading" class="py-5 text-center">
          <i class="fa fa-spinner fa-spin"></i>
        </div>

        <table class="table">
          <thead>
            <th>ID</th>
            <th>Datum en tijd</th>
            <th>Ticketsoort</th>
            <th>Naam</th>
            <th>Barcode</th>
            <th>Scandatum</th>
            <th>Formulier</th>
          </thead>
          <tbody>
            <tr v-for="(ticket, key) in tickets" :key="key">
              <td class="small text-muted">#{{ ticket.id }}</td>
              <td>{{ ticket.date }}</td>
              <td>{{ ticket.ticket_kind.name }}</td>
              <td>{{ ticket.cart.name }}</td>
              <td>{{ ticket.barcode_string }}</td>
              <td>{{ ticket.scanned_at }}</td>
              <td>{{ ticket.form.name }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    
  </div>
</template>

<script>
import axios from 'axios'
export default {

  name: 'TicketExport',
  props: ['merchant', 'forms', 'ticketKinds', 'ticketKindIds', 'formIds', 'defaultFromDate', 'defaultUntilDate', 'hasValidEmail'],
  data () {
    return {
      fromDate: null,
      untilDate: null,
      scanned: 'both',
      dateType: 'buy',
      allTicketKinds: false,
      tickets: [],
      ticketIds: [],
      allForms: false,
      selectedFormIds: [],
      loading: false,
      selectedTicketKindIds: []
    }
  },
  created() {
    this.fromDate = this.defaultFromDate
    this.untilDate = this.defaultUntilDate
    this.selectAllTicketKinds()
    this.selectAllForms()
  },
  computed: {
    progress() {
      if(isNaN(this.merchant.remaining_quota) || isNaN(this.merchant.monthly_quota)){
        return 0
      }else{
        return ((this.merchant.remaining_quota/this.merchant.monthly_quota) * 100).toFixed(0)
      }
    }
  },
  methods: {
    exportMail() {
      this.loading = true
      if (this.hasValidEmail) {
        axios({
          url: `/merchants/${this.merchant.id}/bulk_mails.json`, 
          method: 'POST',
          data: { ticket_ids: this.ticketIds },
          reponseType: 'JSON'
        })
        .then(response => {
          console.log(response.data.id)
          this.loading = false
          window.location.href = `/merchants/${this.merchant.id}/bulk_mails/${response.data.id}/edit`;
        })
        .catch(error => {
          this.loading = false
          console.log(error)
          alert('Er ging iets mis. Probeer het over enkele minuten nogmaals.')
        })

      } else {
        this.loading = false
        alert("Je hebt nog geen 'afzendadres' ingesteld. Ga naar 'Afzendadressen' in het menu.")
      }
    },
    exportCSV () {
      this.loading = true
      axios({
        url: `/merchants/${this.merchant.id}/export_csv`, 
        method: 'POST',
        data: { ticket_ids: this.ticketIds },
        reponseType: 'blob'
      })
      .then(response => {
        console.log(response.data)
        this.loading = false
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'export.csv')
        document.body.appendChild(link)
        link.click()
        // this.tickets = response.data.tickets
      })
      .catch(error => {
        this.loading = false
        console.log(error)
        alert('Er ging iets mis. Probeer het over enkele minuten nogmaals.')
      })
    },
    search () {
      this.loading = true
      axios.post(`/merchants/${this.merchant.id}/tickets_filter.json`, { 
        date_type: this.dateType,
        from: this.fromDate, 
        until: this.untilDate,
        form_ids: this.selectedFormIds,
        scanned: this.scanned,
        ticket_kind_ids: this.selectedTicketKindIds

      })
      .then(response => {
        console.log(response.data)
        this.loading = false
        this.tickets = response.data.tickets
        this.ticketIds = response.data.ticket_ids
      })
      .catch(error => {
        this.loading = false
        console.log(error)
        alert('Er ging iets mis. Probeer het over enkele minuten nogmaals.')
      })

    },
    chooseTicketKinds () {
      this.allTicketKinds = false
    },
    selectAllTicketKinds () {
      this.allTicketKinds = true
      this.selectedTicketKindIds = this.ticketKindIds
    },
    chooseForms () {
      this.allForms = false
    },
    selectAllForms () {
      this.allForms = true
      this.selectedFormIds = this.formIds
    }
  }
}
</script>

<style lang="css" scoped>
</style>