<template>
  <tr :class="{'text-muted': ticket.canceled_at}">
    <td v-if="cancelMode" colspan="4" class="text-center py-3">
      <span v-if="cart.is_admin">Moeten de commissies ook verwijderd worden?</span>
      <div class="w-100 mb-2" v-if="cart.is_admin">
        <button @click="doCancelTicket('tickets_and_commission')" class="btn btn-primary">Annuleer ticket én commissie</button>
        <button @click="doCancelTicket('tickets')" class="btn btn-primary">Annuleer alleen ticket</button>
      </div>
      <span v-if="!cart.is_admin">Weet je het zeker?</span>
      <div class="w-100 mb-2" v-if="!cart.is_admin">
        <button @click="doCancelTicket('tickets')" class="btn btn-primary">Ja, annuleer dit ticket</button>
      </div>
      <button @click="cancelTicket" class="btn btn-secondary btn-sm">annuleer</button>

    </td>
    <td :class="{'canceled': ticket.canceled_at}" v-if="!cancelMode">{{ ticket.ticket_kind}}</td>
    <td :class="{'canceled': ticket.canceled_at}" v-if="!cancelMode">{{ ticket.barcode }}</td>
    <td :class="{'canceled': ticket.canceled_at}" v-if="!cancelMode">{{ ticket.price }}</td>
    <td v-if="!cancelMode && abilities.includes('cancel')"><button @click="cancelTicket()" :disabled="ticket.canceled_at || !ticket.can_cancel" class="btn btn-sm btn-primary">Annuleren</button></td>
  </tr>
</template>

<script>
export default {

  name: 'CartTicket',
  props: ['ticket', 'cart', 'abilities'],
  data () {
    return {
      cancelMode: false
    }
  },
  methods: {
    doCancelTicket(type){
      this.cancelMode = false
      this.$emit('cancel', this.ticket, type)
    },
    cancelTicket() {
      this.cancelMode = !this.cancelMode
    }
  }
}
</script>

<style lang="css" scoped>
</style>