<template>
	<div>
		<h4>Versiegeschiedenis</h4>
		<div  style="height: 300px; overflow-y: auto;">
			<table class="table table-hover table-sm text-left">
				<thead>
					<tr>
						<th>Tijd</th>
						<th>Object</th>
						<th></th>
					</tr>
				</thead>
				<tbody>
						<tr v-for="(version, key) in versions" @click="selectVersion(version)" :key="key">
							<td class="small">{{ version.date }}</td>
							<td class="small">{{ version.item_type }} #{{ version.item_id }}</td>
							<td>
								<span class="badge badge-primary small" v-for="(k,v) in version.changeset" :key="k" v-if="!['updated_at'].includes(v)">{{ v }}</span>

							</td>
						</tr>				
				</tbody>
			</table>
		</div>
		<div v-if="selectedVersion">
			<div v-for="(v,k) in selectedVersion.changeset" :key="k">
				<h5>{{ k }}</h5>
				<h6>Van:</h6>
				<div v-html="v[0]"></div>
				<h6>Naar:</h6>
				<div v-html="v[1]"></div>
			</div>
		</div>
	</div>
</template>

<script>
export default {

  name: 'VersionFeed',
  props: ['versions'],
  data () {
    return {
    	selectedVersion: null
    }
  },
  methods: {
  	selectVersion(version) {
  		this.selectedVersion = version
  	}
  }
}
</script>

<style lang="css" scoped>
</style>