<template>
	<multiselect id="select" v-model="value" @select="select" :options="options" track-by="id" :custom-label="customLabel" :searchable="true" :close-on-select="true" :show-labels="false" placeholder="Wissel tussen aanbieders" :optionHeight="100">
    <template slot="singleLabel" slot-scope="props">
      <div class="option__desc">
        <span v-if="props.option.active" class="bg-success text-white rounded px-1 font-weight-bold">LIVE</span>
        <span class="option__title">{{ props.option.name }} (#{{ props.option.id}})</span>
      </div>
    </template>
    <template slot="option" slot-scope="props">
      <div class="option__desc">
        <span v-if="props.option.active" class="bg-success text-white rounded px-1 font-weight-bold">LIVE</span>
        <span class="option__title" :class="{ 'font-weight-bold': props.option.active }">{{ props.option.name }} (#{{ props.option.id}})</span>
      </div>
    </template>
  </multiselect>
</template>

<script>

export default {
  name: 'MerchantSelector',
  props: ['options'],
  data () {
    return {
      value: ''
    }
  },
  methods: {
  	select(selected) {
  		console.log(selected)
  		window.location.href = selected.url;
  	},
  	customLabel ({ active, name, id }) {
      return `<span>${active}</span> ${name} (#${id})`
    }
  }
};

window.addEventListener('keydown', function(e) {
  if (e.ctrlKey && e.which == 75 || e.metaKey && e.which == 75) {
    document.getElementById("select").focus();
  }
});
</script>

<style></style>