<template>
  <div class="bg-light rounded p-3 border">
    <div class="form-group">
      <div class="text-center"><label>Aantal</label></div>
      <div class="input-group mx-auto w-50">
        <div class="input-group-prepend" @click="decrementQuantity">
          <span class="input-group-text"  style="cursor: pointer;" :class="{'text-muted': product.quantity == 1}"><i class='fa fa-minus'></i></span>
        </div>
        <input v-model="product.quantity" type="number" step="1" class="form-control text-center">
        <div class="input-group-append" @click="incrementQuantity">
          <span class="input-group-text" style="cursor: pointer;"><i class='fa fa-plus'></i></span>
        </div>
      </div>

    </div>
    
    <div class="form-group">
      <div class="text-center"><label>Bedrag</label></div>
      <div class="input-group row ml-0">
        <div v-if="!(chosenValue == 'other' && defaultValue.price == 'other')" class="col-6 col-md-3 px-2" v-for="(defaultValue, key) in defaultValues" :key="key">
          <button @click="chooseOption(defaultValue)" class="my-2 py-3 btn btn-block btn-outline-primary" :class="{'active': (chosenValue == defaultValue.price)}">{{ defaultValue.label }}</button>
        </div>

        <div class="col-6" v-show="chosenValue == 'other'">
          <currency-input ref="other" v-model="product.value" @keyup="setPrice" class="form-control" style="margin-top: 10px; padding: 25px 10px; font-size: 22px;" />
        
        </div>
      </div>

    </div>
  
    <div class="form-group">
      <div class="text-center"><label>Verpakking</label></div>
      
      <div class="form-check" v-for="(option, key) in packageOptions" :key="key">
        <input class="form-check-input" type="radio" :id="`box${option}${index}`" :value="option" v-model="product.giftBox">
        <label class="form-check-label" :for="`box${option}${index}`">
          {{ packaging[option].name }}
          <span v-if="packaging[option].price > 0">(+ {{ packaging[option].formattedPrice }})</span>
        </label>
      </div>

    </div>
  

    <div class="text-right">
      <button @click="deleteCard" v-if="index > 0" class="btn btn-danger btn-sm">verwijderen</button>
    </div>  

  </div>
</template>

<script>
export default {

  name: 'GiftcardProduct',
  props: ['product', 'index', 'packaging'],
  data () {
    return {
      selectedPrice: true,
      chosenValue: 0,
      defaultValues: [
        {label: "€ 10,00", price: 10},
        {label: "€ 25,00", price: 25},
        {label: "€ 30,00", price: 30},
        {label: "€ 50,00", price: 50},
        {label: "€ 75,00", price: 75},
        {label: "€ 100,00", price: 100},
        {label: "Anders...", price: 'other'},
      ]
    }
  },
  computed: {
    packageOptions() {
      return Object.keys(this.packaging)
    }
  },
  methods: {
    deleteCard() {
      this.$emit('delete', this.index)  
    },
    setPrice() {

    },
    chooseOption(option) {
      // console.log(option)
      this.product.value = option.price
      this.chosenValue = option.price
      if (option.price == 'other') {
        console.log('alternative price')
        this.product.value = 25
        // console.log(this.$refs.other)
        setTimeout(function() {
          this.$refs.other.$el.select()
        }.bind(this), 10);
      }
    },
    incrementQuantity() {
      this.product.quantity ++
    },
    decrementQuantity() {
      if (this.product.quantity > 1) {
        this.product.quantity --
      }
    }
  },
  updated() {
    if (this.chosenValue != 'other') {
      this.chosenValue = this.product.value
    }
  },
  created() {
    this.chosenValue = this.product.value
  }
}
</script>

<style lang="css" scoped>
.text-center label {
  font-size: 18px;
  font-weight: 700;
}
</style>