<template>
  <div>
    <fieldset class="scheduler-border">
      <legend class="scheduler-border">Koppeling met Enviso</legend> 
      <div class="center">
        <img height="80" :src="logo">
      </div> 
      <div class="form-inputs">
        <div class="form-group select optional barcode_batch_api_options">
          <input type="hidden" name="barcode_batch[api_options][enviso_venue_id]" v-model="selectedVenueId"></input>
          <label for="enviso_venue_select" class="control-label select optional">Locatie</label>
          <select v-model="selectedVenue" @change="venueSelected" id="enviso_venue_select" class="form-control select optional">
            <option value="">Selecteer een locatie...</option>
            <option v-if="venue.isPartner" :value="venue" v-for="(venue, key) in venues" :key="key">[PARTNER] {{ venue.name }} - {{ venue.city }}</option>
            <option v-if="!venue.isPartner" :value="venue" v-for="(venue, key) in venues" :key="key">{{ venue.name }} - {{ venue.city }}</option>
          </select>
        </div>

        <div v-if="selectedVenueDetails" class="row">
          <div class="col-12 col-md-4" v-if="venueLogo">
            <img v-bind:src="'data:image/png;base64,'+venueLogo" width="100%" class="img-thumbnail" />
            <p class="small">{{selectedVenueDetails.description}}</p>
          </div>
          <div class="col-12 col-md-8">
            
            <ul class="list-group">
              <li class="list-group-item d-flex justify-content-between align-content-center"><span>{{ selectedVenue.name }}</span> <span v-if="selectedVenue.isPartner" class="badge badge-success">Is partner</span><span v-if="!selectedVenue.isPartner" class="badge badge-warning">Is nog geen partner</span></li>
              <li class="list-group-item">Soort: {{ attractionType}} </li>
              <li class="list-group-item">{{ selectedVenueDetails.address.addressLine1 }}</li>
              <li class="list-group-item">{{ selectedVenueDetails.address.postalCode }} {{ selectedVenueDetails.address.locality }}</li>
              <li class="list-group-item">{{ selectedVenueDetails.address.country }}</li>
              <li v-if="selectedVenueDetails.website" class="list-group-item"><a target="_blank" :href="selectedVenueDetails.website">{{ selectedVenueDetails.website }}</a></li>
              <li class="list-group-item">Barcode type: {{ selectedVenueDetails.barcodeFormat }}</li>
            </ul>
          </div>
        </div>

        <div v-if="offers.length" class="form-group select optional barcode_batch_api_options">
          <label for="enviso_offer_select" class="control-label select optional">Aanbieding</label>
          <select v-model="selectedOffer" @change="offerSelected" id="enviso_offer_select" name="barcode_batch[api_options][enviso_offer_id]" class="form-control select optional">
            <option value="">Selecteer een aanbieding...</option>
            <option :value="offer.id" v-for="(offer, key) in offers" :key="key">{{ offer.name }}</option>
          </select>
        </div>
        <figure class="figure" v-if="offerImage">
          <img  v-bind:src="'data:image/png;base64,'+offerImage" width="100%" class="figure-img img-fluid rounded" />
          <figcaption class="figure-caption">{{ currentOffer.description }}</figcaption>
        </figure>
        <ul v-if="offerDetails" class="list-group mb-3">
          <li class="list-group-item"><b>ID</b>: {{ offerDetails.id }} </li>
          <li class="list-group-item"><b>Status</b>: {{ offerStatus }} </li>
          <li class="list-group-item"><b>Startdatum</b>: {{ offerDetails.startDate}} </li>
          <li class="list-group-item"><b>Einddatum</b>: {{ offerDetails.endDate}} </li>
          <li class="list-group-item"><b>Startdatum verkoop</b>: {{ offerDetails.salesStartDate}} </li>
          <li class="list-group-item"><b>Einddatum verkoop</b>: {{ offerDetails.salesEndDate}} </li>
          <li class="list-group-item"><b>Soort boeking</b>: {{ offerCapacityType}} </li>
          <li class="list-group-item">
            <b>Gealloceerd</b>: {{ offerDetails.capacityAllocation.allocatedQuantity }} 
            <span class="small text-muted" v-if="offerDetails.capacityAllocation">gedeeld met andere gebruikers</span>
            <span class="small text-muted" v-if="!offerDetails.capacityAllocation">NIET gedeeld met andere gebruikers</span>
          </li>
          
          
        </ul>

        <div v-if="offerDetails" class="form-group select optional barcode_batch_api_options">
          <label for="enviso_offer_select" class="control-label select optional">Product</label>
          <select v-model="selectedProduct" id="enviso_product_select" name="barcode_batch[api_options][enviso_product_id]" class="form-control select optional">
            <option value="">Selecteer een product...</option>
            <option :value="product.id" v-for="(product, key) in offerDetails.products" :key="key">{{ product.id }} - {{ product.name }} (€{{product.price}})</option>
          </select>
        </div>
        <div class="clearfix"></div><br> 
        <div class="text-center" v-if="loading"><i class="fas fa-spinner fa-spin"></i></div>
      </div>
    </fieldset>
  </div>
</template>

<script>

import axios from 'axios'

export default {
  props: ["logo", "venue_id", "offer_id", "product_id"],
  data() {
    return {
      venues: [],
      selectedVenueId: null,
      selectedVenue: '',
      offers: [],
      selectedOffer: '',
      selectedVenueDetails: null,
      loading: true,
      offerDetails: null,
      venueLogo: null,
      offerImage: null,
      selectedProduct: ''
    }
  },
  computed: {
    currentOffer(){
      if (this.offers.length && this.selectedOffer) {
        for (var index in this.offers){
          if (this.offers[index].id == this.selectedOffer){
            return this.offers[index]
          }
        }
      }
      return {}
    },
    offerCapacityType(){
      if (this.offerDetails) {
        switch (parseInt(this.offerDetails.capacityAllocation.type)) {
          case 1:
            return 'Totaal'
          case 2:
            return 'Per dag'   
          case 3:
            return 'Per tijdslot'   
          default:
            return '';
        }
      } else {
        return ''
      }
    },
    offerStatus(){
      if (this.offerDetails) {
        switch (parseInt(this.offerDetails.status)) {
          case 1:
            return 'Beschikbaar'
          case 4:
            return 'Verlopen'   
          default:
            return '';
        }
      } else {
        return ''
      }
    },
    attractionType(){
      if (this.selectedVenue) {
        switch (parseInt(this.selectedVenue.type)) {
          case 0:
            return 'Amusement park'
          case 1:
            return 'Attraction'   
          case 2:
            return 'Aquarium'        
          case 3:
            return 'Museum'        
          case 4:
            return 'Zoo'        
          case 5:
            return 'Overig...'        
          default:
            return '';
        }
      } else {
        return ''
      }
    }
  },
  methods: {
    venueSelected(){
      this.loading = true
      this.selectedOffer = null
      this.selectedProduct = ''
      this.selectedVenueId = this.selectedVenue.id
      this.getVenueDetails()
      this.getOffers()
    },
    getVenueDetails(){
      axios.get(`/partners/enviso/venues/${this.selectedVenue.id}`)
        .then(response => {
          this.selectedVenueDetails = response.data
        })
    },
    offerSelected(){
      if (this.product_id != undefined) {
        this.selectedProduct = this.product_id
      }
      this.getOfferDetails()
    },
    getLogo(){
      this.venueLogo = null
      if (this.selectedVenue.logoImageUrl){

        axios.get(`/partners/enviso/api.json?url=${this.selectedVenue.logoImageUrl}`)
        .then(response => {
          this.venueLogo = response.data.image
        })
      }
    },
    getOffers(){
      this.offers = []
      this.offerDetails = null
      this.getLogo()

      if (this.selectedVenue.isPartner){
        axios.get(`/partners/enviso/venues/${this.selectedVenue.id}/offers.json`)
        .then(response => {
          this.offers   = response.data
          this.loading  = false
          if (this.offer_id){
            this.selectedOffer = this.offer_id
            this.getOfferDetails()
          }
        })
      } else {
        this.loading  = false
      }
    },
    getOfferImage(){
      this.offerImage = null
      if (this.currentOffer && this.currentOffer.imageUrl){

        axios.get(`/partners/enviso/api.json?url=${this.currentOffer.imageUrl}`)
        .then(response => {
          this.offerImage = response.data.image
        })
      }

    },
    getOfferDetails(){
      this.loading = true
      this.getOfferImage()
      axios.get(`/partners/enviso/offers/${this.selectedOffer}.json`)
        .then(response => {
          this.loading  = false
          this.offerDetails = response.data
        })
    }
  },
  mounted() {
    
    if (this.venue_id != undefined){
      this.selectedVenueId = this.venue_id 
    }
    if (this.product_id != undefined) {
      this.selectedProduct = this.product_id
    }
    
    axios.get("/partners/enviso/venues.json")
    .then(response => {
      this.venues   = response.data
      this.loading  = false
      if (this.venue_id){
        for (let index = 0; index < this.venues.length; index++) {
          if (this.venues[index].id == this.venue_id){
            this.selectedVenue = this.venues[index]
          }
          
        }
        
        this.loading = true
        this.getVenueDetails()
        this.getOffers()
      }
    })
    
  },
}
</script>

<style>

</style>