<template>
  <tr @click="emitClick" style="cursor: pointer;" v-bind:class="{ strikeout: cancelled }">
    <td v-if="!cart.is_anoymized && abilities.includes('view_consumer_details')">{{ cart.name }}</td>
    <td class="text-muted" v-if="cart.is_anoymized">Anoniem</td>
    <td v-if="!isRetail" v-html="cart.contents"></td>
    <td>
      <span v-for="(visit_date, key) in cart.chosen_dates" :key="key">{{ visit_date.date }}</span>
    </td>
    <td>{{ cart.paid_at }}</td>
    <td v-if="isRetail">{{ cart.order_number }}</td>
    <td v-if="!isRetail">{{ cart.channel }}</td>
    <td v-if="!isRetail">{{ cart.price }}</td>
  </tr>
</template>

<script>
export default {

  name: 'CartRow',
  props: ['cart', 'abilities', 'isRetail'],
  data () {
    return {

    }
  },
  computed: {
    cancelled() {
      return this.cart.is_cancelled
    }
  },
  methods: {
    emitClick() {
      this.$emit('click', this.cart)
    }
  }
};
</script>

<style lang="css" scoped>
</style>