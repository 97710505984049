<template>
  <tr>
    <td v-if="loading" colspan="6" class="text-center">
      <i class='fa fa-spinner fa-spin py-1'></i>
    </td>
    <td :class="{canceled, 'text-muted' : canceled}" v-if="!loading" class="py-1 text-muted small">{{ theReservation.id }}</td>
    <td :class="{canceled, 'text-muted' : canceled}" v-if="!loading && abilities.includes('view_consumer_details')" class="py-1">
      {{ theReservation.name }}
      <div v-if="theReservation.comment"><i class="fas fa-info-circle"></i> <i>{{ theReservation.comment }}</i></div>
    </td>
    <td :class="{canceled, 'text-muted' : canceled}" v-if="!loading" class="py-1 d-flex justify-content-between">
      <span>{{ theReservation.ticket_kind.name }}</span>
      <span v-if="theReservation.number_of_people > 1">{{ theReservation.number_of_people }} <i class='fa fa-users'></i></span>
    </td>
    <td :class="{canceled, 'text-muted' : canceled}" v-if="!loading" class="py-1">
      {{ theReservation.show_time }}
      <i data-toggle="tooltip" title="Gereserveerde tijd is anders dan de huidge instellingen voor dit tijdvak" class="fas fa-exclamation-triangle text-warning" v-if="theReservation.has_different_time"></i>
    </td>
    <td :class="{canceled, 'text-muted' : canceled}" v-if="!loading && !isRetail" class="py-1">
      <span v-if="theReservation.barcode">
        <span style="font-family: monospace; font-size: 16px;">{{ theReservation.barcode }}</span>
        <i v-if="theReservation.scanned" class="ml-2 fas fa-check-double text-success"></i>
      </span>
    </td>
    <td :class="{canceled, 'text-muted' : canceled}" v-if="!loading && isRetail" class="py-1">
      <span v-if="theReservation.order_number">
        <span style="font-family: monospace; font-size: 16px;">{{ theReservation.order_number }}</span>
      </span>
    </td>
    <td v-if="!loading" class="py-1 text-right">
      <div class="btn-group dropleft" v-if="!canceled">
        <button type="button" class="btn btn-sm btn-outline-primary dropdown-toggle" data-toggle="dropdown">
          actie
        </button>
        <div class="dropdown-menu">
          <button class="dropdown-item" @click="cancel">Annuleren</button>
          <button class="dropdown-item" @click="cancelAll" v-if="theReservation.total_reservations > 1">Annuleer alle {{ theReservation.total_reservations }} reserveringen</button>
          <a v-if="theReservation.ticket && theReservation.ticket.cart_id != undefined" :href="`/merchants/${merchantId}/carts/${theReservation.ticket.cart_key}/change_date`" class="dropdown-item">Datum en tijd veranderen</a>
          <a v-if="theReservation.ticket && theReservation.ticket.cart_id != undefined" :href="`/merchants/${merchantId}/orders?q=${theReservation.ticket.cart_id}`" class="dropdown-item">Opzoeken in 'Bestellingen'</a>
          <!-- <a class="dropdown-item" href="#">Ticket Downloaden</a> -->
          <button v-if="theReservation.barcode && !theReservation.scanned" class="dropdown-item" @click="markScanned">Als gescand markeren</button>
          <button v-if="theReservation.barcode && theReservation.scanned" class="dropdown-item" @click="markScanned">Als ongescand markeren</button>
          <button v-if="theReservation.ticket && theReservation.ticket.cart_id != undefined" class="dropdown-item" @click="sendEmail">Alle tickets e-mailen</button>
          <a v-if="theReservation.ticket && theReservation.ticket.cart_id != undefined" :href="`/carts/${theReservation.ticket.cart_key}/download_tickets`" class="dropdown-item">Hele bestelling downloaden (PDF)</a>

        </div>
      </div>
    </td>
  </tr>
</template>

<script>
import axios  from 'axios'
export default {

  name: 'DWTCapcityCheckBlockLine',
  props: ['reservation', 'merchantId', 'capacityPlannerId', 'abilities', 'isRetail'],
  data () {
    return {
      loading: false,
      theReservation: null,
      // canceled: false
    }
  },

  created() {
    this.theReservation = {...this.reservation}
  },
  computed: {
    canceled() {
      return this.theReservation.canceled_at && this.theReservation.canceled_at.length
    }
  },
  methods: {
    sendEmail(){
      // this.sendingMail = true
      axios.get(`/carts/${this.theReservation.ticket.cart_key}/resend_cart_email.json`).then((response) => {
        // this.sendingMail = false
        // this.mailSent = true
        // alert('E-mail is opnieuw verzonden!')
        alert(response.data.message)
        // setTimeout(function () { 
        //   this.mailSent = false
        // }.bind(this),3000)
        // TODO
      }).catch((error) => {
        console.error(error);
      }).finally(() => {
        // TODO
      });
    },
    markScanned() {
    this.loading = true
      axios.get(`/merchants/${this.merchantId}/merchant_capacity_planners/${this.capacityPlannerId}/ticket_kind_reservations/${this.theReservation.id}/toggle-scan.json`)
      .then(response => {
        console.log(response.data)
        this.theReservation = response.data
        this.loading = false
        // this.canceled = true
      })
    },
    cancelAll() {
      this.loading = true
      
      axios.get(`/merchants/${this.merchantId}/merchant_capacity_planners/${this.capacityPlannerId}/reservations/${this.theReservation.reservation_id}/cancel.json`)
      .then(response => {
        console.log(response.data)
        this.loading = false
        this.$emit('updateBlock')
      })
    },
    cancel() {
      this.loading = true
      axios.get(`/merchants/${this.merchantId}/merchant_capacity_planners/${this.capacityPlannerId}/ticket_kind_reservations/${this.theReservation.id}/cancel.json`)
      .then(response => {
        console.log(response.data)
        this.theReservation = response.data
        this.loading = false
        // this.canceled = true
      })
    }
  }
}
</script>

<style lang="css" scoped>
</style>