<template>
  <div>
    <div class="row">
      <div class="col-12 col-md-8 col-lg-6 mx-auto mb-2">
        <center><h3>Selecteer een datum</h3></center>
        <center v-if="dateLoading">
          <i class='fa fa-spinner fa-spin'></i>
        </center>
        <date-picker v-if="!dateLoading" v-model="date" :config="options" @dp-change="dateChanged"></date-picker>
      </div>
    </div>
    <div class="row" v-if="loading">
      <div class="col text-center">
        <i class="fa fa-spinner fa-spin"></i>
      </div>  
    </div>
    <div class="row text-left">
      <div class="col">
        <DWTCapacityCheckBlock :abilities="abilities" :block="block" :capacity-planner-id="capacityPlannerId" :is-retail="false" :merchant-id="merchantId" v-for="(block, key) in reservations" :key="key" />
      </div>

    </div>
  </div>
</template>

<script>
import datePicker from 'vue-bootstrap-datetimepicker';
import axios from 'axios'
import moment from 'moment'
import DWTTicketSelector from './DWTTicketSelector.vue'
import DWTCapacityCheckBlock from './DWTCapacityCheckBlock.vue'
import DWTCart from './DWTCart.vue'
export default {
  props: ['capacityPlannerId', 'locale', 'formId', 'adminMode', 'merchantId', 'abilities'],
  components: {
    datePicker,
    DWTTicketSelector,
    DWTCart,
    DWTCapacityCheckBlock
  },
  data () {
    return {
      dates: [],
      date: null,
      dateLoading: true,
      reservations: [],
      loading: false
    }
  },
  methods: {
    updateBlock() {
      console.log('updating...')
      this.dateChanged()
    },
    getAvailableDates() {
      axios.post(`/partners/dwt/dates.json`, {planning_id: this.capacityPlannerId})
      .then(response => {
        console.log('response: ')
        console.log(response.data)
        this.dateLoading = false
        this.dates = response.data
      })
    },
    dateChanged(){
      console.log('date changed triggered')
      this.cart = {}
      this.reservations = []
      this.ticketKinds = []
      this.isConfirmed = false
      this.loading = true
      axios.post(`/partners/dwt/reservations.json`, {planning_id: this.capacityPlannerId, date: this.date})
        .then(response => {
          console.log('response: ')
          console.log(response.data)
          this.loading = false
          this.reservations = response.data.reservations
          this.ticket_kinds = response.data.ticket_kinds
        })
      // this.isConfirmed = false
      // this.message = "De beschikbaarheid wordt gecontroleerd..."
        // this.checkEnvisoCapacity()  
    }
  },
  computed: {
    options() {
      return {
        format: 'YYYY-MM-DD',
        inline: true,
        useCurrent: true,
        minDate: false,
        calendarWeeks: false,
        locale: this.locale,
        enabledDates: null
      }
    }
  },
  created() {
    this.getAvailableDates()
  }
}
</script>

<style>
  .dwt-table.table-striped td, .dwt-table.table-striped th{
    padding-top: 4px;
    padding-bottom:4px;
    vertical-align: middle;
  }
</style>