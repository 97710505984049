<template>
  <div class="list-group m-1">
    <div v-for="(cart, index) in carts" :key="index" class="list-group-item list-group-item-action flex-column align-items-start mb-1">
        <div>
          <div class="d-flex w-100 justify-content-between">
            <div>
              <i v-if="!cart.shop" class="fas fa-store-alt fa-2x d-inline"></i>
              <i v-if="cart.refering_domain == 'google'" class="fab fa-google text-danger fa-2x d-inline mr-1"></i>
              <img v-if="cart.refering_domain == 'dagjeweg'" height="36" width="36" class="d-inline mr-1" src="https://www.dagjeweg.nl/logo-dagjeweg-54x54.png"/>
              <i v-if="cart.refering_domain == 'bing'" class="fab fa-microsoft fa-2x  d-inline mr-1"></i>  
              <i v-if="cart.refering_domain == 'yahoo'" class="fab fa-yahoo fa-2x text-warning  d-inline mr-1"></i>
              <i v-if="cart.refering_domain == 'whatsapp'" class="fab fa-whatsapp fa-2x text-success d-inline mr-1"></i>
              <i v-if="cart.refering_domain == 'paypal'" class="fab fa-paypal fa-2x d-inline mr-1"></i>
              <i v-if="cart.refering_domain == 'facebook'" class="fab fa-facebook fa-2x text-primary d-inline mr-1"></i>
              <i v-if="cart.refering_domain == 'ecosia'" class="fas fa-tree fa-2x text-success d-inline mr-1"></i>
              <h5 class="mb-1 mr-2 d-inline">{{ cart.name }} > <span class="mr-2" v-for="(item, index) in cart.items" :key="index"><span class="font-weight-bold text-primary h3">{{item.quantity}}x</span> {{item.merchant}} ({{item.ticket}})</span></h5>
            </div>
            <div>
              <small style="width: 150px;" class="text-right d-block">{{ timeSince(cart.paid_at) }}</small>
              <span class="badge badge-success float-right" v-if="new_ids.includes(cart.id)">NEW</span>
            </div>
          </div>
          <p class="d-inline" v-if="cart.references">Referenties: <span v-for="(reference, index) in cart.references" :key="index" class="font-weight-bold">{{reference}}, </span></p>
          <i v-if="cart.references && cart.references.join(',').includes('adw')" class="fas fa-euro-sign fa-2x text-success d-inline float-right"></i>
          <p v-if="cart.member" class="d-block mb-0">Al <span class="text-warning font-weight-bold h5">{{cart.member}}x</span> eerder besteld.</p>
          <small class="d-block">{{cart.id}}</small>
        </div>
    </div>
  </div>
</template>

<script>
  import axios from 'axios'
  export default {
    methods: {
      setCurrentTime () { 
        this.current_time = Math.round(+new Date()/1000)
      },
      timeSince(paid_at) {
        var diff = this.current_time - paid_at
        if( diff > 31556926) {
          return Math.round(diff/31556926) + " Jaren geleden"
        } else if ( diff > 2629743) { 
          return Math.round(diff/2629743) + " Maanden geleden"
        } else if ( diff > 604800) { 
          return Math.round(diff/604800) + " Weken geleden"
        } else if ( diff > 86400) { 
          return Math.round(diff/86400) + " Dagen geleden"
        } else if ( diff > 3600) { 
          return Math.round(diff/3600) + " Uren geleden"
        } else if ( diff > 60) { 
          return Math.round(diff/60) + " Minuten geleden"
        } else {
          return diff + " Seconden geleden"
        }
      },
      getNewCarts() {
        if(this.carts.length > 1) {
          this.new_ids = []
          axios.get("feed.json", {params: { ids: this.carts.map(a => a.id).join(",")}})
            .then(res => {
              res.data.forEach(item => {
                this.carts.unshift(item)
                this.new_ids.unshift(item.id)
                this.carts.pop();
                var audio = new Audio('https://www.dagjewegtickets.nl/sound.wav');
                audio.play();
              });
            })
            .catch(err => {
              console.error(err); 
            })
        } else {
          axios.get("feed.json")
            .then(res => {
              this.carts = res.data
            })
            .catch(err => {
              console.error(err); 
            })
        }
      }
    },
    created () {
      this.getNewCarts()
      this.setCurrentTime()
      setInterval(this.setCurrentTime, 5000);
      setInterval(this.getNewCarts, 20000);
    },
    data () {
      return {
        carts: [],
        current_time: 0,
        new_ids: [],
      }
    },
    name: "CartFeed",
  }
</script>