<template>
  <div class="list-group-item d-flex justify-content-between" style="cursor: -webkit-grab; cursor: grab;">
    <div class="d-flex" v-if="item">
      <i class="fas fa-arrows-v mr-3 align-self-center"></i>
      <span v-if="formItem.sub_page_id" class="d-inline-block badge m-auto badge-pill badge-primary">Subpagina</span>
      <div class="ml-2 d-inline-block">
        <div class="my-0">{{ item.name }}</div>
        <small>{{ item.description }}</small>
      </div>
    </div>
    <button @click="deleteItem" class="btn btn-sm btn-danger">verwijderen</button>
  </div>
</template>

<script>
export default {

  name: 'FormComposerItem',
  props: ['formItem', 'ticketKinds', 'subPages'],
  data () {
    return {

    }
  },
  methods: {
    deleteItem() {
      this.$emit('delete', this.formItem)
    }
  },
  computed: {
    item() {
      if (this.formItem.ticket_kind_id) {
        return this.ticketKind
      } else {
        return this.subPage
      }
    },
    subPage() {
      return this.subPages[String(this.formItem.sub_page_id)]
    },
    ticketKind() {
      return this.ticketKinds[String(this.formItem.ticket_kind_id)]
    }
  }
}
</script>

<style lang="css" scoped>
</style>