<template>
  <div class="modal-content">
    <div class="modal-header">
      <h5 class="modal-title">{{ cart.name }}</h5>
      <button type="button" class="close" data-dismiss="modal" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body" v-if="showRefunds">
      <p class="bg-light rounded p-4"><b>Let op: </b> terugbetalen staat los van annuleren. Je kunt het hele bedrag terug betalen, of een gedeelte. Maximaal terug te betalen: {{ cart.max_to_refund_string }}</p>

      <table class="table" v-if="cart.refunds && cart.refunds.length">
        <thead>
          <tr>
            <th>Datum</th>
            <th>Bedrag</th>
            <th>Kosten</th>
            <th>Door</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(refund, key) in cart.refunds" :key="key">
            <td>{{ refund.date }}</td>
            <td>{{ refund.amount }}</td>
            <td>{{ refund.fee }}</td>
            <td>{{ refund.user }}</td>
          </tr>
        </tbody>
      </table>
      <div class="bg-light rounded my-3 p-3" v-if="!cart.refunds || !cart.refunds.length">
        Er zijn nog geen terugbetalingen geweest voor deze bestelling.
      </div>
      <div class="form-group">
        <label for="amount">Bedrag</label>
        <currency-input @keyup="refundMessage = ''" class="form-control" v-model="refund.amount" />
      </div>

      <div class="my-2 alert alert-danger" v-if="refundMessage.length">
        {{ refundMessage }}
      </div>

      <div class="form-check" v-if="cart.is_admin ">
        <input class="form-check-input" v-model="refund.fee" type="checkbox" id="chargeFee">
        <label class="form-check-label" for="chargeFee">
          Reken terugbetaalkosten
        </label>
      </div>


      <div class="text-center">
        <button @click="makeRefund" :disabled="refundDisabled || cart.max_to_refund == 0" class="btn btn-primary my-2">Terugbetalen</button>
        <span class="text-muted">of </span>
        <button @click="toggleRefund" class="btn btn-secondary btn-sm">annuleer</button>
      </div>


    </div>  
    <div class="modal-body" v-if="!showRefunds">
      <div class="row">
        <div class="col">

          <h4>Details</h4>
          <table class="table table-sm">
            <tbody>
              <tr class="small">
                <th>ID</th>
                <td class="text-muted">#{{ cart.id }}</td>
                <td></td>
              </tr>

              <tr v-if="abilities.includes('view_consumer_details')">
                <th>Naam</th>
                <td v-if="!cart.is_anoymized">{{ cart.name }}</td>
                <td class="text-muted" v-if="cart.is_anoymized">Anoniem</td>
                <td></td>
              </tr>
              <tr v-if="abilities.includes('view_consumer_details')">
                <th>E-mail</th>
                <td v-if="!cart.is_anoymized">{{ cart.email }}</td>
                <td class="text-muted" v-if="cart.is_anoymized">Anoniem</td>
                <td></td>
              </tr>
              <tr>
                <th>Besteldatum</th>
                <td>{{ cart.paid_at }}</td>
                <td></td>
              </tr>
            </tbody>
          </table>

          <h4 v-if="isRetail">Antwoorden</h4>
          <table v-if="isRetail" class="table table-sm">
            <tbody>
              <tr v-if="abilities.includes('view_consumer_details')">
                <th>Bestelnummer</th>
                <td>{{ cart.order_number }}</td>
                <td></td>
              </tr>
            </tbody>
          </table>

          <h4>Tickets</h4>
          <table class="table table-sm">
            <thead>
              <tr>
                <th>Ticketsoort</th>
                <th>Barcode</th>
                <th>Prijs</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <cartTicket @cancel="cancelTicket" :abilities="abilities" :cart="cart" :ticket="ticket" :key="`ticket-${ticket.id}`" v-for="ticket in cart.tickets" />
              <tr v-if="!cart.tickets || !cart.tickets.length">
                <td colspan="3" class="text-muted text-center">
                  Geen tickets
                </td>
              </tr>
              <td v-if="cancelMode" colspan="4" class="text-center py-3">
                <span v-if="cart.is_admin">Moeten de commissies ook verwijderd worden?</span>
                <div class="w-100 mb-2" v-if="cart.is_admin">
                  <button @click="doCancelCart('tickets_and_commission')" class="btn btn-primary" :disabled="isCancelling">Annuleer tickets én commissie</button>
                  <button @click="doCancelCart('tickets')" class="btn btn-primary" :disabled="isCancelling">Annuleer alleen tickets</button>
                </div>
                <span v-if="!cart.is_admin">Weet je het zeker?</span>
                <div class="w-100 mb-2" v-if="!cart.is_admin">
                  <button @click="doCancelCart('tickets')" class="btn btn-primary" :disabled="isCancelling">Ja, annuleer tickets</button>
                </div>
                <button @click="cancelMode = false" class="btn btn-secondary btn-sm">annuleer</button>

              </td>
              <tr v-if="cart.can_cancel && !cancelMode && abilities.includes('cancel')">
                <td colspan="4" v-if="!cancelMode" class="text-center">
                  <button @click="cancelCart" class="btn btn-sm btn-primary">Hele bestelling annuleren</button>
                </td>
              </tr>
            </tbody>
          </table>          

          <div class="list-group">
            <a :href="cart.download_url" class="list-group-item list-group-item-action">Download Tickets</a>
            <a :href="cart.regenerate_url" class="list-group-item list-group-item-action">Genereer Opnieuw</a>
            <button v-if="!cart.is_anoymized && abilities.includes('view_consumer_details')" @click="anonymize" :disabled="loading" class="list-group-item list-group-item-action" data-confirm="Alle persoonlijke gegevens van deze winkelmand (naam, email) worden verwijderd.">Gegevens anonimiseren</button>
            <a v-if="cart.change_date_url && abilities.includes('cancel')" :href="cart.change_date_url" target="_blank" class="list-group-item list-group-item-action">Datum en tijd veranderen</a>
            <a 
              :href="url.url" 
              v-for="(url, key) in cart.payment_urls" 
              :key="key"
              target="_blank" 
              class="list-group-item list-group-item-action"
              v-if="abilities.includes('view_consumer_details')"
              >Bekijk transactie in Mollie</a>
            <button @click="resendEmail" class="list-group-item list-group-item-action">{{ mailBtn }}</button>
            <button v-if="cart.can_refund && abilities.includes('refund')" @click="toggleRefund" class="list-group-item list-group-item-action">Terugbetalen</button>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import CartTicket from './CartTicket.vue'
export default {

  name: 'CartDetails',
  props: ['cart', 'merchantId', 'abilities', 'isRetail'],
  components: {CartTicket},
  data () {
    return {
      loading: false,
      showRefunds: false,
      refundDisabled: false,
      mailBtn: 'E-mail hersturen',
      cancelMode: false,
      isCancelling: false,
      refund: {},
      refundMessage: ''
    }
  },
  mounted() {
    if (this.cart && this.cart.id) {
      console.log('mounted cart')
      console.log(this.cart)
      this.fetchCartDetails()
      this.resetRefund()
    }
  },
  methods: {
    cancelCart() {
      this.cancelMode = true
    },
    makeRefund() {
      this.refundMessage = ''
      this.refundDisabled = true
      axios.post(`/carts/${this.cart.key}/refunds.json`, {
        refund: {
          amount: this.refund.amount,
          charge_fee: this.refund.fee
        },
      }).then((response) => {
        this.refundDisabled = false
        // console.log(response)
        this.$emit('update', response.data)
        this.refund.amount = response.data.max_to_refund
      }).catch((error) => {
        // console.log('error')
        // console.log(error.response)
        if (error.response.status === 422) {
          this.refundDisabled = false
          this.refundMessage = error.response.data
        } else {
          console.error(error);
        }
      }).finally(() => {
        // TODO
      });
      // todo
    },
    resetRefund() {
      this.refund = {
        fee: true,
        amount: 0
      }
    },
    cancelTicket(ticket, type) {
      axios.put(`/merchants/${this.merchantId}/tickets/${ticket.id}/cancel.json`, {
        cancel_type: type,
      }).then((response) => {
        this.$emit('update', response.data)
      }).catch((error) => {
        console.error(error);
      }).finally(() => {
        // TODO
      });
    },
    doCancelCart(type) {
      this.isCancelling = true
      let remove_commissions = false
      if (type == 'tickets') {
        remove_commissions = false
      } else {
        remove_commissions = true
      }
      axios.get(`/carts/${this.cart.key}/cancel.json`, {
        remove_commissions: remove_commissions,
      }).then((response) => {
        this.$emit('update', response.data)
        this.isCancelling = false
        this.cancelMode = false
      }).catch((error) => {
        console.error(error);
      }).finally(() => {
        // TODO
      });
    },
    resendEmail() {
      this.loading = true
      axios.get(this.cart.resend_email_url)
      .then((response) => {
        console.log(response)
        this.loading = false
        this.mailBtn = response.data.message
        setTimeout(function() {
          this.mailBtn = "E-mail hersturen"
        }.bind(this), 5000)
      }).catch((error) => {
        console.error(error);
      }).finally(() => {
        // TODO
      });
    },
    toggleRefund() {
      this.showRefunds = !this.showRefunds
    },
    fetchCartDetails() {
      this.loading = true
      let url = ''
      if (this.merchantId) {
        url = `/cart-details/${this.cart.key}.json?merchant_id=${this.merchantId}`
      } else {
        url = `/cart-details/${this.cart.key}.json`
      }
      axios.get(url)
      .then((response) => {
        this.refund.amount = response.data.max_to_refund
        this.$emit('update', response.data)
        this.loading = false
        // TODO
      }).catch((error) => {
        console.error(error);
      }).finally(() => {
        // TODO
      });
    },
    anonymize() {
      this.loading = true
      axios.get(`${this.cart.anonymize_url}?merchant_id=${this.merchantId}`)
      .then((response) => {
        this.$emit('update', response.data)
        console.log(response.data)
        this.loading = false
        // TODO
      }).catch((error) => {
        console.error(error);
      }).finally(() => {
        // TODO
      });
    }
  }
}
</script>

<style lang="css" scoped>
</style>