<template>
  <div class="list-group-item slideInDown animated" :class="`bg-${color}`">
    <div v-if="loading" class="text-center mb-1">
      <i class="fal fa-spinner fa-spin" />
    </div>
    <div class="d-flex justify-content-between">
      <h5>{{ result }}</h5>  
      <div v-if="toggleScanUrl && ticket && ticket.scanned_at" class="text-right">
        <button @click="markAsUnscanned" class="btn btn-light btn-sm">Markeer als ongescand</button>
      </div>
      <div v-if="toggleScanUrl && ticket && !ticket.scanned_at" class="text-right">
        <button @click="markAsUnscanned" class="btn btn-light btn-sm">Markeer als gescand</button>
      </div>
    </div>
    <h5 v-if="ticket_kind">{{ ticket_kind }}</h5>
    <h5 v-if="customer">{{ customer }}</h5>
    <div v-if="!(ticket && !ticket.scanned_at)" v-html="message" />
    <div v-if="ticket">
      <strong>Gescand: </strong>
      <span v-if="ticket.scanned_at">Ja, op {{ ticket.scanned_at }}</span>
      <span v-if="!ticket.scanned_at">Nee</span>
    </div>
    <div v-if="scanResult.change_date_url" class="bg-light rounded p-3">
      De datum komt niet overeen met het gekozen tijdstip. Als je wilt, kun je deze reservering verplaatsen naar een ander tijdstip.
      <div class="alert alert-success" v-if="newBookingResult.date">
        <b>Nieuw tijdslot gekozen:</b> {{ newBookingResult.date }}
      </div>
      <div class="text-center" v-if="!pickDate">
        <button class="btn btn-primary" @click="pickDate = true">Ticket omboeken</button>
        <button @click="markAsUnscanned" class="btn btn-secondary" v-if="newBookingResult.date && toggleScanUrl && ticket && ticket.scanned_at">Markeer als ongescand</button>
        <button @click="markAsUnscanned" class="btn btn-secondary" v-if="toggleScanUrl && ticket && !ticket.scanned_at">Markeer als gescand</button>
      </div>
      <div v-if="pickDate">
          <DWTDateChanger 
            :cart-id="ticket.cart_id" 
            :cart="scanResult.cart"
            :cart-items="scanResult.cart_items"
            :capacity-planner-id="scanResult.capacity_planner_id"
            :merchant-id="scanResult.merchant_id"
            :form-id="scanResult.form_id"
            :hide-overview="true"
            @booked="changedDate"
            path="change_date"
          />

      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import DWTDateChanger from './DWTDateChanger.vue'
export default {
  name: 'ScanResult',
  components: {DWTDateChanger},
  props: {
    result: {
      type: String,
      default: ''
    },
    merchantKey: {
      type: String,
      default: ''
    },
  },
  data () {
    return {
      loading: true,
      toggleScanUrl: null,
      customer: null,
      ticket: null,
      ticket_kind: null,
      color: null,
      message: "",
      pickDate: false,
      newBookingResult: {},
      scanResult: {}
    }
  },
  methods: {
    changedDate(responseData) {
      // this.
      // console.log(response.data)
      this.newBookingResult = responseData
      this.pickDate = false
    },
    markAsUnscanned() {
      this.loading = true
      axios.get(this.toggleScanUrl).then((response) => {
        this.parseTicketResponse(response)
      }).catch((error) => {
        console.error(error);
      }).finally(() => {
        // TODO
      });
    },
    parseTicketResponse(response) {
      console.log(response.data)
      this.scanResult = response.data
      this.loading      = false
      this.ticket       = response.data.ticket
      this.color        = response.data.color
      this.toggleScanUrl  = response.data.toggle_scan_url
      this.ticket_kind  = response.data.ticket_kind
      this.customer     = response.data.customer
      this.message      = response.data.message
    }
  },
  created () {
      let code = ''
      code = this.result
      this.message = `Code '${code}' wordt gecontroleerd`
      const url = `scan.json?code=${code}&key=${this.merchantKey}`
      console.log(`sending GET request: ${url}`)
      axios.get(url)
        .then((response) => {
          this.parseTicketResponse(response)
        })
  }
};
</script>

<style scoped>
</style>

<!-- 173057 -->