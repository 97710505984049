<template>
  <div>
    <div class="row">
      <div class="col text-center">
        <h2>{{ problem.consumer.name }}</h2>
        <h5><small>{{ problem.notification }}</small></h5>
      </div>
    </div>
    
    <div v-if="problem.solved || problem.closed">
      <div class="row">
        <div class="col">
          <p class="lead bg-success text-center p-4">
            Het probleem met deze bestelling is inmiddels verholpen.
          </p>
        </div>
      </div>
    </div>

    <div>
      
      <div class="row" v-if="problem.transactions.length">
        <div class="col">
          <h4>Transacties</h4>
          <table class="table table-striped">
            <tr v-for="(trx, key) in problem.transactions" :key="key">
              <th>
                <span clas="text-success">{{ priceFormatter(trx.amount) }}</span>
              </th>
              <td>
                {{ trx.payment_method }}
              </td>
              <td>
                {{ trx.payment_key }}
              </td>
            </tr>
            <tr v-for="(refund, key) in problem.refunds" :key="key">
              <th>
                <span class="text-danger">- {{ refund.amount }}</span>
              </th>
              <td colspan="2">
                Terugbetaling
              </td>
            </tr>

          </table>
        </div>
      </div>
      
      <div class="row">
        <div class="col">
          <div class="list-group shadow-sm">
            <CartProblemAction @change="setProblem" :key='action.action' :problem="problem" :action="action" v-for="(action, key) in problem.available_actions" />
          </div>
        </div>
      </div>

      <div class="row mt-4" v-if="problem.transactions.length">
        <div class="col">
          <h4>Foutmelding</h4>
          <pre>{{ problem.error }}</pre>

        </div>
      </div>
    
    </div>

    
  </div>
</template>

<script>
import CartProblemAction from './CartProblemAction.vue'
export default {
  props: ['cartProblem'],
  components: {CartProblemAction},
  name: 'CartProblem',
  created() {
    this.problem = this.cartProblem
  },
  data () {
    return {
      problem: {}
    }
  },
  methods: {
    setProblem(data) {
      console.log(data)
      this.problem = data
    },
    priceFormatter(price) {
      return new Intl.NumberFormat('nl', {
        style: 'currency',
        currency: 'EUR'
      }).format(price/100.0)
    },

  }
}
</script>

<style lang="css" scoped>
</style>