<template>
  <div>
    <div class="row mb-4">
      <div class="col" v-if="theOrder.moneybird_invoice_id == null">
        <button :disabled="loading" @click="makeInvoice" class="btn btn-primary">Maak factuur in moneybird</button>
      </div>
      <div class="col" v-if="theOrder.moneybird_invoice_id">
        <div class="btn-group">
          <a :href="`https://moneybird.com/${moneybirdId}/sales_invoices/${theOrder.moneybird_invoice_id}`" target="_blank" class="btn btn-primary">Bekijk factuur in moneybird</a>
          <button :disabled="loading" @click="makeInvoice" class="btn btn-outline-primary">Maak factuur opnieuw</button>
        </div>
      </div>
    </div>
    <table class="table table-hover">
      <thead>
        <tr @click="focusFirstEmpty">
          <th></th>
          <th>Oorspr. Waarde</th>
          <th>Huidig saldo</th>
          <th>Besteldatum</th>
          <th>Kaartnummer</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <GiftcardItem @success="focusFirstEmpty" :ref="`giftcard_${giftcard.id}`" :giftcard="giftcard" :shop-id="shopId" v-for="(giftcard, key) in theGiftcards" :key="key" />
      </tbody>
    </table>
  </div>
</template>

<script>
import axios from 'axios'
import GiftcardItem from './GiftcardItem.vue'
export default {

  name: 'GiftcardList',
  props: ['giftcards', 'shopId', 'order', 'moneybirdId'],
  components: { GiftcardItem },
  data () {
    return {
      loading: false,
      theOrder: [],
      theGiftcards: []
    }
  },
  methods: {
    focusFirstEmpty() {
      // const refs = this.$refs
      for (var i = 0; i < this.theGiftcards.length; i++) {
        let giftcard = this.theGiftcards[i]
        console.log(`giftcard ${giftcard.id}`)
        let field = this.$refs[`giftcard_${giftcard.id}`][0].$refs.codefield        

        if (field && field.value.length == 0) {
          field.focus()
          return
        }
      }
    },
    makeInvoice() {
      this.loading = true
      axios.get(`/shops/${this.shopId}/giftcard_buyers/${this.order.giftcard_buyer_id}/giftcard_orders/${this.order.id}/make_invoice`)
      .then(response => {
        this.loading = false
        console.log('response: ')
        console.log(response.data)
        this.theOrder = response.data
      })
    }
  },
  created() {
    this.theOrder = this.order
    this.theGiftcards = this.giftcards
  },
  mounted() {
    this.focusFirstEmpty()
  }
}
</script>

<style lang="css" scoped>
</style>