<template>
  <div>
    <table class="table">
      <thead>
        <tr>
          <th>Aantal</th>
          <th>Bedrag</th>
          <th class="d-none d-sm-block">Verpakking</th>
          <th>Totaal</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(product, key) in products" :key="key">
          <td>
            {{ product.quantity }} &times;
          </td>
          <td>
            {{ formattedPrice(product.value) }}
          </td>
          <td class="d-none d-sm-block">
            {{ packaging[product.giftBox].name}}
            <span v-if="packaging[product.giftBox].price > 0">(+ {{ packaging[product.giftBox].formattedPrice }})</span>
          </td>
          <td>{{ formattedTotal(product) }}</td>
        </tr>
      </tbody>
      <tfoot>
        <tr>
          <th>{{ totalQuantity }} &times;</th>
          <th></th>
          <th class="d-none d-sm-block"></th>
          <th>{{ formattedGrandTotal }}</th>
        </tr>
      </tfoot>
    </table>    
  </div>
</template>

<script>
export default {

  name: 'GiftcardCart',
  props: ['products', 'packaging'],
  data () {
    return {

    }
  },
  computed: {
    totalQuantity() {
      let tot = 0
      for (var i = this.products.length - 1; i >= 0; i--) {
        tot += this.products[i].quantity
      }
      return tot
    },
    formattedGrandTotal() {
      let tot = 0
      for (var i = 0; i < this.products.length; i++) {
        tot += this.totalPrice(this.products[i])
      }
      return this.priceFormatter(tot)
    }
  },
  methods: {
    priceFormatter(price) {
      return new Intl.NumberFormat('nl', {
        style: 'currency',
        currency: 'EUR'
      }).format(price)
    },
    formattedPrice(number) {
      return this.priceFormatter(number)
    },
    formattedTotal(product) {
      return this.priceFormatter(this.totalPrice(product))
    },
    totalPrice(product){
      return product.quantity * (product.value + this.packaging[product.giftBox].price)
    }
  }
}
</script>

<style lang="css" scoped>
</style>