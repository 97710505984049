<template>
	<div>
		<div class="row">
			<div class="col-4 bg-light py-4 rounded">
			  <div class="form-group">
			    <label>Datum en tijdstip van wijziging</label>
			    <input v-model="dateTime" type="text" placeholder="25-12-2022 00:00" name="planned_mutation[activate_at]" class="form-control"/>
			    <small id="emailHelp" class="form-text text-muted">In formaat dd-mm-jjjj hh:mm</small>
			  </div>
			  <div class="form-group">
			  	<label>Waar gaat de wijziging over?</label>
				  <select v-model="selectedType" class="custom-select mb-3">
					  <option :value="null" selected>Maak een keuze</option>
					  <option :value="type" v-for="(type, key) in types" :key="key">{{ nameForMainType(type[0]) }}</option>
					</select>
				</div>
				
				<div class="form-group" v-if="selectedType && selectedType[0] == 'barcodes'">
			  	<label>Welke barcodesreeks wil je wijzigen?</label>
				  <select v-model="selectedBarcodeBatch" class="custom-select mb-3">
					  <option :value="null" selected>Maak een keuze</option>
					  <option :value="batch.id" v-for="(batch, key) in barcode_batches" :key="key">{{ batchLabel(batch) }}</option>
					</select>
				</div>

				<div class="form-group" v-if="selectedType && selectedType[0] == 'ticket_kind'">
			  	<label>Welk ticketsoort wil je wijzigen?</label>
				  <select v-model="selectedTicketKind" class="custom-select mb-3">
					  <option :value="null" selected>Maak een keuze</option>
					  <option :value="ticket_kind.id" v-for="(ticket_kind, key) in ticket_kinds" :key="key">{{ ticket_kind.name }}</option>
					</select>
				</div>


				<div class="form-group" v-if="selectedType != null">
			  	<label>Waar wil je precies doen?</label>
				  <select v-model="selectedAction" class="custom-select mb-3" name="planned_mutation[action]">
					  <option :value="null" selected>Maak een keuze</option>
					  <option :value="type" v-for="(type, key) in selectedType[1]" :key="key">{{ nameForMainAction(type) }}</option>
					</select>
				</div>

				<div class="form-group" v-if="selectedAction == 'add_ticket_kind_to_form'">
			  	<label>Op welk formulier wil je dit ticketsoort plaatsen?</label>
				  <select v-model="selectedForm" class="custom-select mb-3">
					  <option :value="null" selected>Maak een keuze</option>
					  <option :value="form.id" v-for="(form, key) in forms" :key="key">{{ form.name }}</option>
					</select>
				</div>

				<div class="form-group" v-if="selectedAction == 'add_ticket_kind_to_form'">
			  	<label>Op welk positie in het formulier moet deze ticketsoort komen?</label>
				  <select v-model="formPosition" class="custom-select mb-3">
					  <option value="top">Bovenaan</option>
					  <option value="bottom">Onderaan</option>
					</select>
				</div>

        <div class="form-group" v-if="selectedAction == 'replace_commission'">
          <label>Nieuwe commissieafspraak</label>
          <div class="row">
            <div class="col">
              <div class="input-group">
                <input class="form-control" v-model="newCommissionPercent">
                <div class="input-group-append">
                  <span class="input-group-text">%</span>
                </div>
              </div>
            </div>
            <div class="col">
              <div class="input-group">
                <input class="form-control" v-model="newCommissionCent">
                <div class="input-group-append">
                  <span class="input-group-text">cent</span>
                </div>
              </div>
            </div>
          </div>
        </div>


        <div class="form-group">
          <label>Opmerking</label>
          <textarea name="planned_mutation[comment]" class="form-control" v-model="comment" placeholder="Schrijf op waarom je deze wijziging inplant."></textarea>
        </div>

				<input type="hidden" name="planned_mutation[mutatable_type]" v-bind:value="mutatableType">
				<input type="hidden" name="planned_mutation[mutatable_id]" v-bind:value="mutatableId">
				<input type="hidden" name="planned_mutation[options]" v-bind:value="JSON.stringify(options)">
				<input v-bind:disabled="isDisabled" type="submit" name="commit" value="Toevoegen" data-disable-with="Toevoegen" class="btn btn-success btn-block">

			</div>
			
			
			
		</div>
	</div>
</template>

<script>

export default {

  name: 'PlannedMutations',
  components: {},
  props: ['forms', 'types', 'type_labels', 'main_type_labels', 'barcode_batches', 'merchant_id', 'ticket_kinds'],
  data () {
    return {
      comment: '',
      newCommissionPercent: '',
      newCommissionCent: '',
    	dateTime: null,
			selectedType: null,
			selectedAction: null,
			selectedForm: null,
			formPosition: "top",
			selectedBarcodeBatch: null,
			selectedTicketKind: null
    }
  },
  computed: {
  	mutatableType(){
  		if (this.selectedType && this.selectedType[0] == 'merchant'){
  			return 'Merchant'
  		} else if (this.selectedType && this.selectedType[0] == 'barcodes') {
  			return 'BarcodeBatch'
			} else if (this.selectedType && this.selectedType[0] == 'ticket_kind') {
  			return 'TicketKind'
  		} else {
  			return this.selectedType
  		}
  	},
  	mutatableId(){
  		if (this.selectedType && this.selectedType[0] == 'merchant'){
  			return this.merchant_id
  		} else if (this.selectedType && this.selectedType[0] == 'barcodes') {
  			return this.selectedBarcodeBatch
			} else if (this.selectedType && this.selectedType[0] == 'ticket_kind') {
				return this.selectedTicketKind
  		}
  	},
  	options(){
  		if (this.selectedAction == 'add_ticket_kind_to_form'){
  			return {form_id: this.selectedForm, position: this.formPosition}
      } else if (this.selectedAction == 'replace_commission'){
        return {percent: this.newCommissionPercent, cent: this.newCommissionCent}
  		} else {
  			return null
  		}
  	},
  	isDisabled(){
      if ( !this.comment.length ){
        return true
  		} else if (this.selectedAction == null) {
  			return true
  		} else if (this.dateTime == null){
  			return true
  		} else if (this.selectedAction == 'add_ticket_kind_to_form' && this.selectedForm == null) {
  			return true
  		} else {
  			return false
  		}
  	}
  },
  methods: {
  	batchLabel(batch){
			if (batch.active){
				return `#${batch.id} ${batch.name} - ${batch.source_name} - ACTIEF`
			} else {
				return `#${batch.id} ${batch.name} - ${batch.source_name}`
			}
  	},
  	nameForMainType(type){
  		return this.main_type_labels[type]
  	},
  	nameForMainAction(type){
  		return this.type_labels[type]
  	}
  }
}
</script>

<style lang="css" scoped>
</style>