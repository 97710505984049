<template>
  <div>
    <h2>Toegevoegde aparaten</h2>
    <div v-if="devices.length">
      <table class="table">
        <thead>
          <tr>
            <th>Naam</th>
            <th>Datum</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <RegisteredDevice :device="device" :merchant-id="merchantId" v-for="(device, key) in devices" :key="key" />
        </tbody>
      </table>
    </div>
    <div v-if="!devices.length" class="text-muted">
      <em>Nog geen toestellen gekoppeld. Volg de instructies hiernaast om een toestel toe te voegen.</em>
    </div>
  </div>
</template>

<script>
import RegisteredDevice from './RegisteredDevice.vue'
import axios from 'axios'
export default {

  name: 'RegisteredDevices',
  props: ['merchantId'],
  components: { RegisteredDevice },
  data () {
    return {
      devices: []
    }
  },
  mounted() {
    this.fetchDevices()
    setInterval(function(){ this.fetchDevices() }.bind(this), 5000)
  },
  methods: {
    fetchDevices() {
      // console.log('fetching list')
      axios.get(`/merchants/${this.merchantId}/registered_devices.json`)
      .then((response) => {
        this.devices = response.data.devices
        // TODO
        // console.log(response.data)  
      }).catch((error) => {
        console.error(error);
      }).finally(() => {
        // TODO
      });
    }
  }
}
</script>

<style lang="css" scoped>
</style>