<template>
  <div>
    <div>
      <div class="sticky-top bg-white pt-3" style="top: 50px; border-bottom: 1px solid #dedede;">
        <div class="row">
          <div class="col">
            <div class="form-group">
              <input type="text" v-model="query" class="form-control" placeholder="Zoek op naam, e-mail, betaalkenmerk, barcode" autofocus>
            </div>
          </div>
          <div class="col">
            <div class="form-group">
              <div class="input-group">
                <div class="input-group-prepend" v-if="!hasVisitDate">
                  <span class="input-group-text">Verkoopdatum</span>
                </div>
                <select v-model="searchByVisitDate" v-if="hasVisitDate" class="custom-select" id="dateSelectInput">
                  <option value="false">Verkoopdatum</option>
                  <option value="true">Bezoekdatum</option>
                </select>
                <input type="date" v-model="fromDate" class="form-control" :class="{'is-invalid': !fromDate.length}" style="max-width: 180px;">
                <div class="input-group-append">
                  <span class="input-group-text">tot</span>
                </div>
                <input type="date" v-model="toDate" class="form-control" :class="{'is-invalid': !toDate.length}" style="max-width: 180px;">
                <div class="input-group-append">
                  <button @click="filterDate" class="btn btn-secondary" type="button" :disabled="invalidDate">Filter</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <table class="table table-sm" id="carts">
            <thead>
              <tr v-if="isRetail">
                <th v-if="abilities.includes('view_consumer_details')">Naam</th>
                <th>Reserveringsdatum</th>
                <th>Verkoopdatum <i class='fa fa-arrow-down'></i></th>
                <th>Bestelnummer</th>
              </tr>
              <tr v-else>
                <th v-if="abilities.includes('view_consumer_details')">Naam</th>
                <th>Ticketsoort</th>
                <th>Bezoekdatum</th>
                <th>Verkoopdatum <i class='fa fa-arrow-down'></i></th>
                <th>Verkoopkanaal</th>
                <th>Prijs</th>
              </tr>
            </thead>
            <tbody>
              <cartRow 
                @click="showCart(cart)"
                :key="`cart-${cart.id}-${cart.updated_at_number}`" 
                :cart="cart"
                :merchant-id="merchantId"
                :abilities="abilities"
                :is-retail="isRetail"
                v-for="cart in filteredCarts" 
              />
            </tbody>
          </table>

        </div>
      </div>
      <div class="row" v-if="!query == ''">
        <div class="col text-center text-muted">
          <div class="w-100">{{ dateString }}</div>

          <button v-if="!searchingAll && pagination.last_page" @click="searchAll" class="btn btn-secondary btn-sm" type="button">Doorzoek alle bestellingen</button>
          <button v-if="searchingAll && loading" @click="stopSearchAll" class="btn btn-secondary btn-sm" type="button">Stop met zoeken</button>

          <div v-if="searchingAll && loading" class="w-100 p-2">
            <em>Het doorzoeken van alle bestellingen kan even duren...</em>
          </div>
          
        </div>

      </div>
      <div class="row" v-if="!pagination.last_page">
        <div class="col text-center">
          <button @click="fetchCarts" :disabled="loading" class="btn btn-outline-primary">Meer resultaten laden</button>
        </div>
      </div>
    </div>

    <div class="row" v-if="loading">
      <div class="col text-center">
        <i class='fa fa-spinner fa-spin'></i>
      </div>
    </div>

    <div ref="details" class="modal fade" id="detailsModal" tabindex="-1" role="dialog" aria-hidden="true">
      <div class="modal-dialog" role="document">
        <cartDetails :is-retail="isRetail" @update="updateCart" :abilities="abilities" :key="`details-${selectedCart.id}`" :cart="selectedCart" :merchant-id="merchantId" />
      </div>
    </div>
    

  </div>
</template>

<script>
import axios from 'axios'
import CartRow from './CartRow.vue'
import CartDetails from './CartDetails.vue'
export default {

  name: 'Carts',
  components: {CartRow, CartDetails},
  props: ['cart', 'hasVisitDate', 'merchantId', 'startDate', 'endDate', 'abilities', 'isRetail'],
  data () {
    return {
      carts: [],
      page: 0,
      query: '',
      searchByVisitDate: false,
      fromDate: null,
      toDate: null,
      searchingAll: false,
      selectedCart: {},
      loading: true,
      pagination: {total_pages: 0, total: 0, last_page: true, next_page: 1},
      dateOptions: {
        weekday: 'long',
        day: 'numeric',
        month: 'long',
        year: 'numeric'
      }
    }
  },
  computed: {
    showFromDate() {
      return new Intl.DateTimeFormat('nl-NL', this.dateOptions).format(Date.parse(this.fromDate))
    },
    showToDate() {
      return new Intl.DateTimeFormat('nl-NL', this.dateOptions).format(Date.parse(this.toDate))
    },
    invalidDate() {
      return !(this.fromDate && this.fromDate.length && this.toDate && this.toDate.length) 
    },
    dateString() {
      if (this.carts.length) {
        if(this.cart) {
          return `Alle resultaten al ingeladen`
        } else if (this.pagination.last_page) {
          return `Kan niet verder terugzoeken, alle resultaten tussen ${this.showFromDate} en ${this.showToDate} zijn al ingeladen`
        } else {
          return `Resultaten tot ${this.carts[this.carts.length - 1].paid_at}`
        }
      } else {
        return ''
      }
    },
    filteredCarts() {
      // if(this.hasVisitDate) {
      //   return this.reverseArr(this.carts.filter((cart) => {
      //     return cart.search_string.toLowerCase().includes(this.query.toLowerCase())
      //   }).sort((a, b) => new Date(a.chosen_dates[0].date) - new Date(b.chosen_dates[0].date)));
      // } else {
      return this.carts.filter((cart) => {
        return cart.search_string.toLowerCase().includes(this.query.toLowerCase())
      });
      // }
    },
    cartIds() {
      return this.carts.map((cart) => {
        return cart.id
      })
    }
  },
  methods: {
    reverseArr(input) {
      var ret = new Array;
      for(var i = input.length-1; i >= 0; i--) {
        ret.push(input[i]);
      }
      return ret;
    },
    stopSearchAll() {
      this.searchingAll = false
    },
    searchAll() {
      this.searchingAll = true
      this.resetFetch()
    },
    filterDate() {
      this.searchingAll = false
      this.resetFetch()
    },
    updateCart(cart){
      this.selectedCart = cart
      const oldQ = this.query
      for (var i = 0; i < this.carts.length; i++) {
        if (this.carts[i].id === cart.id) {
          this.carts[i] = cart
          console.log(this.carts[i])
          this.query = '.'
          this.query = oldQ
        }
      }
    },
    showCart(cart){
      this.selectedCart = cart
      console.log(cart)
      console.log(this.$refs.details)
      // this.$refs.details.modal('show')
      $("#detailsModal").modal('show')
    },
    resetFetch() {
      this.carts = []
      this.pagination.next_page = 1
      this.fetchCarts()
    },
    fetchCarts() {
      this.loading = true
      let url = ''
      if (this.cart && this.merchantId) {
        url = `/merchants/${this.merchantId}/carts.json?search_based_on_visit_date=${this.searchByVisitDate}&cart_id=${this.cart}&start_date=${this.fromDate}&end_date=${this.toDate}&page=${this.pagination.next_page}`
        this.query = `${this.cart}`
      } else if(this.cart) {
        url = `/carts.json?search_based_on_visit_date=${this.searchByVisitDate}&start_date=${this.fromDate}&cart_id=${this.cart}&end_date=${this.toDate}&page=${this.pagination.next_page}`
        this.query = `${this.cart}`
      } else if (this.merchantId && this.searchingAll) {
        url = `/merchants/${this.merchantId}/carts.json?search_based_on_visit_date=${this.searchByVisitDate}&page=${this.pagination.next_page}`
      } else if (this.merchantId) {
        url = `/merchants/${this.merchantId}/carts.json?search_based_on_visit_date=${this.searchByVisitDate}&start_date=${this.fromDate}&end_date=${this.toDate}&page=${this.pagination.next_page}` 
      } else {
        url = `/carts.json?search_based_on_visit_date=${this.searchByVisitDate}&start_date=${this.fromDate}&end_date=${this.toDate}&page=${this.pagination.next_page}` 
      }
      axios.get(url).then((response) => {
        // this.page = pageNum
        this.pagination = JSON.parse(response.headers['x-pagination'])
        // console.log(response)
        for (var i = 0; i < response.data.length; i++) {
          if (!this.cartIds.includes(response.data[i].id))
          this.carts.push(response.data[i])
        }
        this.loading = false
        if (!this.pagination.last_page && this.pagination.next_page < 4) {
          this.fetchCarts()
        } else if (!this.pagination.last_page && this.searchingAll) {
          this.fetchCarts()
        }
      }).catch((error) => {
        console.error(error);
      }).finally(() => {
        // TODO
      });
    }
  },
  created() {
    this.fromDate = this.startDate
    this.toDate = this.endDate
    this.fetchCarts()
  }
};
</script>

<style>
table { border-collapse: collapse; empty-cells: show; }

td { position: relative; }

#carts thead th { width: 100px; }

tr.strikeout td:before {
  content: " ";
  position: absolute;
  top: 50%;
  left: 0;
  border-bottom: 1px solid #111;
  width: 100%;
}

tr.strikeout td:after {
  content: "\00B7";
  font-size: 1px;
}
</style>