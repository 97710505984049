<template>
  <div class="bg-light rounded p-3 mb-3">
    <div class="alert alert-light border mb-4">
      Door de instellingen op deze pagina, kun je zorgen dat je e-mails kunt versturen via {{ address }}, in plaats van via service@dagjewegtickets.nl. DagjeWeg.Tickets heeft geen toegang tot je mailbox, maar door deze instellingen, kunnen we e-mails toch versturen met {{ domain }} als afzender.
    </div>
    <h4><i class="fa fa-times text-danger" v-if="!emailAccount.verified"></i><i class="fa fa-check text-success" v-if="emailAccount.verified"></i> E-mailadres valideren</h4>
    <div v-if="!emailAccount.verified && emailAccount.sent_mandrill_verification_to">
      <p class="mb-1">Er is een e-mail gestuurd naar <b>{{emailAccount.sent_mandrill_verification_to}}</b>. Stuur deze e-mail door naar <b>service@dagjewegtickets.nl</b>.</p>
      <p>Geen e-mail ontvangen? <button class="btn btn-secondary btn-sm">stuur validatie e-mail nogmaals</button></p>
    </div>
    <p v-if="emailAccount.verified && emailAccount.sent_mandrill_verification_to">
      Het e-mailadres <b>{{emailAccount.sent_mandrill_verification_to}}</b> is gevalideerd!
    </p>
    <div v-if="!emailAccount.verified && !emailAccount.sent_mandrill_verification_to">
      <p>Om e-mails te versturen vanaf {{address}}, moet dit e-mailadres eerst gevalideerd worden. Druk op de knop hieronder om het adres te valideren.</p>
      <button @click="validateEmail" :disabled="validationMailSending" class="btn btn-primary">Stuur validatie e-mail naar {{address}}</button>
    </div>

    <hr class="my-3">
    <div v-if="!emailAccount.has_valid_dkim">
      <h4><i class="fa fa-times text-danger"></i> DKIM</h4>
      <p>DKIM is een op DNS gebaseerd e-mail authenticatie systeem dat ons helpt om mails namens {{ domain }} te versturen, zonder dat we toegang hebben tot jullie mailbox. Om DKIM toe te voegen, moet er iets aangepast worden aan de DNS gegevens van het domein {{ domain }}. Weet je niet hoe je de DNS gegevens moet aanpassen? Stuur de onderstaande instructies dan door naar de IT beheerder van jullie organisatie.</p>
      <p><b>Instructie:</b> maak een DNS record aan met deze gegevens:</p>
      <table class="table">
        <thead>
          <tr>
            <th>Type</th>
            <th>Naam</th>
            <th>Waarde</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td><code>TXT</code></td>
            <td><code>{{ dkimName }}</code></td>
            <td><code>{{ dkimValue }}</code></td>
          </tr>
        </tbody>
      </table>
    </div>
    <div v-if="emailAccount.has_valid_dkim">
      <h4><i class="fa fa-check text-success"></i> DKIM</h4>
      <p>Het DNS record voor DKIM validatie is succesvol gevalideerd.</p>
    </div>
    
    <hr class="my-3">
    <div v-if="!emailAccount.has_valid_spf">
      <h4><i class="fa fa-times text-danger"></i> SPF</h4>
      <p>SPF is een ander op DNS gebaseerd email authenticatie systeem. Als je nog geen SPF record hebt, voeg er dan één toe met onderstaande gegevens.</p>
      <table class="table">
        <thead>
          <tr>
            <th>Type</th>
            <th>Naam</th>
            <th>Waarde</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td><code>TXT</code></td>
            <td><code>{{ spfName }}</code></td>
            <td><code>{{ spfValue }}</code></td>
          </tr>
        </tbody>
      </table>
      <p>Bestaat er al een SPF record? Voeg dan <code>include:spf.mandrillapp.com</code> toe aan de waarde van het reeds bestaande record, vlak vóór de afsluitende <code>?all</code>.</p>
    </div>
    <div v-if="emailAccount.has_valid_spf">
      <h4><i class="fa fa-check text-success"></i> SPF</h4>
      <p>Het DNS record voor SPF validatie is succesvol gevalideerd.</p>
    </div>

    <hr class="my-3">

    <div class="alert alert-success" v-if="emailAccount.has_valid_spf && emailAccount.has_valid_dkim && emailAccount.verified">
      <p class="my-0 p-0">🎉 Je hoeft niets meer te doen! We kunnen nu mails naar jouw klanten versturen vanaf {{address}}.</p>
    </div>

    <button @click="pollForChanges" :disabled="polling" class="btn btn-primary">
      Controleer instellingen
    </button>
  </div>
</template>

<script>
import axios from 'axios'
export default {

  name: 'MandrillSettings',
  props: ['address', 'accountId', 'domain', 'account'],
  data () {
    return {
      validatedEmail: false,
      validationMailSending: false,
      emailAccount: {},
      polling: true
    }
  },
  computed: {
    spfName() {
      return `${this.domain}`
    },
    spfValue() {
      return `v=spf1 include:spf.mandrillapp.com ?all`
    },
    dkimName() {
      return `mandrill._domainkey.${this.domain}`
    },
    dkimValue() {
      return `v=DKIM1; k=rsa; p=MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCrLHiExVd55zd/IQ/J/mRwSRMAocV/hMB3jXwaHH36d9NaVynQFYV8NaWi69c1veUtRzGt7yAioXqLj7Z4TeEUoOLgrKsn8YnckGs9i3B3tVFB+Ch/4mPhXWiNfNdynHWBcPcbJ8kjEQ2U8y78dHZj1YeRXXVvWob2OaKynO8/lQIDAQAB;`
    }
  },
  created() {
    this.emailAccount = this.account
    this.pollForChanges()
  },
  methods: {
    pollForChanges() {
      this.polling = true
      axios.get(`/email_accounts/${this.accountId}/poll.json`)
      .then((response) => {
        console.log(response.data.updated_at)
        this.emailAccount = response.data
        this.polling = false
      }).catch((error) => {
        console.error(error);
      }).finally(() => {
        // // TODO
        // setTimeout(function() {
        //   if (this.emailAccount.has_valid_dkim == false && this.emailAccount.has_valid_spf == false && this.emailAccount.verified == false) {
        //     // this.pollForChanges()

        //   }
        // }.bind(this), 5000)
      });
    },
    validateEmail() {
      this.validationMailSending = true
      axios.get(`/email_accounts/${this.accountId}/send_mandrill_validation.json`)
      .then((response) => {
        // console.log(response.data)
        this.validationMailSending = false
        this.emailAccount = response.data
      }).catch((error) => {
        console.error(error);
      }).finally(() => {
        // TODO
      });
    },
    checkSettings() {
      axios.get(`/email_accounts/${this.accountId}/check_mandrill.json`)
      .then((response) => {
        console.log(response.data)
      }).catch((error) => {
        console.error(error);
      }).finally(() => {
        // TODO
      });
    } 
  }
}
</script>

<style lang="css" scoped>
</style>