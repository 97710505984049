<template>
  <div class="list-group-item py-2">
    <div class="row" v-if="theTemplateBlockTicketKind">
      <div class="col-5 d-flex">
        <span class="align-self-center">
          {{ theTemplateBlockTicketKind.ticket_kind.name }}
          <small class="form-text text-muted" v-if="(theTemplateBlockTicketKind.ticket_kind.number_of_people > 1)">
          <b>Let op:</b> dit ticketsoort telt elk verkocht ticket als {{ theTemplateBlockTicketKind.ticket_kind.number_of_people }} personen
          </small>
        </span>
        
      </div>
      <div class="col-2">
        <div class="input-group input-group-sm">
          <input v-on:keydown.enter="save" v-model="theTemplateBlockTicketKind.capacity" type="number" class="form-control" placeholder="100" style="padding-right: 5px">
        </div>
      </div>
      <div class="col-5 d-flex justify-content-between align-items-center">
        <small v-if="theTemplateBlockTicketKind.capacity == 0" class="form-text text-muted">Bij 0 wordt de bovengrens van het blok gebruikt: {{ blockCapacity }}</small>
        <div>
          <button @click="cancel" class="btn btn-secondary btn-sm" v-if="isChanged">
            annuleer
          </button>
          <button @click="save" class="btn btn-success btn-sm" v-if="isChanged">
            opslaan
          </button>
        </div>
        <button @click="remove" class="btn btn-link text-danger btn-sm">
          verwijderen
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
export default {

  name: 'CapacityBlockTicketKind',
  props: ['blockId', 'templateBlockTicketKind', 'merchantId', 'capacityPlanner', 'capacityTemplateId', 'blockCapacity'],
  data () {
    return {
      theTemplateBlockTicketKind: null,
      originalTemplateBlockTicketKind: null,
    }
  },
  methods: {
    remove() {
      axios.delete(`/merchants/${this.merchantId}/merchant_capacity_planners/${this.capacityPlanner.id}/capacity_templates/${this.capacityTemplateId}/capacity_template_blocks/${this.blockId}/capacity_template_block_ticket_kinds/${this.theTemplateBlockTicketKind.id}.json`)
        .then(response => {
          console.log(response)
          this.$emit('update', response.data)
        })
        .catch(error => {
          console.log(error.response)
        })
    },
    cancel() {
      this.theTemplateBlockTicketKind = {...this.templateBlockTicketKind}
    },
    save() {
      axios.put(`/merchants/${this.merchantId}/merchant_capacity_planners/${this.capacityPlanner.id}/capacity_templates/${this.capacityTemplateId}/capacity_template_blocks/${this.blockId}/capacity_template_block_ticket_kinds/${this.theTemplateBlockTicketKind.id}.json`, { capacity_template_block_ticket_kind: this.theTemplateBlockTicketKind })
        .then(response => {
          // console.log(response)
          // this.$emit('update', response.data)
          this.theTemplateBlockTicketKind = {...response.data}
          this.originalTemplateBlockTicketKind = {...response.data}
          console.log(this.originalTemplateBlockTicketKind)
          console.log(response.data)
        })
        .catch(error => {
          console.log(error.response)
        })
    }
  },
  created() {
    this.theTemplateBlockTicketKind = {...this.templateBlockTicketKind}
    this.originalTemplateBlockTicketKind = {...this.templateBlockTicketKind}
  },
  computed: {
    isChanged() {
      return  JSON.stringify(this.originalTemplateBlockTicketKind) !==  JSON.stringify(this.theTemplateBlockTicketKind)
    }
  }
}
</script>

<style lang="css" scoped>
</style>