<template>
  <div class="input-group" v-if="valueTranslations">
    <input v-on:keydown.enter="emitEnter" v-if="type == 'input'" type="text" class="form-control" :placeholder="placeholder" v-model="valueTranslations[selectedLocale]">
    
    <textarea rows="2" v-if="type == 'textarea'" type="text" class="form-control" :placeholder="placeholder" v-model="valueTranslations[selectedLocale]"></textarea>
    <div class="input-group-append">
      <button class="btn btn-outline-secondary dropdown-toggle text-uppercase" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{{ selectedLocale }}</button>
      <div class="dropdown-menu">
        <button @click="selectedLocale = locale" class="dropdown-item text-uppercase" v-for="(locale, key) in locales" :key="key">
          {{ locale }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {

  name: 'I18nInput',
  props: ['valueTranslations', 'locale', 'type', 'placeholder', 'hint'],
  data () {
    return {
      selectedLocale: 'nl'
    }
  },
  computed: {
    locales() {
      return ['nl', 'en', 'de']
    }
  },
  methods: {
    emitEnter() {
      this.$emit('enter')
    }
  },
  created() {
    console.log(this.valuetranslations)
    this.selectedLocale = this.locale
  }
}
</script>

<style lang="css" scoped>
</style>