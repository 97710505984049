<template>
  <div class="border rounded p-3">
    <div class='row'>
      <div class="col">
        <h4>{{translations.title }}</h4>
        <p>{{ translations.body }}</p>
          <div class="form-check">
            <input v-model="showPriceList" class="form-check-input" type="radio" name="showPriceList" id="showPriceList1" :value="true">
            <label class="form-check-label" for="showPriceList1">
              Toon de prijslijst als eerste stap
            </label>
          </div>
          <div class="form-check">
            <input v-model="showPriceList" class="form-check-input" type="radio" name="showPriceList" id="showPriceList2" :value="false">
            <label class="form-check-label" for="showPriceList2">
              Sla de prijslijst over en start direct met datumkeuze
            </label>
          </div>
          <hr>
          <div class="form-check">
            <input v-model="locale" class="form-check-input" type="radio" name="localeList" id="localeNL" value="nl">
            <label class="form-check-label" for="localeNL">
              Nederlands
            </label>
          </div>
          <div class="form-check">
            <input v-model="locale" class="form-check-input" type="radio" name="localeList" id="localeEN" value="en">
            <label class="form-check-label" for="localeEN">
              Engels
            </label>
          </div>
          <div class="form-check">
            <input v-model="locale" class="form-check-input" type="radio" name="localeList" id="localeDE" value="de">
            <label class="form-check-label" for="localeDE">
              Duits
            </label>
          </div>
          <hr>
          <div class="form-check">
            <input v-model="showCapacity" class="form-check-input" type="radio" name="showCapacity" id="capYes" :value="true">
            <label class="form-check-label" for="capYes">
              Toon de beschikbare capaciteit bij tijdsloten
            </label>
          </div>
          <div class="form-check">
            <input v-model="showCapacity" class="form-check-input" type="radio" name="showCapacity" id="capNo" :value="false">
            <label class="form-check-label" for="capNo">
              Verberg de beschikbare capaciteit bij tijdsloten
            </label>
          </div>

        <textarea class="mt-3" style="width: 100%; max-width: 100%;" rows="8" name="">{{ iframeCode }}</textarea>
      </div>
      <div class="col">
        <iframe :id="`ticket-iframe-${form.id}`" frameborder='0' width='100%' height='1000px' :src="this.url"></iframe>
      </div>
    </div>
  </div>
</template>

<script>
export default {

  name: 'EmbedInstructions',
  props: ['translations', 'form', 'isLocal', 'isRetail'],
  data () {
    return {
      locale: 'nl',
      showPriceList: true,
      showCapacity: true
    }
  },
  computed: {
    iframeCode() {
      return `<iframe id='ticket-iframe-${this.form.id}' frameborder='0' width='100%' height='1000px' src='${this.url}'></iframe>${this.jsResizeCode}`
    },
    baseUrl() {
      if (this.isLocal) {
        return 'http://shop.lvh.me:8080/'
      } else {
        return 'https://www.dagjewegtickets.nl/'
      }
    },
    url() {
      return `${this.baseUrl}${this.locale}/embed/${this.form.id}/${this.form.key}?skip_prices=${!this.showPriceList}&show_capacity=${this.showCapacity}`
    },
    jsResizeCode() {
      return `<script>window.addEventListener('message', function(e) { document.getElementById('ticket-iframe-${this.form.id}').style.height = e.data.height + 'px'; });<\/script>`
    }
  },
  mounted() {
    if (this.isRetail) {
      this.showPriceList = false
    }
  }
}
</script>

<style lang="css" scoped>
</style>