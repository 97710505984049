<template>
  <div>
    <div class="row" style="padding-bottom: 70px;">
      <div class="col-3">
        <div class="list-group">
          <div @click="selectTemplate(template.id)" :class="`list-group-item list-group-item-action template_border_left_${template.id}`" v-for="template in templates" :key="template.id">
            <div class="d-flex justify-content-between">
              <span>{{ template.name }}</span>
              <i class="fa fa-check align-self-center text-success" v-if="currentTemplate == template.id"></i>
            </div>
            <div v-if="currentTemplate == template.id">
              <ul>
                <li v-for="(block, key) in template.blocks" :key="key">
                  {{ block.show_hours }}
                </li>
              </ul>
            </div>
          </div>
        </div>      
        <div class="btn-group mt-5 w-100">
          <button @click="resetCache" class="btn btn-outline-primary" :disabled="resettingCache || saving">
            <i class='fa fa-sync' :class="{'fa-spin': resettingCache }"></i> Cache resetten
          </button>
          <button @click="showHelp = !showHelp" class="btn btn-outline-primary" :disabled="resettingCache || saving">
            <i class='fa fa-question'></i>
          </button>
        </div>
        <small class="form-text text-muted" v-if="showHelp">De kalender die consumenten te zien krijgen, wordt automatisch doorlopend bijgewerkt en opgeslagen in een 'cache'. Door de cache te resetten, wordt de kalender voor consumenten direct voor iedere dag in de toekomst herberekend. Dit heeft geen consquenties voor reeds gemaakte reserveringen.</small>
      </div>
      <div class="col">
        <div class="text-center" v-if="loading">
          <i class='fa fa-spinner fa-spin'></i> Kalender wordt geladen...
        </div>
        <YearCalendar
          v-if="!loading"
          v-model="year"
          :activeDates.sync="activeDates"
          :activeClass="activeClass"
          @toggleDate="toggleDate"
          lang="en"
          :showYearSelector="true"
        ></YearCalendar>      
      </div>
    </div>
    <div v-if="mutations && mutations.length" class="row bg-light shadow-sm" style="position: fixed; bottom: 0px; width: 100%;">
      <div class="col text-center">
        <button @click="save" class="btn btn-success my-3 mx-auto" :disabled="saving">Wijzigingen opslaan</button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import YearCalendar from 'vue-material-year-calendar'
export default {

  name: 'CapacityCalendar',
  props: ['templates', 'merchantId', 'capacityPlanner'],
  components: { YearCalendar },
  data () {
    return {
      year: new Date().getFullYear(),
      showHelp: false,
      currentTemplate: null,
      activeDates: [],
      selectedDate: null,
      activeClass: '',
      mutations: [],
      saving: false,
      resettingCache: false,
      loading: true
    }
  },
  created() {
    this.selectTemplate(this.templates[0].id)
    this.getDates()
  },
  computed: {
    // activeClass() {
    //   if (this.currentTemplate === null) {
    //     return ''
    //   } else {
    //     return `template_${this.currentTemplate}`
    //   }
      
    // }
  },
  methods: {
    resetCache() {
      this.resettingCache = true
      axios.get(`/merchants/${this.merchantId}/merchant_capacity_planners/${this.capacityPlanner.id}/reset_cache.json`)
      .then(response => {
        this.resettingCache = false
      })
    },  
    getDates() {
      axios.get(`/merchants/${this.merchantId}/merchant_capacity_planners/${this.capacityPlanner.id}/calendar.json`)
      .then(response => {
        this.activeDates = response.data
        this.loading = false
      })
    },
    selectTemplate(templateId) {
      this.currentTemplate = templateId
      this.activeClass = `template_${this.currentTemplate}`
    },
    save() {
      this.saving = true
      axios.post(`/merchants/${this.merchantId}/merchant_capacity_planners/${this.capacityPlanner.id}/dates.json`, { mutations: this.mutations })
      .then(response => {
        console.log(response.data)
        this.saving = false
        this.mutations = []
        this.activeDates = response.data
      })
      .catch(error => {
        this.saving = false
        console.log(error)
        alert('Er ging iets mis. De wijzigingen konden niet opgeslagen worden. Probeer het over enkele minuten nogmaals.')
      })
    },
    toggleDate (dateInfo) {
      // console.log(dateInfo)
      this.mutations.push(dateInfo)
      // axios.post("/account-manager-stats.json", {stat: this.chosenStat, user_id: this.selectedUser.id, from: this.selectedFromPeriod, to: this.selectedToPeriod, role: this.selectedRole})
      // .then(response => {
      //   this.loading = false
      //   this.grandTotal = response.data.total
      //   this.stats = response.data.stats
      // })
      // this.selectedDate = dateInfo

    }

  }
}
</script>

<style lang="css">

</style>