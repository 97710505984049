<template>
  <div>
    <fieldset class="scheduler-border">
      <legend class="scheduler-border">Koppeling met LeisureKing</legend> 
      <div class="center">
        <img height="80" :src="logo">
      </div> 
      <div class="form-inputs">       
        <div class="form-group select optional barcode_batch_api_options" v-if="products
        .length">
          <input type="hidden" name="barcode_batch[api_options][leisure_king_assortiment_id]" v-model="selectedAssortimentId"></input>
          <label for="leisure_king_assortiment" class="control-label select optional">Product</label>
          <select v-model="selectedAssortiment" @change="assortimentSelected" id="leisure_king_assortiment" class="form-control select optional">
            <option value="">Selecteer een arrangement...</option>
            <option v-if="!product.affiliate_provider" :value="product" v-for="(product, key) in products" :key="key">{{ product.weergavenaam }}</option>
            <option v-if="product.affiliate_provider" :value="product" v-for="(product, key) in products" :key="key">{{product.affiliate_provider.shopname}} - {{product.affiliate_provider.city}} - {{ product.weergavenaam }}</option>
          </select>
        </div>

        <table class="table small" v-if="selectedAssortiment">
          <tr>
            <th class="p-1">Naam</th>
            <td class="p-1">{{selectedAssortiment.weergavenaam}}</td>
          </tr>
          <tr>
            <th class="p-1">ID</th>
            <td class="p-1">{{selectedAssortiment.id_assortiment}}</td>
          </tr>
          <tr>
            <th class="p-1">Aantal personen</th>
            <td class="p-1">Min: {{selectedAssortiment.minpax}}, max: {{selectedAssortiment.maxpax}}</td>
          </tr>
          <tr>
            <th class="p-1">Soort reservering</th>
            <td class="p-1">{{ reservationType }}</td>
          </tr>
          <tr v-if="selectedAssortimentDetails">
            <th class="p-1">Laagste prijs</th>
            <td class="p-1">€ {{selectedAssortimentDetails.laagste_prijs}}</td>
          </tr>
        </table>

        <pre v-if="false">{{selectedAssortimentDetails}}</pre>
        <pre v-if="false">{{prices}}</pre>
        <div v-if="loadingPrices">
          <i class="fa fa-spinner fa-spin"></i> prijzen worden geladen...
        </div>
        <div v-if="prices.length">
          <div class="bg-light p-3">
            <strong>LET OP: </strong> het aanpassen van prijzen is een omweg om tickets met korting aan te bieden, zoals afgesproken met een klant. De ingestelde prijs blijft bewaard aan de unieke sleutel die bij deze prijzen horen. Voor iedere prijs is dat een combinatie van [productnaam_zonder_vreemde_tekens]_[prijs_in_centen]. Als de productnaam of de prijs wijzigt bij LeisureKing, vervalt de ingestelde prijs zonder waarschuwing.<br><br>
            LeisureKing staat helemaal buiten deze ingestelde prijs en verwacht aan hun kant voor iedere bestelling het volledige bedrag te ontvangen. De administratieve moeilijkheden die dit kan geven is dus een zaak tussen LeisureKing en de aanbieder.
          </div>
        </div>
        <table v-if="prices.length" class="table table-sm">
          <thead>
            <tr>
              <th>Product</th>
              <th>Prijs</th>
              <th>Onze prijs</th>
            </tr>
          </thead>
          <tbody>
            <LeisureKingCustomPrice 
              v-for="price in prices"
              :price="price" 
              :key="`price-${price.id_tarif}`"
              :merchant-id="merchantId" 
              :api-options="apiOptions"
              :barcode-batch-id="barcodeBatchId"
            />
          </tbody>
        </table>
        <div class="clearfix"></div><br> 
        <div class="text-center" v-if="loading"><i class="fas fa-spinner fa-spin"></i></div>
      </div>
    </fieldset>
  </div>
</template>

<script>

import axios from 'axios'
import LeisureKingCustomPrice from './LeisureKingCustomPrice.vue'
export default {
  props: ["logo", "product_id", "barcodeBatchId", "merchantId", "apiOptions"],
  components: { LeisureKingCustomPrice },
  data() {
    return {
      loading: true,
      shops: [],
      loadingPrices: false,
      products: [],
      selectedAssortiment: null,
      selectedAssortimentId: null,
      selectedAssortimentDetails: null,
      selectedShop: null,
      selectedShopId: null,
      prices: []
      
    }
  },
  computed: {
    reservationType(){
      if (this.selectedAssortiment) {
        switch (parseInt(this.selectedAssortiment.starttijden)) {
          case 0:
            return "Een eenvoudig product zonder voorraad of tijdblokken. Enkel een prijs."
          case 1:
            if (this.selectedAssortiment.FKid_soort == '10' || this.selectedAssortiment.FKid_soort == '8') {
              return "Vast aanbod zonder datumkeuze"
            } else {
              return "Vaste tijdblokken met vaste begin- en eindtijden."
            }
          case 2:
            return "Assortiment met voorraad, prijzen en vrije tijdkeuze. Bezoekers mogen binnen de grenzen zelf een tijd kiezen, per kwartier."
          default:
            return ''
        }
      } else {
        return ''
      }
    }
  },
  methods: {
    assortimentSelected() {
      this.prices = []
      this.selectedAssortimentId = this.selectedAssortiment.id_assortiment
      this.loading    = false
      axios.get(`/partners/leisure-king/assortiment/${this.selectedAssortiment.id_assortiment}.json`)
        .then(response => {
          this.selectedAssortimentDetails = response.data["data"]
          this.loading    = false
          this.fetchPrices()    
        })
    },
    fetchPrices() {
      this.loadingPrices = true
      axios.get(`/partners/leisure-king/prices/${this.selectedAssortiment.id_assortiment}.json`)
        .then(response => {
          this.loadingPrices = false
          console.log(response.data)
          if (response.data.status && response.data.status == "NOTICE") {
            alert(response.data.data.StockDetails)
          } else {
            // this.selectedAssortimentDetails = response.data["data"]
            // this.loading    = false
            this.prices = response.data

          }
        }) 
    }
  },
  created() {
    console.log('leisure king created! So long and thanks for all the fish!')
  },
  mounted() {
    console.log('leisure king mounted!')
    this.selectedAssortimentId = this.product_id
    axios.get("/partners/leisure-king/assortiment.json")
      .then(response => {
        if (response.data["data"]){
          this.products   = response.data["data"]
          this.loading    = false
          for (let index = 0; index < this.products.length; index++) {
            if (this.products[index].id_assortiment == this.product_id) {
              this.selectedAssortiment = this.products[index];
            }
            
          }
          this.assortimentSelected()
        } else {
          alert(response.data['error'])
        }
      })
  },
}
</script>

<style>

</style>