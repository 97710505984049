<template>
  <div>
    <div class="bg-light rounded row pt-2">
      <div class="col">
        <div class="form-group">
          <label for="accountmanager">Accountmanager</label>
          <select @change="fetchData" class="form-control" v-model="selectedUser" id="accountmanager" autofocus>
            <option value="null" v-if="!selectedUser">Kies een gebruiker...</option>
            <option :value="user" v-for="(user, key) in users" :key="key">{{ user.first_name }} {{ user.last_name }}</option>
          </select>
        </div>
      </div>
      <div class="col">
        <div class="form-group">
          <label for="">Statistiek</label>
          <select @change="fetchData" class="form-control" v-model="chosenStat" id="">
            <option :value="statOption" v-for="(statOption, key) in statOptions" :key="key">{{ statOption }}</option>
          </select>
        </div>
      </div>
      <div class="col">
        <div class="form-group">
          <label for="accountmanager-role">In de rol van</label>
          <select @change="fetchData" class="form-control" v-model="selectedRole" id="accountmanager-role">
            <option :value="role" v-for="(role, key) in roles" :key="key">{{role}}</option>
          </select>
        </div>
      </div>
      <div class="col">
        <div class="form-group">
          <label for="">Periode van</label>
          <select @change="selectFromDate" class="form-control" v-model="selectedFromPeriod">
            <option :value="period" v-for="(period, key) in from_periods" :key="key">{{period}}</option>
          </select>
        </div>
      </div>
      <div class="col">
        <div class="form-group">
          <label for="">Periode van</label>
          <select @change="fetchData" class="form-control" v-model="selectedToPeriod" id="">
            <option :value="period" v-if="index >= selectedFromIndex" v-for="(period, index) in to_periods" :key="index">{{period}}</option>
          </select>
        </div>
      </div>


    </div>

    <div class="row">
      <div class="col-12 text-center p-5" v-if="loading">
        <i class="fas fa-spinner fa-spin"></i>
      </div>
      <div class="col-12 table-responsive" v-if="stats.length">
        <center>
          <h2>Totaal: {{grandTotal}}</h2>
          <a :href="`/account-manager-stats-export.csv?stat=${chosenStat}&user_id=${selectedUser.id}&from=${selectedFromPeriod}&to=${selectedToPeriod}&role=${selectedRole}`" class="btn btn-outline-primary btn-sm mb-2">Download CSV</a>
        </center>
      </div>
      <div class="col-3 table-responsive pr-0" v-if="stats.length">
        <table class="table table-hover table-bordered table-sm">
          <thead>
            <tr>
              <th class="small">Aanbieder</th>
            </tr>
          </thead>
          <tbody>
            <tr v-if="index > 0" v-for="(row, index) in stats" :key="index">
              <th class="small" style="white-space: nowrap;">{{ row[0]}}</th>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="col-9 pl-0 table-responsive" v-if="stats.length">
        <table class="table table-hover table-bordered table-sm">
          <thead>
            <tr>
              <th class="small" style="white-space: nowrap;" v-if="i > 0" v-for="(header, i) in stats[0]" :key="i">{{header}}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-if="index > 0" v-for="(row, index) in stats" :key="index">
              <td class="small" v-if="i > 0" v-for="(stat, i) in row" :key="i">
                {{ stat}}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <pre v-if="false">{{ stats}}</pre>
  </div>
</template>

<script>
  
  import axios from 'axios'

  export default {
    name: 'Dashboard',
    data() {
      return {
        selectedUser: null,
        selectedFromPeriod: null,
        selectedToPeriod: null,
        selectedFromIndex: 0,
        grandTotal: null,
        loading: false,
        statOptions: ["Omzet", "Commissies excl. BTW", "Aantal tickets"],
        chosenStat: "Omzet",
        selectedRole: "Oorspronkelijke accountmanager",
        roles: ["Oorspronkelijke accountmanager", "Huidige accountmanager"],
        stats: []
      }
    },
    components: {
    },
    props: ["users", "from_periods", "to_periods"],
    created() {
    },
    methods: {
      selectFromDate(){
        for (let index = 0; index < this.from_periods.length; index++) {
          const element = this.from_periods[index]
          if (element == this.selectedFromPeriod){
            this.selectedFromIndex = index
          }
        }
        this.fetchData()
      },
      fetchData(){
        if (this.selectedUser != null && this.selectedFromPeriod != null && this.selectedToPeriod != null){
          console.log("Fetching data")
          this.stats = []
          this.grandTotal = null
          this.loading = true
          axios.post("/account-manager-stats.json", {stat: this.chosenStat, user_id: this.selectedUser.id, from: this.selectedFromPeriod, to: this.selectedToPeriod, role: this.selectedRole})
          .then(response => {
            this.loading = false
            this.grandTotal = response.data.total
            this.stats = response.data.stats
          })
        } 
      }
    },
    mounted(){
      this.selectedFromPeriod = this.from_periods[this.from_periods.length - 7]
      this.selectedToPeriod   = this.to_periods[this.to_periods.length-1]
    },
    computed: {

    }
  };
</script>

<style scoped>
  
</style>
