<template>
  <button class="list-group-item list-group-item-action" :class="{active: loading}" @click="performAction" :disabled="loading || action.history || problem.solved || problem.closed">
    <div class="d-flex w-100 justify-content-between">
      <h5 class="mb-1">{{ action.label }}</h5>
      <span v-if="loading"><i class="fa fa-spinner fa-spin"></i></span>
    </div>
    <small v-if="!action.history">{{ action.description }}</small>
    <small v-if="action.history">Deze actie is al uitgevoerd</small>
  </button>
</template>

<script>
import axios from 'axios'
export default {
  props: ['action', 'problem'],
  name: 'CartProblemAction',

  data () {
    return {
      loading: false
    }
  },
  methods: {
    performAction() {
      this.loading = true
      axios.get(`/problem-action/${this.problem.cart_key}/${this.problem.id}/${this.action.action}.json`)
      .then(response => {
        this.loading = false
        this.$emit('change', response.data)
      })
      .catch(error => {
        this.saving = false
        console.log(error)
        alert('Er ging iets mis. De wijzigingen konden niet opgeslagen worden. Probeer het over enkele minuten nogmaals.')
      })
    }
  }
}
</script>

<style lang="css" scoped>
</style>