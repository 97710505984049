<template>
  <div class="card mb-2 shadow-sm">
    <div class="card-header d-flex justify-content-between">
      <span>
        {{ block.template.name }} - {{ block.capacity.name }}
        <span class="text-muted small" v-if="!block.in_current_template"><br><i class="fas fa-exclamation-triangle text-warning"></i> Gekozen sjabloon is niet meer van toepassing op deze datum</span>
      </span>
      <div class="">
        <div class="progress align-self-center" style="width: 150px; height: 2px;">
          <div class="progress-bar bg-success" role="progressbar" :style="`width: ${block.capacity.capacity_percentage}%;`" ></div>
          <div class="progress-bar bg-danger" role="progressbar" :style="`width: ${100 - block.capacity.capacity_percentage}%;`" ></div>
        </div>
        <span class="small align-self-center mr-2">{{ block.capacity.capacity }}/{{ block.capacity.original_capacity }} ({{ block.capacity.capacity_percentage }}%) besch.</span>
      </div>
    </div>
    <div class="card-body">
      <div v-if="!block.reservations.length" class="text-center text-muted">
        Er zijn nog geen reserveringen voor dit tijdvak
      </div>
      <div v-if="block.reservations.length && !collapsed" class="text-center text-muted">
        <button class="btn btn-outline-primary" @click="collapsed = true">Toon reserveringen: {{ uncanceledReservations.length }}</button>
      </div>
      <table v-if="block.reservations.length && collapsed" class="table table-striped">
        <thead>
          <th>#</th>
          <th v-if="abilities.includes('view_consumer_details')">Naam</th>
          <th>Ticketsoort</th>
          <th>Tijd</th>
          <th v-if="!isRetail">Barcode</th>
          <th v-if="isRetail">Bestelnummer</th>
          <th></th>
        </thead>
        <tbody>
          <DWTCapacityCheckBlockLine @updateBlock="updateBlock" :is-retail="isRetail" :capacityPlannerId="capacityPlannerId" :abilities="abilities" :merchant-id="merchantId" :reservation="reservation" v-for="(reservation, key) in block.reservations" :key="key" />
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
  import DWTCapacityCheckBlock from './DWTCapacityCheckBlock.vue'
import DWTCapacityCheckBlockLine from './DWTCapacityCheckBlockLine.vue'
export default {

  name: 'DWTCapacityCheckBlock',
  components: {DWTCapacityCheckBlockLine},
  props: ['block', 'capacityPlannerId', 'merchantId', 'abilities', 'isRetail'],
  data () {
    return {
      collapsed: false
    }
  },
  methods: {
    updateBlock() {
      this.$emit('update')
      console.log('updating block')
    }
  },
  computed: {
    uncanceledReservations() {
      return this.block.reservations.filter((res) => !(res.canceled_at && res.canceled_at.length))
    }
  }
}
</script>

<style lang="css" scoped>
</style>