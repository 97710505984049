<template>
  <div>
    <div class="sticky-top bg-white pt-3" style="top: 50px; border-bottom: 1px solid #dedede;">
      <div class="row">
        <div class="col">
          <div class="form-group">
            {{sort}}
            <input v-model="search" type="text" class="form-control" placeholder="Zoek op naam en tag" autofocus>
          </div>
        </div>
        <div class="col" v-if="!checkpage">
          <div class="form-group">
            <div class="input-group">
              <div class="input-group-prepend" >
                <span class="input-group-text">Laatst gecheckt datum</span>
              </div>
              <input type="date" v-model="date_from" class="form-control" style="max-width: 180px;">
              <div class="input-group-append">
                <span class="input-group-text">tot</span>
              </div>
              <input type="date" v-model="date_to" class="form-control" style="max-width: 180px;">
              <div class="input-group-append">
                <button @click="sortDate" class="btn btn-secondary" type="button">Filter</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <table class="table table-sm" id="carts">
          <thead>
            <tr>
              <th @click="sort == 'status' ? sort = 'status_desc' : sort = 'status'">Status <i v-if="sort == 'status'" class="fas fa-chevron-down"></i><i v-if="sort == 'status_desc'" class="fas fa-chevron-up"></i></th>
              <th @click="sort == 'name' ? sort = 'name_desc' : sort = 'name'">Naam <i v-if="sort == 'name'" class="fas fa-chevron-down"></i><i v-if="sort == 'name_desc'" class="fas fa-chevron-up"></i></th>
              <th v-if="!checkpage">Tag</th>
              <th v-if="!checkpage">Aanbieders</th>
              <th @click="sort == 'last_checked' ? sort = 'last_checked_desc' : sort = 'last_checked'">Laatst gecheckt <i v-if="sort == 'last_checked'" class="fas fa-chevron-down"></i><i v-if="sort == 'last_checked_desc'" class="fas fa-chevron-up"></i></th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <ArticleRow v-for="(article, index) in sortedArticles" :key="index" :article="article" :merchants="article.article_merchants" :tag="getTag(article.tag_id)" :shop_id="shop_id" @remove="remove_from_records" :checkpage="checkpage" />
          </tbody>
        </table>
        <div class="row" v-if="loading">
          <div class="col text-center">
            <i class='fa fa-spinner fa-spin'></i>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import ArticleRow from "./ArticleRow.vue"

  export default {
    name: "ArticleTable",
    components: {
      ArticleRow
    },
    props: {
      articles: {
        type: Array,
        default: null,
        require: true
      },
      tags: {
        type: Array,
        default: null,
        require: true
      },
      shop_id: {
        type: Number,
        default: null,
        require: true
      },
      checkpage: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        records: this.articles,
        search: null,
        date_from: null,
        date_to: null,
        loading: false,
        sort: "status"
      }
    },
    watch: {
      search: function() {
        this.commitSearch(this.search);
      },
    },
    computed: {
      sortedArticles() {
        if(this.sort == "status") {
          return this.reverseArr(this.records.sort((a, b) => (a.is_active > b.is_active) ? 1 : (a.is_active === b.is_active) ? ((a.is_active > b.is_active) ? 1 : -1) : 1 ))
        } else if(this.sort == "status_desc") {
          return this.records.sort((a, b) => (a.is_active > b.is_active) ? 1 : (a.is_active === b.is_active) ? ((a.is_active > b.is_active) ? 1 : -1) : 1 )
        } else if(this.sort == "name") {
          return this.records.sort((a, b) => (a.name.toLowerCase().charAt(0) > b.name.toLowerCase().charAt(0)) ? 1 : (a.name.toLowerCase().charAt(0) === b.name.toLowerCase().charAt(0)) ? ((a.name.toLowerCase().charAt(0) > b.name.toLowerCase().charAt(0)) ? 1 : -1) : -1 )
        } else if(this.sort == "name_desc") {
          return this.reverseArr(this.records.sort((a, b) => (a.name.toLowerCase().charAt(0) > b.name.toLowerCase().charAt(0)) ? 1 : (a.name.toLowerCase().charAt(0) === b.name.toLowerCase().charAt(0)) ? ((a.name.toLowerCase().charAt(0) > b.name.toLowerCase().charAt(0)) ? 1 : -1) : -1 ))
        } else if(this.sort == "last_checked") {
          return this.records.sort((a, b) => (a.last_checked_at > b.last_checked_at) ? 1 : (a.last_checked_at === b.last_checked_at) ? ((a.last_checked_at > b.last_checked_at) ? 1 : -1) : -1 )
        } else if(this.sort == "last_checked_desc") {
          return this.reverseArr(this.records.sort((a, b) => (a.last_checked_at > b.last_checked_at) ? 1 : (a.last_checked_at === b.last_checked_at) ? ((a.last_checked_at > b.last_checked_at) ? 1 : -1) : -1 ))
        } else {
          return this.records
        }
      }
    }, 
    methods: {
      reverseArr(input) {
        var ret = new Array;
        for(var i = input.length-1; i >= 0; i--) {
          ret.push(input[i]);
        }
        return ret;
      },
      commitSearch(search) {
        this.loading = true;
        if(search != null && search != '') {
          this.records = [];
          for(let i = 0; i < this.articles.length; i++) {
            if(this.articles[i].name.toLowerCase() != null && this.articles[i].name.toLowerCase().indexOf(search.toLowerCase()) >= 0 || this.tags.find(element => element.id == this.articles[i].tag_id && element.name.toLowerCase().indexOf(search.toLowerCase()) >= 0)) {
              this.records.push(this.articles[i]);
            }
          }
        }
        else {
          this.records = this.articles;
        }
        this.loading = false;
      },
      sortDate() {
        this.loading = true;
        if(this.date_from && this.date_to) {
          this.records = [];
          let start_date = new Date(this.date_from);
          let end_date = new Date(this.date_to);
          for(let i = 0; i < this.articles.length; i++) {
            if(new Date(this.articles[i].last_checked_at) >= start_date && new Date(this.articles[i].last_checked_at) <= end_date) {
              this.records.push(this.articles[i]);
            }
          }
        }
        else {
          this.records = this.articles;
        }
        this.loading = false;
      },
      getTag(id) {
        for (var i = 0; i < this.tags.length; i++) {
          if(this.tags[i].id == id) {
            return this.tags[i];
          }
        }
      },
      remove_from_records(id) {
        for (var i = 0; i < this.records.length; i++) {
          if(this.records[i].id == id) {
            this.records.splice(this.records.indexOf(this.records[i]), 1);
          }
        }
      }
    }
  }
</script>