<template>
  <div>
    <div class="text-center">
      <button @click="settingsOpened = true" class="btn btn-primary" v-if="!settingsOpened">Data uit formulier binnenhalen</button>
    </div>
    <div class="card shadow" v-if="settingsOpened">
      <div class="card-header">
        Welke data wil je importeren?
      </div>
      <div class="card-body text-center" v-if="success">
        <p class="lead">Het importeren is geslaagd! 🎉</p>
        <button @click="close" class="btn btn-outline-secondary btn-sm" v-if="!loading">Sluiten</button>
      </div>
      <div class="list-group-flush" v-if="!success">
        <div class="list-group-item py-1" v-for="(element, key) in elements" :key="key">
          <div class="form-group form-check mb-0">
            <input type="checkbox" class="form-check-input" :id="element.name" :value="element.key" v-model="selectedElements" v-bind:disabled="loading">
            <label class="form-check-label"  :for="element.name">{{element.name}}</label>
          </div>
        </div>
      </div>
      <div class="card-footer d-flex justify-content-between" v-if="!success">
        <button @click="startImporting" class="btn btn-primary" v-bind:disabled="loading || !selectedElements.length">Importeren<i v-if="loading" class="ml-3 fa fa-spinner fa-spin"></i></button>
        <button @click="settingsOpened = false" class="btn btn-outline-secondary btn-sm" v-if="!loading">Annuleren</button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
export default {

  name: 'FormImporter',
  props: ['merchant', 'form_data'],
  data () {
    return {
      loading: false,
      success: false,
      selectedElements: [],
      settingsOpened: false,
      elements: [
        {name: 'Bedrijfsgegevens', key: "bedrijfsgegevens"},
        {name: 'Logo', key: "logo"},
        {name: 'Hoofdfoto', key: "hoofdfoto"},
        {name: 'Overige fotos', key: "fotos"},
        {name: 'Ticketaanbod', key: "ticketsoorten"},
        {name: 'Voorwaarden', key: "voorwaarden"},
        {name: 'Overige instellingen van extra tabblad', key: "extra"}
      ]

    }
  },
  methods: {
    close(){
      this.settingsOpened = false
      this.success = false
      this.selectedElements = []
    },
    startImporting(){
      this.loading = true
      return axios.put(`/merchants/${this.merchant.id}/sync_form.json`, { elements: this.selectedElements })
        .then(response => {
          // window.store.dispatch("setPeriods", response.data.periods)
          this.loading = false
          this.success = true
          this.selectedElements = []
        })
    }
  }
}
</script>

<style lang="css" scoped>
</style>