<template>
  <div class="card mb-2 cap-template-block">
    <i @click="deleteMode = true" class="fa fa-times-circle remove-btn text-muted"></i>
    <div class="card-body text-center" v-if="deleteMode">
      <h4>Verwijderen?</h4>
      <p class="lead">
        Als je dit tijdslot verwijdert, worden eventuele reserveringen die al gemaakt zijn op dit tijdslot, niet verwijderd. Deze reserveringen blijven gewoon staan met de gekozen start- en eindtijd. Deze reserveringen zullen ook zichtbaar blijven in het overzicht bij 'Capaciteit Controleren'.
      </p>
      <button @click="deleteBlock" class="btn btn-danger">Ja, tijdslot verwijderen</button>
      <button class="btn btn-secondary" @click="deleteMode = false">annuleren</button>
    </div>

    <div class="card-body pb-0" v-if="!deleteMode">
      <!-- block settings -->
      <div class="row">
        <div class="col-5">
           <div class="form-group">
            <label>Bloknaam</label>
            <I18nInput 
              @enter="save" 
              placeholder="Naam, bijv. 'Ochtend', 'Blok 2' of 'Groep 4" 
              :locale="locale" 
              type="input" 
              :value-translations="theBlock.localized_name">  
            </I18nInput>

            
            <small class="form-text text-muted">Optioneel. Standaardtekst: '{{theBlock.starts_at}} - {{theBlock.ends_at}} uur'</small>
            <div class="invalid-feedback d-block" v-if="hasErrors('name')">
              <p v-for="(error, key) in errorMessages['name']" :key="key">{{ error }}</p>
            </div>
          </div>
        </div>
        <div class="col-2">
          <div class="form-group">
            <label>Max. Totaal</label>
            <input v-on:keydown.enter="save" v-model="theBlock.capacity" type="number" class="form-control" :class="{'is-invalid': hasErrors('capacity')}"  placeholder="100" style="padding-right: 5px">
            <small class="form-text text-muted">Harde bovengrens</small>
            <div class="invalid-feedback d-block" v-if="hasErrors('capacity')">
              <p v-for="(error, key) in errorMessages['capacity']" :key="key">{{ error }}</p>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="form-group" :class="{'is-invalid': hasErrors('starts_at')}">
            <label>Tijden</label>
            <div class="input-group">
              <input ref='starts_at' v-model="theBlock.starts_at" v-on:keydown.enter="save" type="time" class="form-control" :class="{'is-invalid': hasErrors('starts_at')}" placeholder="00:00" style="padding-right: 5px">
              <div class="input-group-append">
                <span class="input-group-text">tot</span>
              </div>
              <input ref='ends_at' v-model="theBlock.ends_at" v-on:keydown.enter="save" type="time" class="form-control" :class="{'is-invalid': hasErrors('starts_at')}" placeholder="00:00" style="padding-right: 5px">
            </div>
            <small class="form-text text-muted" v-if="!showDeadlineSettings">
              Voor consumenten te boeken tot: <span @click="showDeadlineSettings = true" style="text-decoration: underline; cursor: pointer;">{{ showBuyDeadline }}</span>
            </small>
            <div v-if="showDeadlineSettings">
              <div class="input-group input-group-sm mt-2 mx-auto">
                <div class="input-group-prepend">
                  <span class="input-group-text"><i class="fa fa-shopping-cart"></i> <i class="fa fa-arrow-right"></i> <i class="fa fa-clock"></i></span>
                </div>
                <input ref="deadline" type="number" v-model="theBlock.deadline_minutes" class="form-control" style="max-width: 80px;">
                <div class="input-group-append">
                  <span v-if="theBlock.deadline_minutes < 0" class="input-group-text">minuten vóór aanvang blok</span>
                  <span v-else class="input-group-text">minuten ná aanvang blok</span>
                </div>
              </div>
            </div>
            <div class="invalid-feedback d-block" v-if="hasErrors('starts_at')">
              <p v-for="(error, key) in errorMessages['starts_at']" :key="key">{{ error }}</p>
            </div>
          </div>
        </div>
      </div>

      <div class="row mb-1 my-0 text-center border-top py-2" v-if="isChanged">
        <div class="col">
          <button @click="cancel" class="btn btn-sm btn-outline-secondary">annuleren</button>
          <button :disabled="!hasStartAndEnd" @click="save" class="btn btn-sm btn-outline-success">wijzigingen opslaan</button>
            <div class="invalid-feedback d-block m-0" v-if="!hasStartAndEnd">
              Begin én eindtijd is verplicht.
            </div>
        </div>
      </div>

    </div>

    <!-- ticket_kinds -->
    <div class="list-group-flush" v-if="!deleteMode && !hasOnlyOneTicketKind">
      <CapacityBlockTicketKind 
        @update="emitUpdate"
        v-for="ticketKind in theBlock.template_block_ticket_kinds"
        :key="`cap-block-${theBlock.id}-${ticketKind.id}`"
        :template-block-ticket-kind="ticketKind"
        :block-capacity="theBlock.capacity"
        :merchant-id="merchantId"
        :capacity-planner="capacityPlanner"
        :capacity-template-id="capacityTemplateId"
        :block-id="theBlock.id"
      />
    </div>

    <div class="card-body" v-if="!deleteMode && !hasOnlyOneTicketKind">

      <!-- ticket_kind add buttons -->
      <div class="row" v-if="showAddButtons">
        <div class="col">
          <button 
            @click="addTicketKind(ticketKind.id)"
            class="btn btn-sm btn-outline-primary m-1" 
            :ticket-kind="ticketKind" 
            v-for="(ticketKind, key) in ticketKindsToShow"
            :key="key"
          >
            <i class='fa fa-plus'></i> {{ ticketKind.name }}
          </button>
          <button @click="addTicketKind('all')" class="btn btn-sm btn-primary m-1" v-if="ticketKindsToShow.length > 1">
            <i class='fa fa-plus'></i> voeg alle ticketsoorten toe
          </button>
          <button class="btn m-1 btn-sm btn-outline-secondary" @click="showAddButtons = false">
            annuleer
          </button>
        </div>
      </div>

      <!-- ticket_kind add buttons -->
      <div class="row" v-if="!showAddButtons && !isChanged">
        <div class="col">
          <button class="btn btn-sm btn-outline-primary" @click="showAddButtons = true">
            <i class='fa fa-plus'></i> ticketsoorten toevoegen
          </button>
        </div>
      </div>      
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import I18nInput from '../Admin/I18nInput.vue'
import CapacityBlockTicketKind  from './CapacityBlockTicketKind.vue'
export default {

  name: 'CapacityBlock',
  components: { CapacityBlockTicketKind, I18nInput },
  props: ['block', 'capacityTemplateId', 'capacityPlanner', 'merchantId', 'ticketKinds', 'locales', 'locale'],
  data () {
    return {
      deleteMode: false,
      theBlock: {},
      errorMessages: {},
      showAddButtons: false,
      showDeadlineSettings: false
    }
  },
  created() {
    // const block = this.block
    this.cancelChanges()
  },
  methods: {
    cancelChanges() {
      this.theBlock = JSON.parse(JSON.stringify(this.block)) // {...this.block}
    },
    emitUpdate(data) {
      this.$emit('update', data)
    },
    addTicketKind(ticketKindId) {
      axios.post(`/merchants/${this.merchantId}/merchant_capacity_planners/${this.capacityPlanner.id}/capacity_templates/${this.capacityTemplateId}/capacity_template_blocks/${this.theBlock.id}/capacity_template_block_ticket_kinds.json`, { ticket_kind_id: ticketKindId })
          .then(response => {
            console.log(response)
            this.emitUpdate(response.data)
            this.showAddButtons = false
            this.errorMessages = {}
          })
          .catch(error => {
            console.log(error.response)
            this.showAddButtons = false
            this.errorMessages = error.response.data.errors
          })
    },
    hasErrors(type) {
      return this.errorMessages[type] && this.errorMessages[type].length
    },
    cancel() {
      this.errorMessages = {}
      // this.theBlock = {...this.block}
      this.cancelChanges()
    },
    deleteBlock() {
      console.log('deleting')
      axios.delete(`/merchants/${this.merchantId}/merchant_capacity_planners/${this.capacityPlanner.id}/capacity_templates/${this.capacityTemplateId}/capacity_template_blocks/${this.theBlock.id}.json`)
          .then(response => {
            this.$emit('update', response.data)
          })
    },
    save() {
      axios.put(`/merchants/${this.merchantId}/merchant_capacity_planners/${this.capacityPlanner.id}/capacity_templates/${this.capacityTemplateId}/capacity_template_blocks/${this.theBlock.id}.json`, { capacity_template_block: this.theBlock })
          .then(response => {
            console.log(response)
            this.$emit('update', response.data)
            this.errorMessages = {}
          })
          .catch(error => {
            console.log(error.response)
            this.errorMessages = error.response.data.errors
          })
    }
  },
  computed: {
    hasOnlyOneTicketKind() {
      return (this.ticketKinds.length == 1 && this.theBlock.template_block_ticket_kinds.length == 1)
    },
    showBuyDeadline() {
      if (this.theBlock.deadline_minutes == null) {
        return `einde blok (${this.theBlock.ends_at})`
      } else {
        return this.theBlock.show_deadline
      }
    },
    hasStartAndEnd() {
      return (this.theBlock.starts_at.length && this.theBlock.ends_at.length)
    },
    ticketKindsToShow() {
      // return this.ticketKinds
      // let list = []
      return this.ticketKinds.filter((ticketKind) => !this.theBlock.template_block_ticket_kinds.map((tkb) => tkb.ticket_kind.id).includes(ticketKind.id)); 
      // for (var i = this.ticketKinds.length - 1; i >= 0; i--) {
      //   const tk = this.ticketKinds[i]
      //   for (var j = this.theBlock.template_block_ticket_kinds.length - 1; j >= 0; j--) {
      //     const ttk = this.theBlock.template_block_ticket_kinds[j]
      //     console.log(ttk)
      //     console.log(`ticketet kind ${tk.id} == ${ttk.id}?`)
      //   }
      // }
      // return list
    },
    isInvalidTime() {
      if (this.theBlock.starts_at.length != 5) {
        return true
      } else if (this.theBlock.ends_at.length != 5) {
        return true
      } else if (parseInt(this.theBlock.starts_at.replace(':', '')) > parseInt(this.theBlock.ends_at.replace(':', ''))) {
        return true
      } else {
        return false
      }
    },
    isChanged() {
      return  JSON.stringify(this.block) !==  JSON.stringify(this.theBlock)
    }
  }
}
</script>

<style lang="css" scoped>

</style>