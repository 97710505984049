<template>
  <div>
    <table class="table table-sm">
      <thead>
        <tr>
          <th>Gebruiker</th>
          <th>Aangemaakt op</th>
          <th>Code</th>
          <th>Status</th>
          <th></th>
        </tr>
      </thead>

      <tbody>
          <tr v-if="image_imports.length > 0" v-for="(image, index) in image_imports" :key="index">
            <td>{{image.user.first_name}} {{ image.user.last_name}}</td>
            <td>{{image.formated_created_at}}</td>
            <td>{{image.key}}</td>
            <td>{{setStatus(image)}}</td>
            <td>
            <a :href="'/nl/merchants/' + merchant_id + '/image_imports/' + image.id" class="btn btn-outline-primary" style='font-size: 14px; margin: 5px'>Bekijken</a>
            <button @click="remove(image)" v-if="image.accepted_at == null && image.denied_at == null" class="btn btn-outline-danger" style='font-size: 14px; margin: 5px'>Verwijderen</button>
            </td>
          </tr>
          <tr v-if="image_imports.length == 0">
            <td>Er zijn nog geen afbeeldingen geimporteerd.</td>
          </tr>
      </tbody>
    </table>
  </div>
</template>

<script>

import axios from 'axios'
export default {
  props: ['image_imports', 'merchant_id'],

  created(){

  },

  methods: {
    setStatus(image){
      if(image.accepted_at){
        return "Geimporteerd"
      }else if(image.denied_at){
        return "Afgekeurd"
      }else if(image.imported_at){
        return "Wachtend op goedkeuring"
      }else{
        return "Nog niet ingediend"
      }
    },

    remove(image) {
    if (confirm("Weet je zeker dat je dit artikel wilt verwijderen?") == true) {
      axios.delete(`/merchants/${this.merchant_id}/image_imports/${image.id}`).then(response => {
        window.location.href = `/merchants/${this.merchant_id}/image_imports/`;
      });
    }
  },
}
}
</script>