<template>
  <tr>
    <td><i class="fa fa-credit-card"></i></td>
    <td>{{ giftcard.original_value }}</td>
    <td>{{ giftcard.current_value }}</td>
    <td>{{ giftcard.created_at }}</td>
    <td v-if="showGiftBox">{{ giftcard.gift_box_option.label }}</td>
    <td v-if="theGiftcard.code && theGiftcard.code.length">{{ theGiftcard.code }}</td>
    <td v-if="!theGiftcard.code">
      <div class="input-group">
        <input v-on:keydown.enter="submit" ref="codefield"  :disabled="linking" type="text" class="form-control form-control-sm" v-model="code">
        <div class="input-group-append">
          <button @click="submit" :disabled="linking" class="btn btn-outline-secondary btn-sm" type="button">Koppel kaart</button>
        </div>
      </div>
    </td>
    <td>
      <a :href="`/shops/${shopId}/giftcards/${giftcard.id}`" class="btn btn-primary btn-sm">Details</a>
    </td>
  </tr>
</template>

<script>
import axios from 'axios'
export default {

  name: 'GiftcardItem',
  props: ['giftcard', 'shopId', 'showGiftBox'],
  data () {
    return {
      code: '',
      theGiftcard: null,
      linking: false
    }
  },
  created() {
    this.theGiftcard = this.giftcard
  },
  methods: {
    submit() {
      this.linking = true
      axios.post(`/link-giftcard.json`, 
        {
          shop_id: this.shopId,
          giftcard_id: this.giftcard.id,
          code: this.code
        }
      )
      .then(response => {
        this.linking = false
        console.log('response: ')
        // console.log(response.data)
        if (response.data.success) {
          this.theGiftcard = response.data.giftcard
          this.$emit('success')
        } else {
          // alert('ongeldige code')
          // this.$refs.codefield[0].focus()
          // this.code = ''
          setTimeout(function () { 
            this.$refs.codefield.select()
          }.bind(this), 200)

        }
        // location.reload()
        // this.dateLoading = false
        // this.dates = response.data
      })

    }
  }
}
</script>

<style lang="css" scoped>
</style>