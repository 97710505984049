<template>
  <div>
    <div class="row">
      <div class="col-md-3">

        <h3>Instellingen</h3>
        <div class="form-group">
          <label>Naam</label>
          <input v-model="theCapacityTemplate.name" type="text" class="form-control" placeholder="bijv. 'regulier' of 'feestdagen'">
        </div>
        <div class="form-group">
          <label>Instructietekst</label>
          <I18nInput :locale="locale" type="textarea" :value-translations="theCapacityTemplate.localized_instructions"></I18nInput>
          
          <input v-if="false" v-model="theCapacityTemplate.instructions" type="text" class="form-control" placeholder="bijv. 'Kies een tijdvak'">
          <small class="form-text text-muted">Optioneel. Deze tekst wordt getoond op de plek waar consumenten een tijdslot reserveren. Bijvoorbeeld: 'De duur van uw bezoek is vrij, het gekozen tijdvak geeft een aankomsttijd aan'</small>
        </div>

        <div class="form-group">
          <label>Kleur</label>
          <input v-model="theCapacityTemplate.color_code" type="color" class="form-control">
          <small class="form-text text-muted">Met deze kleur kun je het gekozen sjabloon snel zien in je capaciteitskalender</small>
        </div>
        <div class="d-flex justify-content-between">
          <button @click="save" class="btn btn-success" :disabled="saving">Opslaan</button>
          <a class="btn btn-secondary" :href="indexUrl">Terug</a>
        </div>
        <small class="form-text text-success" v-if="doneSaving">Opslaan gelukt!</small>
      </div>

      <div class="col-md-9 bg-light rounded p-3" v-if="theCapacityTemplate.id != null">
        <h3>Tijdsloten</h3>
        <div class="bg-white rounded p-3 my-2 w-50 mx-auto" v-if="theCapacityTemplate.blocks == null || theCapacityTemplate.blocks.length == 0">
          <p class="lead">Aan het sjabloon '{{theCapacityTemplate.name}}' kun je nu één of meer tijdsloten toevoegen. Voor elk tijdslot kun je instellen:
            <ul>
              <li>Wat is de begin- en eindtijd?</li>
              <li>Wat is totale maximumcapaciteit? (harde bovengrens)</li>
              <li>Welke ticketsoorten mogen in dit tijdvak verkocht worden, en wat is per ticketsoort de bovengrens?</li>
            </ul>
          </p>
        </div>
        <CapacityBlock 
          @update="setTemplateData"
          v-for="block in theCapacityTemplate.blocks" 
          :key="`block-${block.id}-${block.updated_at}`" 
          :block="block"
          :ticket-kinds="theCapacityTemplate.ticket_kinds"
          :locale="locale"
          :locales="locales"
          :capacity-template-id="capacityTemplateId" 
          :capacity-planner="capacityPlanner"
          :merchant-id="merchantId"
        />
        <button @click="addBlock" class="btn btn-outline-success btn-block">
          <i class="fa fa-clock"></i> Tijdslot toevoegen
        </button>
      </div>

      <div class="col-3" v-if="false">
        <pre>{{ theCapacityTemplate }}</pre>
      </div>
    </div>
  </div>
</template>

<script>
import I18nInput from '../Admin/I18nInput.vue'
import axios from 'axios'
import CapacityBlock from './CapacityBlock.vue'
export default {

  name: 'CapacityTemplate',
  components: {CapacityBlock, I18nInput},
  props: ['capacityTemplateId', 'capacityPlanner', 'merchantId', 'locales', 'locale'],
  data () {
    return {
      saving: false,
      doneSaving: false,
      multilanguageText: {},
      theCapacityTemplate: {id: null, blocks: [], name: ''}
    }
  },
  created() {
    this.multilanguageText = {
      nl: 'dit is een test',
      en: 'this is a test',
      de: 'dies ist ein Test'
    }
    this.getTemplateData()
    // this.theCapacityTemplate = this.capacityTemplate
  },
  computed: {
    indexUrl() {
      return `/${this.locale}/merchants/${this.merchantId}/merchant_capacity_planners/${this.capacityPlanner.id}?tab=planning`
    }
  },
  methods: {
    addBlock() {
      console.log('adding timeslot')
       axios.get(`/${this.locale}/merchants/${this.merchantId}/merchant_capacity_planners/${this.capacityPlanner.id}/capacity_templates/${this.capacityTemplateId}/capacity_template_blocks/new.json`)
        .then(response => {
          this.theCapacityTemplate = response.data
        })
    },
    setTemplateData(data) {
      console.log('setting template date from update')
      this.theCapacityTemplate = data
    },
    getTemplateData() {
      if (this.capacityTemplateId == '') {
        // do nothing
      } else {
        axios.get(`/${this.locale}/merchants/${this.merchantId}/merchant_capacity_planners/${this.capacityPlanner.id}/capacity_templates/${this.capacityTemplateId}.json?force_locale=true`)
            .then(response => {
              this.theCapacityTemplate = response.data
            })
      }
    },
    save() {
      this.saving = true
      console.log('saving')
      if (this.theCapacityTemplate.id == null) {
        axios.post(`/${this.locale}/merchants/${this.merchantId}/merchant_capacity_planners/${this.capacityPlanner.id}/capacity_templates.json?force_locale=true`, { capacity_template: this.theCapacityTemplate })
          .then(response => {
            this.theCapacityTemplate = response.data
            this.capacityTemplateId = this.theCapacityTemplate.id  
            const url = location.href
            const newUrl = url.replace('/new', `/${this.theCapacityTemplate.id}`)
            history.pushState(null, null, newUrl)
          })
      } else {
        axios.put(`/${this.locale}/merchants/${this.merchantId}/merchant_capacity_planners/${this.capacityPlanner.id}/capacity_templates/${this.theCapacityTemplate.id}.json?force_locale=true`, { capacity_template: this.theCapacityTemplate })
          .then(response => {
            this.saving = false
            this.doneSaving = true
            setTimeout(function () { 
              this.doneSaving = false
            }.bind(this), 1300)

            // this.theCapacityTemplate = response.data
          })
      }
    }
  }
}
</script>

<style lang="css" scoped>
</style>