<template>
  <div>
    <div class="bg-light rounded mb-3 p-4" v-if="capacityPlanners.length">
      <h6>Welke capaciteitsplanning gebruikt dit formulier?</h6>
      <div class="form-check">
        <input @change="updatePlanner" class="form-check-input" type="radio" name="planner" id="planner-null" :value="null" v-model="selectedPlanner">
        <label class="form-check-label" for="planner-null">
          Gebruik geen capaciteitsplanning
        </label>
      </div>
      <div class="form-check" v-for="planner in capacityPlanners">
        <input @change="updatePlanner" class="form-check-input" type="radio" name="planner" :id="`planner-${planner.id}`" :value="planner.id" v-model="selectedPlanner">
        <label class="form-check-label" :for="`planner-${planner.id}`">
          {{ planner.name }}
        </label>
      </div>
    </div>

    <draggable class="list-group" v-model="theFormItems" group="people" @end="endDrag">
      <FormComposerItem @delete="deleteItem" :data-form-item-id="item.id" :form-item="item" :sub-pages="subPages" :ticket-kinds="ticketKinds" v-for="item in theFormItems" :key="item.id" />
    </draggable>
    <div class="dropdown mt-2">
      <button v-if="ticketKindsLength !== ticketKindIds.length" class="btn btn-primary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
        Ticketsoort Toevoegen
      </button>
      <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
        <button 
          v-for="ticketKind in ticketKinds" 
          v-if="!ticketKindIds.includes(ticketKind.id)"
          :key="`tk-${ticketKind.id}`" 
          @click="addItem({ticket_kind: ticketKind})"
          class="dropdown-item"
        >{{ ticketKind.show_name }}
        </button>
        <div v-if="subPages && subPages.length" class="dropdown-divider"></div>
        <button 
          v-for="subPage in subPages" 
          v-if="!subPagesIds.includes(subPage.id)"
          :key="`sp-${subPage.id}`" 
          @click="addItem({sub_page: subPage})"
          class="dropdown-item"
        >Subpagina: {{ subPage.name }}
        </button>

      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import draggable from 'vuedraggable'
import FormComposerItem from './FormComposerItem.vue'
export default {

  name: 'FormComposer',
  components: {FormComposerItem, draggable},
  props: ['ticketKinds', 'subPages', 'formItems', 'formId', 'capacityPlanners', 'merchantCapacityPlannerId'],
  data () {
    return {
      selectedPlanner: null,
      theFormItems: [],
      drag: false
    }
  },
  created() {
    if (this.merchantCapacityPlannerId) {
      this.selectedPlanner = this.merchantCapacityPlannerId
    }
    this.theFormItems = this.formItems
  },
  computed: {
    ticketKindsLength() {
     return Object.keys(this.ticketKinds).length
    },
    subPagesIds() {
      let arr = []
      for (var i = this.theFormItems.length - 1; i >= 0; i--) {
        if (this.theFormItems[i].sub_page_id) {
          arr.push(this.theFormItems[i].sub_page_id)
        }
      }
      return arr
    },
    ticketKindIds() {
      let arr = []
      for (var i = this.theFormItems.length - 1; i >= 0; i--) {
        arr.push(this.theFormItems[i].ticket_kind_id)
      }
      return arr
      // return this.formItems.map()
    }
  },
  methods: {
    updatePlanner() {
      return axios.put(`/forms/${this.formId}.json`, { form: {merchant_capacity_planner_id: this.selectedPlanner }})
        .then(response => {
          // this.theFormItems = response.data
        })
    },
    deleteItem(item) {
      // console.log('deleting item')
      // console.log(item)
      return axios.put(`/forms/${this.formId}/delete_item.json`, { form_item_id: item.id })
        .then(response => {
          this.theFormItems = response.data
        })
    },
    endDrag(event) {
      const form_item_id    = event.item.dataset.formItemId
      const position = event.newIndex
      return axios.put(`/forms/${this.formId}/move_item.json`, { form_item_id, position })
        .then(response => {
          // this.theFormItems = response.data
        })
    },
    addItem(item) {
      return axios.post(`/forms/${this.formId}/add_item.json`, { item: item })
        .then(response => {
          this.theFormItems = response.data
        })
    }
  }
}
</script>

<style lang="css" scoped>
</style>