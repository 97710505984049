<template>
  <div>
    <h3>Instellingen</h3>
    <div class="form-group string optional sub_page_name">
      <label class="control-label string optional" for="sub_page_name">Naam</label>
      <input v-model="subpage.name" name="sub_page[name]" placeholder="Vul een naam in" class="form-control string optional" type="text" id="sub_page_name">
      <small>Type uitje of locatie</small>
    </div>

    <div class="form-group select optional sub_page_merchant_location_id">
      <label class="control-label select optional" for="sub_page_merchant_location_id">Locatie</label>
      <select class="form-control select optional" name="sub_page[merchant_location_id]" id="sub_page_merchant_location_id">
        <option v-for="(location, key) in locations" :selected="location.id == subpage.merchant_location_id" :value="location.id" :key="key">{{location.name}} ({{location.city}})</option>
        <option value="" selected disabled hidden>Kies een locatie</option>
      </select>
      <small @click="showNewLocation = true">+ Nieuwe locatie aanmaken</small>
    </div>

    <div class="card" v-if="showNewLocation">
      <div class="card-body">
        <h5>Nieuwe locatie</h5>
        <p>Vul hier alle gegevens in van de locatie van het uitje.</p>
        <div class="form-group string optional location_name">
          <label class="control-label string optional" for="location_name">Naam</label>
          <input v-model="location.name" class="form-control string optional" type="text" id="location_name">
        </div>

        <div class="form-group string optional location_street">
          <label class="control-label string optional" for="location_street">Straat</label>
          <input v-model="location.street" class="form-control string optional" type="text" id="location_street">
        </div>

        <div class="form-group string optional location_house_number">
          <label class="control-label string optional" for="location_house_number">Huisnummer</label>
          <input v-model="location.house_number" class="form-control string optional" type="text" id="location_house_number">
        </div>

        <div class="form-group string optional location_postal_code">
          <label class="control-label string optional" for="location_postal_code">Postcode</label>
          <input v-model="location.postal_code" class="form-control string optional" type="text" id="location_postal_code">
        </div>

        <div class="form-group string optional location_city">
          <label class="control-label string optional" for="location_city">Stad</label>
          <input v-model="location.city" class="form-control string optional" type="text" id="location_city">
        </div>

        <div class="form-group string optional location_phone">
          <label class="control-label string optional" for="location_phone">Telefoonnummer</label>
          <input v-model="location.phone" class="form-control string optional" type="text" id="location_phone">
        </div>

        <div class="form-group string optional location_email">
          <label class="control-label string optional" for="location_email">Email</label>
          <input v-model="location.email" class="form-control string optional" type="text" id="location_email">
        </div>

        <button type="button" @click="closeNewLocation()" class="btn btn-danger">Annuleren</button>
        <button type="button" @click="createNewLocation()" class="btn btn-success">Nieuwe locatie aanmaken</button>
      </div>
    </div>

    <hr class="mb-3"/>

    <h3>Uitstraling</h3>

    <div v-if="sub_page_type == 'type'" class="form-group string optional sub_page_icon">
      <label class="control-label string optional" for="sub_page_icon">Icoon (optioneel)</label>
      <input v-model="subpage.icon" name="sub_page[icon]" placeholder="Bijv. bicycle of car" class="form-control string optional" type="text" id="sub_page_icon">
      <small>Een icoon die getoont kan worden in het overzicht van subpagina's. Gebruik hier deze <a href="https://fontawesome.com/">iconen</a>.</small>
    </div>

    <div class="form-group string optional sub_page_title">
      <label class="control-label string optional" for="sub_page_title">Titel</label>
      <input v-model="subpage.title" name="sub_page[title]" placeholder="Vul een titel in" class="form-control string optional" type="text" id="sub_page_title">
      <small>De titel van de subpagina, staat als tekst in de zoekresultaten en op de subpagina zelf</small>
    </div>

    <div class="form-group text optional sub_page_short_description">
      <label class="control-label text optional" for="sub_page_short_description">Korte omschijving</label>
      <textarea v-model="subpage.short_description" placeholder="Vul een korte omschrijving in" name="sub_page[short_description]" class="form-control text optional" type="text" id="sub_page_short_description"></textarea>
      <small>De korte beschijving van de subpagina, staat als tekst in de zoekresultaten en op de subpagina zelf</small>
    </div>

    <div class="form-group text optional sub_page_body">
      <label class="control-label text optional" for="sub_page_body">Tekst</label>
      <textarea v-model="subpage.body" name="sub_page[body]" placeholder="vul een tekst in" class="form-control text optional" type="text" id="sub_page_body"></textarea>
      <small>De tekst van de subpagina, staat als tekst op de subpagina.</small>
    </div>

    <hr class="mb-3"/>

    <h3>SEO</h3>

    <div class="form-group string optional sub_page_seo_title">
      <label class="control-label string optional" for="sub_page_seo_title">SEO Titel</label>
      <input v-model="subpage.seo_title" placeholder="Vul een SEO titel in" name="sub_page[seo_title]" class="form-control string optional" type="text" id="sub_page_seo_title">
      <small>De SEO Titel van de subpagina, vul hier een goede titel in voor de SEO</small>
    </div>

    <div class="form-group text optional sub_page_seo_description">
      <label class="control-label text optional" for="sub_page_seo_description">SEO Beschrijving</label>
      <textarea v-model="subpage.seo_description" placeholder="Vul een SEO beschrijving in" name="sub_page[seo_description]" class="form-control text optional" type="text" id="sub_page_seo_description"></textarea>
      <small>De SEO beschrijving van de subpagina, vul hier een goede beschrijving in voor de SEO</small>
    </div>

  </div>
</template>

<script>
import axios from 'axios'

export default {
  name: "SubPage",
  props: ["subpage", "merchant_id", "sub_page_type"],
  data() {
    return {
      locations: [],
      showNewLocation: false,
      location: {
        name: null,
        street: null,
        house_number: null,
        postal_code: null,
        city: null,
        phone: null,
        email: null
      }
    }
  },
  created() {
    this.getLocations()
  },
  methods: {
    getLocations() {
      axios.get(`/merchants/${this.merchant_id}/locations.json`)
      .then(response => {
        this.locations = response.data
      })
      .catch(error => {
        console.log(error)
        alert('Er ging iets mis. De wijzigingen konden niet opgeslagen worden. Probeer het over enkele minuten nogmaals.')
      })
    },
    createNewLocation() {
      if(this.location.name && this.location.street && this.location.house_number && this.location.postal_code && this.location.city && this.location.phone && this.location.email) {
        axios.post(`/merchants/${this.merchant_id}/new_location.json`, this.location)
        .then(response => {
          this.getLocations()
          this.subpage.merchant_location_id = response.data.id
          this.showNewLocation = false
        })
        .catch(error => {
          console.log(error)
          alert('Er ging iets mis. De wijzigingen konden niet opgeslagen worden. Probeer het over enkele minuten nogmaals.')
        })
      } else {
        alert('Niet alle velden zijn ingevuld. Vul alle velden in.')
      }
    },
    closeNewLocation() {
      this.showNewLocation = false
      this.location = {
        name: null,
        street: null,
        house_number: null,
        postal_code: null,
        city: null,
        phone: null,
        email: null
      }
    }
  }
}

</script>

<style>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .5);
  display: table;
  transition: opacity .3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}
</style>