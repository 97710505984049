<template>
  <div>
    <div class="input-group mb-3 w-50">
      <div class="input-group-prepend">
        <span class="input-group-text" id="basic-addon1"><i class="fas fa-search"></i></span>
      </div>
      <input type="text" class="form-control" v-model="filter" placeholder="Zoeken.." aria-label="search" aria-describedby="basic-addon1">
    </div>
    <div class="list-group" v-if="filteredTicketKinds.length > 0">
      <div v-for="ticket_kind in filteredTicketKinds" class="list-group-item list-group-item-action flex-column align-items-start">
        <div class="d-flex w-100 justify-content-between">
          <h5 class="mb-1">{{ ticket_kind.name }}</h5>
          <span v-if="ticket_kind.is_active && ticket_kind.first_active_barcode_batch"><span class="badge badge-pill badge-success">LIVE</span></span>
          <span v-else><span class="badge badge-pill badge-danger">NIET LIVE</span></span>
        </div>
        <div class="d-flex w-100 justify-content-between">
          <span>{{ ticket_kind.description }}</span>
          <span class="h4"><span class="strike h5" v-if="ticket_kind.original_price_in_cents && ticket_kind.original_price_in_cents != ticket_kind.price_in_cents">€{{ (ticket_kind.original_price_in_cents/100).toFixed(2) }}</span> €{{ (ticket_kind.price_in_cents/100).toFixed(2) }}</span>
        </div>
        <div class="mb-2">
          <span v-if="ticket_kind.forms.length > 0">
            Word momenteel verkocht op: <span class="font-weight-bold">{{ticket_kind.forms.map(x => x.show_name).join(", ")}}</span>
          </span>
          <span v-else>
            <span class="font-weight-bold text-danger h6">LET OP:</span> Is nog niet gekoppeld aan een verkoopformulier, voeg hem <a href="forms">hier</a> toe aan een verkoopformulier.
          </span>
        </div>
        <div class="mb-2">
          <span v-if="ticket_kind.first_active_barcode_batch">
            Barcodes: 
            <span>{{ticket_kind.barcode_batch_string}}</span>
          </span>
          <span v-else>
            <span class="font-weight-bold text-danger h6">LET OP:</span> Er zijn nog geen barcodes ingesteld, stel <a href="barcode_batches">hier</a> barcodes in.
          </span>
        </div>
        <a :href="`ticket_kinds/${ticket_kind.id}/edit`" class="btn btn-sm btn-primary">Bewerken</a>
        <a :href="`barcode_batches`" class="btn btn-sm btn-info">Barcodes</a>
        <a :href="`ticket_kinds/${ticket_kind.id}?archive=true`" class="btn btn-sm btn-secondary">Archiveer</a>
      </div>
    </div>
    <div v-if="filteredTicketKinds.length == 0 && ticket_kinds.length > 0">
      <p>Er zijn geen ticketsoorten gevonden met de zoekopdracht: {{filter}}</p>
    </div>
    <div v-if="filteredTicketKinds.length == 0 && ticket_kinds.length == 0">
      <p>Er zijn nog geen ticketsoorten. <a href="ticket_kinds/new">een nieuwe aan</a>.</p>
    </div>
  </div>
</template>

<script>
  export default {
  data () {
    return {
      filter: null
    }
  },
  props: {
    ticket_kinds: { 
      type: Array,
      default: [],
      required: true
    },
  },
  computed: {
    filteredTicketKinds() {
      if((this.filter != null) && (this.filter != "")) {
        return this.ticket_kinds.filter(tk => (tk.name.toLowerCase().includes(this.filter.toLowerCase())));
      } else {
        return this.ticket_kinds
      }
    }
  },
  name: "TicketKinds"
  }
</script>