<template>
  <div>
    <div v-show="showCalendar" style="display: flex;flex-direction: column;flex-grow: 1; min-height: 600px">
      <div class="text-center py-5" v-if="loadingCalendar">
        <i class='fa fa-spinner fa-spin my-5'></i>
      </div>
      <calendar-view
        v-if="!loadingCalendar"
        :show-date="showDate"
        :events="events"
        :starting-day-of-week="1"
        @click-date="onClickDay"
        @click-event="onClickEvent"
        class="theme-default holiday-nl-traditional holiday-nl-official">
        <calendar-view-header
          slot="header"
          slot-scope="t"
          :header-props="t.headerProps"
          @input="setShowDate" />
      </calendar-view>
    </div>

    <div class="row text-left" v-if="!showCalendar">
      <div class="col-12 text-center py-5" v-if="loading">
        <i class='fa fa-spinner fa-spin my-5'></i>
      </div>
      <div class="col-12 mb-2 d-flex justify-content-between">
        <button @click="showCalendar = true" class="btn btn-secondary"><i class="fa fa-chevron-left"></i> Terug naar agenda <i class="fa fa-calendar"></i> </button>
        <h3>{{ dateName }}</h3>
      </div>
      <div class="col">
        <DWTCapacityCheckBlock @update="updateBlock" :is-retail="false" :abilities="abilities" :block="block" :capacity-planner-id="capacityPlannerId" :merchant-id="merchantId" :key="`block-${block.block_id}`" v-for="block in reservations" />
      </div>
    </div>

  </div>
</template>

<script>

import DWTCapacityCheckBlock from '../Partners/DWTCapacityCheckBlock.vue'
import { CalendarView, CalendarViewHeader } from "vue-simple-calendar"
import axios from 'axios'

export default {
  props: ['merchantId', 'capacityPlannerId', 'abilities', 'isRetail'],
  components: {
    CalendarView,
    CalendarViewHeader,
    DWTCapacityCheckBlock
  },
  name: 'DWTCalendar',
  data () {
    return {
      events: [],
      loadingCalendar: true,
      reservations: [],
      ticket_kinds: [],
      showDate: new Date(),
      loading: false,
      showCalendar: true,
      d: null,
      dateName: ''
    }
  },
  methods: {
    onClickEvent(d){
      console.log(d)
      this.onClickDay(d.startDate)
    },
    updateBlock() {
      console.log('updatig')
      this.reservations = []
      // this.onClickDay(this.d)
      axios.post(`/partners/dwt/reservations.json`, {planning_id: this.capacityPlannerId, date: this.d.toDateString()})
        .then(response => {
          // console.log('response: ')
          console.log(response.data)
          // this.loading = false
          this.reservations = response.data.reservations
          // this.ticket_kinds = response.data.ticket_kinds
          // this.dateName = response.data.date
        })
    },
    onClickDay(d) {
      this.d = d
      const dateString = new Date(d)
      console.log(d)
      console.log(dateString)
      console.log(d.toISOString())
      console.log(dateString.toISOString())
      this.reservations = []
      this.loading = true
      this.dateName = ''
      this.showCalendar = false
      axios.post(`/partners/dwt/reservations.json`, {planning_id: this.capacityPlannerId, date: d.toDateString()})
        .then(response => {
          console.log('response: ')
          console.log(response.data)
          this.loading = false
          this.reservations = response.data.reservations
          this.ticket_kinds = response.data.ticket_kinds
          this.dateName = response.data.date
        })
    },
    setShowDate(d) {
      this.showDate = d
    },
    fetchEvents() {
      axios.get(`/merchants/${this.merchantId}/merchant_capacity_planners/${this.capacityPlannerId}/events.json`)
      .then((response) => {
        this.events = response.data
        this.loadingCalendar = false
        // TODO
      }).catch((error) => {
        console.error(error);
      }).finally(() => {
        // TODO
      });
    }
  },
  created() {
    this.fetchEvents()
  }
}
</script>

<style lang="css" scoped>
</style>