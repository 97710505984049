<template>
  <tr>
    <td class="w-25">
      <div class="d-inline-block rounded-circle mt-1" style="height: 15px; width: 15px;" :class="`${article.is_active ? 'bg-success' : 'bg-danger'}`"></div>
      <span class="d-inline-block mt-0 ml-1">{{ article.is_active ? 'Live' : 'Niet actief'}}</span>
    </td>
    <td>{{ article.name }}</td>
    <td v-if="tag && !checkpage">{{ tag.name }}</td>
    <td v-else-if="!checkpage"></td>
    <td v-if="!checkpage">{{ merchants | merchantCount }}</td>
    <td :style="`color:${last_checked_date_color}`">{{ article.last_checked_at | formatDate }}</td>
    <td>
      <a :href="`/shops/${this.shop_id}/articles/${this.article.id}/edit`" class="btn btn-outline-primary" style="font-size: 14px; margin: 5px;">
        Bekijken
      </a>
      <button v-if="!checkpage" @click="remove" class="btn btn-outline-danger" style="font-size: 14px; margin: 5px;">Verwijderen</button>
    </td>
  </tr>
</template>

<script>
  import axios from 'axios'
  export default {
    name: "ArticleRow",
    props: {
      article: {
        type: Object,
        default: null,
        require: true
      },
      merchants: {
        type: Array,
        default: null,
        require: true
      },
      tag: {
        type: Object,
        default: null,
        require: true
      },
      shop_id: {
        type: Number,
        default: null,
        require: true
      },
      checkpage: {
        type: Boolean,
        default: false
      }
    },
    filters: {
      truncate: function(data,num){
        if(data && data.length > num) {
          return data.split("").slice(0, num).join("") + "..."
        }
        else {
          return data
        }
      },
      formatDate: function(date) {
        if (date) {
          const new_date = date.split("T")[0].split("-");
          return new_date[2] + "-" + new_date[1] + "-" + new_date[0]
        }
      },
      merchantCount: function(merchants) {
        if(merchants.length > 0) {
          if(merchants.length == 2) {
            return merchants[0].merchant.name + ", " + merchants[1].merchant.name
          }
          else if(merchants.length > 2) {
            return merchants[0].merchant.name + ", " + merchants[1].merchant.name + ', +' + (merchants.length-2)
          }
          else {
            return merchants[0].merchant.name
          }
        }
        else {
          return null
        }
      }
    },
    computed: {
      last_checked_date_color: function() {
        const warning_days_amount = 14;
        const extra_warning_days_amount = 7;
        const error_days_amount = 2;

        if(this.article.last_checked_at && this.article.expires_at) {
            const last_checked_at = new Date(this.article.last_checked_at);
            const expires_at = new Date(this.article.expires_at);
              
            const time_difference = expires_at.getTime() - last_checked_at.getTime();
              
            const result = time_difference / (1000 * 3600 * 24);

          if(result > warning_days_amount) {
            // Green
            return '#28a745'
          }
          else if(result < warning_days_amount && result > extra_warning_days_amount) {
            // Light Orange
            return '#ffc107'
          }
          else if(result < extra_warning_days_amount && result > error_days_amount) {
            // Dark Orange
            return "#ff8205"
          }
          else {
            // Red
            return '#ff0039'
          }
        }
        else {
          // Red
          return '#ff0039'
        }
      }
    },
    methods: {
      remove() {
        if (confirm("Weet je zeker dat je dit artikel wilt verwijderen?") == true) {
          axios.delete(`/shops/${this.shop_id}/articles/${this.article.id}.json`).then(response => {
            this.$emit('remove', this.article.id);
          });
        }
      }
    }
  }
</script>