<template>

    <div class="row">
      <div class="col-12 col-md-12 col-lg-4 mb-2">
        <center><h3>{{translations.select_a_date}}</h3></center>
        <center v-if="dateLoading">
          <i class='fa fa-spinner fa-spin'></i>
        </center>
        <date-picker v-if="!dateLoading" v-model="date" :config="options" @dp-change="dateChanged"></date-picker>
      </div>
      
      <!-- select time and tickets -->
      <div class="col-12 col-md-6 col-lg-4" v-if="date">
        <center><h3>{{translations.select_a_time}}</h3></center>
        <center v-if="loading">
          <i class='fa fa-spinner fa-spin'></i>
        </center>
        <select @change="selectTimeSlot" v-model="selectedTimeSlot" class="custom-select">
          <option value="" v-if="selectedTimeSlot == ''">{{translations.make_a_choice}}</option>
          <option :value="timeSlot.id" v-for="(timeSlot, key) in timeBlocks" :key="key">
            {{ timeSlot.name }} - {{ timeSlot.capacity }} {{translations.available}}
          </option>
        </select>

        <div class="mt-2" v-if="selectedTimeSlot != ''">
          <center><h3>{{translations.select_tickets}}</h3></center>
          <center v-if="ticketsLoading">
            <i class='fa fa-spinner fa-spin'></i>
          </center>
          <table class="table dwt-table table-striped">
            <DWTTicketSelector @change="changeSelection" :translations="translations" :ticket-kind-block="ticketKindBlock" v-for="(ticketKindBlock, key) in ticketKinds" :key="key" />
          </table>
        </div>
      </div>

      <!-- order overview -->
      <div class="col-12 col-md-6 col-lg-4" v-if="Object.keys(cart).length > 0">
        <div class="bg-light rounded p-3">
          <center><h3>{{translations.summery}}</h3></center>
          <DWTCart :is-retail="isRetail" :translations="translations" :date="date" :block-id="selectedTimeSlot" :admin-mode="adminMode" :capacity-planner-id="capacityPlannerId" :form-id="formId" :items="cart" :ticket-kinds="ticketKinds" />
        </div>
      </div>
    </div>

</template>

<script>
import datePicker from 'vue-bootstrap-datetimepicker';
import axios from 'axios'
import moment from 'moment'
import DWTTicketSelector from './DWTTicketSelector.vue'
import DWTCart from './DWTCart.vue'
export default {
  props: ['capacityPlannerId', 'locale', 'formId', 'adminMode', 'translations', 'isRetail'],
  components: {
    datePicker,
    DWTTicketSelector,
    DWTCart
  },
  data () {
    return {
      dates: [],
      date: null,
      isConfirmed: false,
      message: '',
      checkedDates: {},
      dateLoading: true,
      timeBlocks: [],
      loading: false,
      ticketsLoading: true,
      selectedTimeSlot: '',
      ticketKinds: [],
      cart: {}
    }
  },
  methods: {
    changeSelection(ticketKindId, quantity) {
      console.log(`ticket kind ${ticketKindId}: ${quantity}`)  
      let cart = {...this.cart}
      cart[ticketKindId] = quantity
      let newCart = {}
      for (var i = Object.keys(cart).length - 1; i >= 0; i--) {
        const key = Object.keys(cart)[i]
        if (cart[key] > 0) {
          newCart[key] = cart[key]
        }
      }
      this.cart = newCart
    },
    selectTimeSlot() {
      this.cart = {}
      this.ticketKinds = []
      this.ticketsLoading = true
      axios.post(`/partners/dwt/products.json`, {
        planning_id: this.capacityPlannerId, 
        form_id: this.formId,
        date: this.date,
        block_id: this.selectedTimeSlot
      })
      .then(response => {
        console.log(response.data)
        this.ticketsLoading = false
        this.ticketKinds = response.data
      })
    },
    getAvailableDates() {
      axios.post(`/partners/dwt/dates.json`, {planning_id: this.capacityPlannerId, form_id: this.formId})
      .then(response => {
        console.log('response: ')
        console.log(response.data)
        this.dateLoading = false
        this.dates = response.data
      })
    },
    dateChanged(){
      this.cart = {}
      this.timeBlocks = []
      this.selectedTimeSlot = ''
      this.isConfirmed = false
      this.message = ''
      this.loading = true
      axios.post(`/partners/dwt/capacity.json`, {planning_id: this.capacityPlannerId, date: this.date, form_id: this.formId})
        .then(response => {
          console.log('response: ')
          console.log(response.data)
          this.loading = false
          this.timeBlocks = response.data.blocks
        })
      // this.isConfirmed = false
      // this.message = "De beschikbaarheid wordt gecontroleerd..."
        // this.checkEnvisoCapacity()  
    }
  },
  computed: {
    mindDate() {
      if (this.adminmode) {
        return false
      } else {
        return moment().format('YYYY-MM-DD')
      }
    },
    options() {
      return {
        format: 'YYYY-MM-DD',
        inline: true,
        useCurrent: true,
        minDate: this.minDate,
        calendarWeeks: false,
        locale: this.locale,
        enabledDates: this.dates
      }
    }
  },
  created() {
    this.getAvailableDates()
  }
}
</script>

<style>
  .dwt-table.table-striped td, .dwt-table.table-striped th{
    padding-top: 4px;
    padding-bottom:4px;
    vertical-align: middle;
  }
</style>