<template>
  <tr>
    <td class="small">{{ price.description }}</td>
    <td class="small">€ {{ price.price }}</td>
    <td class="small text-right">
      <currency-input v-model="thePriceInEuro" @keyup="setPrice" class="form-control-sm form-control"  style="max-width: 110px;" />
      <input type="hidden" :name="`barcode_batch[api_options][${unique_key}]`" v-model="thePrice" />
    </td>
  </tr>
</template>

<script>
export default {

  name: 'LeisureKingCustomPrice',
  props: ['price', 'barcodeBatchId', 'merchantId', 'apiOptions'],
  data () {
    return {
      thePrice: null,
      thePriceInEuro: 0
    }
  },
  methods: {
    setPrice() {
      console.log('setting price')
      console.log(parseFloat(this.thePriceInEuro))
      this.thePrice = Math.round(parseFloat(this.thePriceInEuro) * 100.0)
    },
    parameterize(str) {
      return str.trim().toLowerCase().replace(/[^0-9a-z]/gi, "")
    }
  },
  computed: {
    normalized_price() {
      return this.parameterize(String(this.price.price))
    },
    normalized_name() {
      return this.parameterize(this.price.description)
    },
    unique_key() {
      return `leisure_king_product_price_${this.normalized_name}_${this.normalized_price}`
    },
    priceFromApiOptions() {
      return this.apiOptions[this.unique_key]
    }
  },
  mounted() {
    console.log(this.price)

    if (this.priceFromApiOptions) {
      this.thePrice = this.priceFromApiOptions
      this.thePriceInEuro = this.thePrice / 100.0
    } else {
      this.thePrice = parseInt(this.price.price.replace('.', '')) / 100.0
      this.thePriceInEuro = parseInt(this.price.price.replace('.', '')) / 100.0
    }
  }
}
</script>

<style lang="css" scoped>
</style>