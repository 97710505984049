import Vue from 'vue/dist/vue.esm'

import axios from 'axios'
axios.defaults.headers.common['X-CSRF-Token'] = document.querySelector('meta[name="csrf-token"]').getAttribute('content')

import store from '../store/index'
window.store = store

import Vue2Editor from "vue2-editor";
Vue.use(Vue2Editor);

import Multiselect from 'vue-multiselect'
Vue.component('multiselect', Multiselect)

import VueCurrencyInput from 'vue-currency-input'
Vue.use(VueCurrencyInput)

import VueClip from 'vue-clip'
Vue.use(VueClip)

import VTooltip from 'v-tooltip'
Vue.use(VTooltip)

import CartFeed from '../components/Dashboard/CartFeed.vue'
Vue.component('cartfeed', CartFeed)

import TicketKinds from '../components/Admin/TicketKinds.vue'
Vue.component('ticketkinds', TicketKinds)

import EnvisoFields from '../components/Partners/EnvisoFields.vue'
Vue.component('envisofields', EnvisoFields)

import LeisureKingFields from '../components/Partners/LeisureKingFields.vue'
Vue.component('leisurekingfields', LeisureKingFields)

import PaymentMethod from '../components/Admin/PaymentMethod.vue'
Vue.component('paymentmethod', PaymentMethod)

import PlannedMutations from '../components/Admin/PlannedMutations.vue'
Vue.component('plannedmutations', PlannedMutations)

import Carts from '../components/Admin/Carts.vue'
Vue.component('carts', Carts)

import EmbedInstructions from '../components/Admin/EmbedInstructions.vue'
Vue.component('embedinstructions', EmbedInstructions)

import FormImporter from '../components/Admin/FormImporter.vue'
Vue.component('formimporter', FormImporter)

import FormComposer from '../components/Admin/FormComposer.vue'
Vue.component('formcomposer', FormComposer)

import CapacityTemplate from '../components/Admin/CapacityTemplate.vue'
Vue.component('capacitytemplate', CapacityTemplate)

import CapacityCalendar from '../components/Admin/CapacityCalendar.vue'
Vue.component('capacitycalendar', CapacityCalendar)

import DWTCalendar from '../components/Admin/DWTCalendar.vue'
Vue.component('dwtcalendar', DWTCalendar)

import QuestionBuilder from '../components/Admin/QuestionBuilder.vue'
Vue.component('questionbuilder', QuestionBuilder)

import VersionFeed from '../components/Admin/VersionFeed.vue'
Vue.component('versionfeed', VersionFeed)

import RetailForm from '../components/Admin/RetailForm.vue'
Vue.component('retailform', RetailForm)

import CartProblem from '../components/Admin/CartProblem.vue'
Vue.component('cartproblem', CartProblem)

import DWTDatePicker from '../components/Partners/DWTDatePicker.vue'
Vue.component('dwtdatepicker', DWTDatePicker)

import STCFields from '../components/Partners/STCFields.vue'
Vue.component('stcfields', STCFields)

import GiftcardOrderForm from '../components/Admin/GiftcardOrderForm'
Vue.component('giftcard-order-form', GiftcardOrderForm)

import DWTCapacityCheck from '../components/Partners/DWTCapacityCheck.vue'
Vue.component('dwtcapacitycheck', DWTCapacityCheck)

import Scanner from '../components/Admin/Scanner.vue'
Vue.component('scanner', Scanner)

import MerchantSelector from '../components/Admin/MerchantSelector.vue'
Vue.component('merchantselector', MerchantSelector)

import ScanImport from '../components/Admin/ScanImport.vue'
Vue.component('scanimport', ScanImport)

import RegisteredDevices from '../components/Admin/RegisteredDevices.vue'
Vue.component('registereddevices', RegisteredDevices)

import TicketExport from '../components/Admin/TicketExport.vue' 
Vue.component('ticketexport', TicketExport)

import DWTDateChanger from '../components/Admin/DWTDateChanger.vue'
Vue.component('dwtdatechanger', DWTDateChanger)

import GiftcardList from '../components/Admin/GiftcardList.vue'
Vue.component('giftcardlist', GiftcardList)

import CompanyGiftcardList from '../components/Admin/CompanyGiftcardList.vue'
Vue.component('companygiftcardlist', CompanyGiftcardList)

import MandrillSettings from '../components/Admin/MandrillSettings.vue'
Vue.component('mandrillsettings', MandrillSettings)

import SubPage from '../components/Admin/SubPage.vue'
Vue.component('subpage', SubPage)

import AccountManagers from '../components/Dashboard/AccountManagers'
Vue.component('accountmanagers', AccountManagers)

import PromotionForms from '../components/Promotions/PromotionForms'
Vue.component('promotionforms', PromotionForms)

import ArticleTable from '../components/Articles/ArticleTable'
Vue.component('articletable', ArticleTable)

import ArticleForm from '../components/Articles/ArticleForm'
Vue.component('articleform', ArticleForm)

import ImageImport from '../components/Images/ImageImport'
Vue.component('imageimport', ImageImport)

import ImageImportTable from '../components/Images/ImageImportTable'
Vue.component('imageimporttable', ImageImportTable)

import ImageImportModal from '../components/Images/ImageImportModal'
Vue.component('imageimportmodal', ImageImportModal)

window.addEventListener("load", function(){
  	if (document.getElementById("app")) {
  		if(document.getElementById("app").dataset.useVue == 'true') {
        console.log("#app found")
    		const app = new Vue({
    	  	el: '#app',
    		})
  		}
  	}
    if (document.getElementById("app2")) {
      if(document.getElementById("app2").dataset.useVue == 'true') {
        console.log("#app2 found")
        const app2 = new Vue({
          el: '#app2',
        })
      }
    }
    if (document.getElementById("appnavbar")) {
      if(document.getElementById("appnavbar").dataset.useVue == 'true') {
        console.log("#appnavbar found")
        const appnavbar = new Vue({
          el: '#appnavbar',
        })
      }
    }
});
