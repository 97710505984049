<template>
  <div>
    <div v-if="loading" class="text-center py-5"><i class='fa fa-spinner fa-spin'></i></div>
    <div class="form-inputs pt-3" v-if="!loading && !error && chosenPriceType">
      <h3>{{ chosenPriceType.name}}</h3>
      <table class="table table-sm table-hover">
        <tbody>
          <tr>
            <th>PriceKey</th>
            <td>{{chosenPriceType.data.PriceKey}}</td>
          </tr>
          <tr>
            <th>PriceTypeKey</th>
            <td>{{chosenPriceType.data.PriceTypeKey}}</td>
          </tr>
          <tr>
            <th>Originele prijs</th>
            <td>{{chosenPriceType.data.FromPrice}}</td>
          </tr>
          <tr>
            <th>Prijs</th>
            <td>{{chosenPriceType.data.Price}}</td>
          </tr>
          <tr>
            <th>Geldig van</th>
            <td>{{chosenPriceType.data.TicketValidFrom}}</td>
          </tr>
          <tr>
            <th>Geldig tot</th>
            <td>{{chosenPriceType.data.TicketValidTo}}</td>
          </tr>
          <tr>
            <th>Reservering nodig</th>
            <td>{{chosenPriceType.data.RequiresCapacitySlot}}</td>
          </tr>
          
        </tbody>
      </table>
      <span @click="clearSelection" class="btn btn-primary btn-block">Maak nieuwe keuze</span>
    </div>
    <div class="form-inputs pt-3" v-if="!loading && !error && !chosenPriceType" >

      <div class="form-group border rounded p-2 shadow-sm bg-light">
        <label for="aanbieder">Event</label>
        <select @change="eventChanged" v-model="event" class="form-control" id="aanbieder">
          <option :value="ev"  v-for="(ev, key) in events" :key="key">{{ ev.name }}</option>
        </select>
        <div v-if="event" class="my-2">
          <STCDetails :object="event.data" />
        </div>

      </div>

      <div class="form-group border rounded p-2 shadow-sm bg-light" v-if="event">
        <label for="perf">Performance</label>
        <select @change="performanceChanged" v-model="performance" class="form-control" id="perf">
          <option :value="perf"  v-for="(perf, key) in event.performances" :key="key">{{ perf.name }}</option>
        </select>

        <div v-if="performance" class="my-2">
          <STCDetails :object="performance.data" />
        </div>

      </div>

      <div class="form-group border rounded p-2 shadow-sm bg-light" v-if="performance">
        <label for="sec">Section</label>
        <select @change="sectionChanged" v-model="section" class="form-control" id="sec">
          <option :value="sec"  v-for="(sec, key) in performance.sections" :key="key">{{ sec.name }}</option>
        </select>

        <div v-if="section" class="my-2">
          <STCDetails :object="section.data" />
        </div>

      </div>

      <div class="form-group border rounded p-2 shadow-sm bg-light" v-if="section">
        <label for="pr">Price Type</label>
        <select v-model="priceType" class="form-control" id="pr">
          <option :value="pr"  v-for="(pr, key) in section.price_types" :key="key">{{ pr.name }}</option>
        </select>

        <div v-if="priceType" class="my-2">
          <STCDetails :object="priceType.data" />
        </div>

        <span v-if="priceType" class="btn btn-primary btn-block" @click="priceTypeChanged">Dit product selecteren</span>
      </div>
         

      
    </div>
    <input type="hidden" name="barcode_batch[api_options][stc_price_type_key]" v-model="chosenKey">
  </div>
</template>

<script>
import axios from 'axios'
import STCDetails from './STCDetails.vue'
export default {

  name: 'STCFields',
  props: ['eventKey', 'performanceKey', 'sectionKey', 'priceTypeKey', 'dwtKey'],
  components: {STCDetails},
  data () {
    return {
      loading: true,
      error: false,
      chosenPriceType: null,
      chosenKey: '',
      event: null,
      performance: null,
      section: null,
      priceType: null,
      priceTypes: [],
      events: []
    }
  },
  methods: {
    clearSelection() {
      this.chosenPriceType = null
      this.event = null
      this.eventChanged() 
    },
    eventChanged() {
      this.performance = null
      // if (this.event.performances.length) {
      //   this.performance = this.event.performances[0]
      // }
      this.performanceChanged()
    },
    performanceChanged() {
      this.section = null
      this.sectionChanged()
    },
    sectionChanged() {
      this.priceType = null
      this.priceTypeChanged()
    },
    priceTypeChanged() {
      console.log('selecting')
      if (this.priceType) {
        console.log(this.priceType)
        this.chosenKey = this.priceType.dwt_token
        this.chosenPriceType = this.priceType
      } else {
        this.chosenKey = ''
      }
      // this.section = null
      // this.sectionChanged()
    },
    setSavedValue() {
      if (this.priceTypeKey) {
        this.chosenPriceType = this.priceTypes[this.priceTypeKey]
        // 
      }
    },
    fetchEvents() {
      axios.get('/partners/ticket_counter/events.json')
      .then((response) => {
        this.loading = false
        this.events = response.data.events
        this.priceTypes = response.data.price_types
        this.setSavedValue()
      }).catch((error) => {
        console.error(error);
      }).finally(() => {
        // TODO
      });
    }
  },
  created() {
    this.chosenKey = this.dwtKey
    this.fetchEvents()
  }
}
</script>

<style lang="css" scoped>
</style>