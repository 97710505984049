<template>
  <div>
    <div class="card mb-2 shadow-sm">
      <div class="card-body" v-if="theQuestion">
        <div class="form-group">
          <label>Label/naam van het veld</label>
          <I18nInput 
            @enter="save" 
            placeholder="" 
            :locale="locale" 
            type="input" 
            :value-translations="theQuestion.localized_label">  
          </I18nInput>
        </div>
        <div class="form-group">
          <label>Hint</label>
          <I18nInput 
            @enter="save" 
            placeholder="" 
            :locale="locale" 
            type="input" 
            :value-translations="theQuestion.localized_hint">  
          </I18nInput>
          <small class="form-text text-muted">Een hint komt onder het veld, precies zoals deze tekst. Geschikt voor uitleg.</small>
        </div>
        <div class="form-group">
          <div class="form-check">
            <input v-model="theQuestion.required" class="form-check-input" type="checkbox" value="" id="required">
            <label class="form-check-label" for="required">
              Deze vraag <b>moet</b> beantwoord worden door klanten
            </label>
          </div>
        </div>


        <div class="d-flex justify-content-between">
          <button @click="save" v-if="isChanged" class="btn btn-success">Opslaan</button>
          <button @click="destroy" class="btn btn-danger">Verwijderen</button>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import I18nInput from '../Admin/I18nInput.vue'
export default {

  name: 'QuestionBuilderItem',
  props: ['question', 'locale', 'merchantId', 'formId'],
  components: {I18nInput},
  data () {
    return {
      theQuestion: null,
      originalQuestion: null
    }
  },
  computed: {
    isChanged() {
      return  JSON.stringify(this.theQuestion) !==  JSON.stringify(this.originalQuestion)
    }
  },
  methods: {
    destroy() {
      this.$emit('destroy', this.question.id)
    },
    cancelChanges() {
      this.theQuestion = JSON.parse(JSON.stringify(this.question))
    },
    save() {
      console.log('saving')
      axios.put(`/merchants/${this.merchantId}/forms/${this.formId}/questions/${this.question.id}.json`, {
        question: this.theQuestion,
      }).then((response) => {
        console.log(response.data)
        this.theQuestion = JSON.parse(JSON.stringify(response.data))
        this.originalQuestion = JSON.parse(JSON.stringify(response.data))
      }).catch((error) => {
        console.error(error);
      }).finally(() => {
        // TODO
      });
    }
  },
  created() {
    this.cancelChanges()
    // this.theQuestion = {...this.question}
    // 79:     this.originalTemplateBlockTicketKind = {...this.templateBlockTicketKind}
  }
}
</script>

<style lang="css" scoped>
</style>