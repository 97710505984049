<template>
  <div class="mt-4">

    <vue-clip v-if="!success" :options="options" :on-complete="complete" class="bg-light rounded text-center p-4 w-50 mx-auto" style="border: 2px dashed #999; color: #999; cursor: pointer;">
      <template slot="clip-uploader-action">
        <div>
          <div class="dz-message"><h2>Upload hier een csv of xls bestand met gescande barcodes</h2></div>
        </div>
      </template>

      <template slot="clip-uploader-body" slot-scope="props">
        <div v-for="(file, key) in props.files" :key="key">
          <img v-bind:src="file.dataUrl" />
          {{ file.name }} {{ file.status }}
        </div>
      </template>

    </vue-clip>

    <div v-if="rows.length" class="pt-3">
      <div class="row my-3 py-4 bg-light">
        <div class="col">
          <label>Barcode:</label>
          <select v-model="codeColumn" class="custom-select">
            <option selected>Maak een keuze</option>
            <option v-for="(col, index) in rows[0]" :key="index" :value="index">{{ letters[index] }}</option>
          </select>
        </div>
        <div class="col">
          <label>Datum:</label>
          <select v-model="dateColumn" class="custom-select">
            <option selected>Maak een keuze</option>
            <option v-for="(col, index) in rows[0]" :key="index" :value="index">{{ letters[index] }}</option>
          </select>
        </div>
        <div class="col">
          <label>Eerste rij overslaan?</label>
          <select v-model="skipFirst" class="custom-select">
            <option :value="true">Ja</option>
            <option :value="false">Nee</option>
          </select>
        </div>
        <div class="col">
          <label>&nbsp;</label>
          <button @click="sync" :disabled="loading || blankFields" class="btn btn-primary btn-block">Synchroniseren</button>
        </div>
      </div>

      <div class="row" v-if="loading">
        <div class="col text-center">
          <i class="fa fa-spinner fa-spin"></i> even geduld...
        </div>
      </div>
      <div class="row" v-if="success">
        <div class="col text-center">
          <i class="fa fa-check text-success"></i> {{ message }}
        </div>
      </div>

      <div class="table-responsive">
        <table class="table table-sm" v-if="!success">
          <thead>
            <tr>
              <th class="text-center text-capitalize" v-for="(col, index) in rows[0]" :key="index">
                {{ letters[index] }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(row, key) in rows" :key="key">
              <td v-for="(col, key) in row" :key="key">
                {{ col }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>

import axios from 'axios'

export default {
  props: ['merchant'],
  name: 'ScanImport',
  data () {
    return {
      rows: [],
      codeColumn: null,
      success: false,
      dateColumn: null,
      loading: false,
      message: '',
      skipFirst: false,
      options: {
        uploadMultiple: false,
        url: `/merchants/${this.merchant.id}/scan_file_upload.json`,
        paramName: 'file'
      }
    }
  },
  methods: {
    sync() {
      this.loading = true
      axios.post(`/merchants/${this.merchant.id}/sync_scan_upload.json`, { 
        rows: this.rows,
        code_coloumn: this.codeColumn,
        date_column: this.dateColumn,
        skip_first: this.skipFirst 
      })
      .then(response => {
        console.log(response.data)
        this.loading = false
        this.message = response.data.message
        this.success = true
      })
      .catch(error => {
        console.log(error)
        alert('Er ging iets mis. Probeer het over enkele minuten nogmaals.')
      })
    },
    complete (file, status, xhr) {
      const data = JSON.parse(xhr.response)
      
      // Adding server id to be used for deleting
      // the file.
      this.rows = data.rows
      // file.addAttribute('id', xhr.response.id)
    }
  },
  computed: {
    blankFields() {
      return (this.codeColumn == null || this.dateColumn == null)
    },
    letters() {
      return [...'ABCDEFGHIJKLMNOPQRSTUVWXYZ']
    }
  }
}
</script>

<style lang="css" scoped>
</style>