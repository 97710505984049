<template>
  <li style="float: left; height: 100%; padding: 0 0 0 10px; margin: 0 0 10px; border-color: inherit;">
    <div class="card" style="width: 18rem;">
      <img class="card-img-top" :src="image">
      <div class="card-body">
        <h5 class="card-title">{{ name.charAt(0).toUpperCase() + name.slice(1) }}</h5>
        <p class="card-text">{{ text }}</p>
        <button :disabled="loading" @click="toggle" v-if="!currenly_active" class="btn btn-success">Inschakelen</button>
        <button :disabled="loading" @click="toggle" v-if="currenly_active" class="btn btn-danger">Uitschakelen</button>
      </div>
    </div>
  </li> 
</template>

<script>
import axios from 'axios'
export default {
  name: 'PaymentMethod',
  props: ["active", "name", "id", "image", "text", "merchant_id"],
  data () {
    return {
      loading: false,
      currenly_active: false
    }
  },
  created() {
    this.currenly_active = this.active
  },
  methods: {
    toggle_active(x) {
      this.currenly_active = x
    },
    toggle() {
      this.loading = true
      axios.get(`/payment-methods/toggle.json?merchant_id=${this.merchant_id}&payment_method_id=${this.id}`)
            .then(response => {
              this.currenly_active = response.data
              this.loading = false
            })
    }
  }
};
</script>

<style scoped>
</style>