<template>
  <div>
    <div class="bg-light p-3 rounded text-muted" v-if="!questions.length">
      Je hebt nog geen vragen toegevoegd.
    </div>    
    <div v-if="questions.length">
      <draggable class="" v-model="questions" group="questions" @end="endDrag">
        <QuestionBuilderItem @destroy="destroyItem" :locale="locale" :key="`question-${question.id}`" :merchant-id="merchantId" :form-id="formId" :data-question-id="question.id" :question="question" v-for="(question, key) in questions"></QuestionBuilderItem>
      </draggable>
    </div>
    <div class="row mt-1">
      <div class="col">
        <div class="dropdown">
          <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            Vraag toevoegen
          </button>
          <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
            <button @click="addItem('phone')" class="dropdown-item">Telefoonnummer</button>
            <button @click="addItem('string')" class="dropdown-item">Korte tekst</button>
            <button @click="addItem('text')" class="dropdown-item disabled">Lange tekst</button>
            <button @click="addItem('label')" class="dropdown-item disabled">Label / tekst</button>
            <button @click="addItem('phone')" class="dropdown-item disabled">Ja/Nee vraag</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import QuestionBuilderItem from './QuestionBuilderItem.vue'
import draggable from 'vuedraggable'

export default {

  name: 'QuestionBuilder',
  components: {QuestionBuilderItem, draggable},
  props: ['formId', 'merchantId', 'locale'],
  data () {
    return {
      questions: []
    }
  },
  methods: {
    endDrag(event) {
      const questionId = event.item.dataset.questionId
      const position = event.newIndex
      // /merchants/:merchant_id/forms/:form_id/questions/:question_id/move_item
      return axios.put(`/merchants/${this.merchantId}/forms/${this.formId}/questions/${questionId}/move_item.json`, { position })
        .then(response => {
          // this.theFormItems = response.data
        })
    },
    destroyItem(questionId) {
      axios.delete(`/merchants/${this.merchantId}/forms/${this.formId}/questions/${questionId}.json`).then((response) => {
        this.questions = response.data
      }).catch((error) => {
        console.error(error);
      }).finally(() => {
        // TODO
      });
    },
    addItem(itemType) {
      axios.post(`/merchants/${this.merchantId}/forms/${this.formId}/questions.json`, {
        answer_type: itemType,
      }).then((response) => {
        // TODO
        this.questions.push(response.data)
      }).catch((error) => {
        console.error(error);
      }).finally(() => {
        // TODO
      });
    }
  },
  created() {
    axios.get(`/merchants/${this.merchantId}/forms/${this.formId}/questions.json`
    ).then((response) => {
      this.questions = response.data
    }).catch((error) => {
      console.error(error);
    }).finally(() => {
      // TODO
    });
  }
}
</script>

<style lang="css" scoped>
</style>