<template>
  <div>
    <span @click="showDetails = !showDetails" class="btn btn-outline-secondary btn-sm btn-block" v-if="!showDetails">Toon details</span>
    <span @click="showDetails = !showDetails" class="btn btn-outline-secondary btn-sm btn-block" v-if="showDetails">Verberg details</span>
    <table class="table table-sm my-1" v-if="showDetails">
      <tr v-for="(key, index) in cleanedKeys" :key="index">
        <th>{{ key }}</th>
        <td>
          <span v-if="!isObject(object[key])">{{ object[key] }}</span>
          <span v-if="isObject(object[key])"><STCDetails :object="object[key]" /></span>
        </td>
      </tr>

    </table>
  </div>
</template>

<script>

export default {

  name: 'STCDetails',
  props: ['object'],
  data () {
    return {
      showDetails: false
    }
  },
  methods: {
    isObject(obj) {
      return obj !== undefined && obj !== null && obj.constructor == Object
    }
  },
  computed: {
    keys() {
      return Object.keys(this.object)
    },
    cleanedKeys() {
      return this.keys.filter((key) => !JSON.stringify(key).includes('[{'))
    }
  }
}
</script>

<style lang="css" scoped>
</style>
