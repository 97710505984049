<template>
  <tr v-show="!deleted">
    <td>
      <input v-on:keydown.enter="save" class="form-control form-control-sm" v-model="name" v-show="editMode" ref="name">
      <span v-if="!editMode">{{ name }}</span>
    </td>
    <td>
      {{ device.created_at}}
    </td>
    <td class="text-right">
      <div class="" v-if="!loading && !editMode">
        <button @click="enterEditMode" class="btn btn-warning btn-sm">bewerk</button>
        <button @click="deleteDevice" class="btn btn-danger btn-sm">verwijder</button>
      </div>
      <div class="" v-if="!loading && editMode">
        <button @click="editMode = false" class="btn btn-secondary btn-sm">annuleer</button>
        <button @click="save" class="btn btn-success btn-sm">opslaan</button>
      </div>
    </td>
  </tr>
</template>

<script>
import axios from 'axios'
export default {

  name: 'RegisteredDevice',
  props: ['merchantId', 'device'],
  data () {
    return {
      name: '',
      deleted: false,
      loading: false,
      editMode: false
    }
  },
  methods: {
    enterEditMode() {
      this.editMode = true
      this.$refs.name.focus()
    },
    save() {
      this.loading = true
      axios.put(`/merchants/${this.merchantId}/registered_devices/${this.device.id}`, {
        registered_device: {
          device_name: this.name
        }
      }).then((response) => {
        // TODO
      }).catch((error) => {
        console.error(error);
      }).finally(() => {
        // TODO
        this.editMode = false
        this.loading = false
      });
      
    },
    deleteDevice() {
      this.loading = true
      axios.delete(`/merchants/${this.merchantId}/registered_devices/${this.device.id}`).then((response) => {
        // TODO
        this.deleted = true
      }).catch((error) => {
        console.error(error);
      }).finally(() => {
        // TODO
      });
    }
  },
  created() {
    this.name = this.device.device_name
  }
}
</script>

<style lang="css" scoped>
</style>