<template>
  <div>
    <div class="form-check" v-for="(form, index) in forms" :key="index">
      <input @change="addOrRemoveFormFromPromotion" class="form-check-input" type="checkbox" :value="form.id" :id="`form-${form.id}`" v-model="checked_items">
      <label class="form-check-label" :for="`form-${form.id}`">
        {{form.show_name}}
      </label>
    </div>
  </div>
</template>
<script>
import axios from 'axios';
export default {
  created () {
    this.checked_items = this.promotion_forms
  },
  data () {
    return {
      checked_items: []
    }
  },
  name: "PromotionForms",
  methods: {
    addOrRemoveFormFromPromotion() {
      const url = "/merchants/" + this.promotion.merchant_id + "/promotions/" + this.promotion.id + ".json"
      axios.put(url, {forms: this.checked_items})
      .catch(err => {
        console.error(err); 
      })
      return null 
    },
  },
  props: ["forms", "promotion", "promotion_forms"],
  
}
</script>
<style>
  
</style>